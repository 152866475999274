var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-speed-dial', {
    attrs: {
      "direction": "left",
      "bottom": "",
      "right": "",
      "fixed": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "large": "",
            "color": "accent",
            "dark": "",
            "fab": ""
          },
          model: {
            value: _vm.fab,
            callback: function ($$v) {
              _vm.fab = $$v;
            },
            expression: "fab"
          }
        }, [_vm.fab ? _c('v-icon', [_vm._v("mdi-close")]) : _c('v-icon', [_vm._v("mdi-file-document-edit")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.fab,
      callback: function ($$v) {
        _vm.fab = $$v;
      },
      expression: "fab"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "disabled": !_vm.allTenantComes,
            "fab": "",
            "color": "purple"
          },
          on: {
            "click": function ($event) {
              return _vm.openSendPassword();
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-lock-reset")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Password Link Resend")])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "disabled": !_vm.allTenantComes || _vm.isVerified,
            "fab": "",
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              return _vm.openSendVerify();
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-email-sync")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Send Email Verify Link")])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "disabled": !_vm.allTenantComes || _vm.isVerified,
            "fab": "",
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              return _vm.openDirectVerify();
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-shield-check")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Verify Account")])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [!_vm.isDeleted ? _c('v-btn', {
          attrs: {
            "fab": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteAccount();
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-close")])], 1) : _vm._e(), _vm.isDeleted ? _c('v-btn', {
          attrs: {
            "fab": "",
            "color": "success"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteAccount();
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-refresh")])], 1) : _vm._e()], 1)];
      }
    }])
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isDeleted,
      expression: "!isDeleted"
    }]
  }, [_vm._v("Delete Account")]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDeleted,
      expression: "isDeleted"
    }]
  }, [_vm._v("Undelete Account")])])], 1), _c('v-dialog', {
    attrs: {
      "max-width": " 600px"
    },
    model: {
      value: _vm.sendVerify,
      callback: function ($$v) {
        _vm.sendVerify = $$v;
      },
      expression: "sendVerify"
    }
  }, [_c('v-card', [_c('v-flex', {
    staticClass: "mb-12"
  }), _c('base-material-card', {
    attrs: {
      "color": "warning",
      "icon": "mdi-alert-circle-outline"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "red",
            "outlined": ""
          },
          on: {
            "click": _vm.closeSendVerify
          }
        }, [_vm._v(" Not Now ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "success"
          },
          on: {
            "click": _vm.sendVerifyLink
          }
        }, [_c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading"
          }]
        }, [_vm._v(" Send Link ")]), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }],
          staticClass: "mng-loader"
        })])];
      },
      proxy: true
    }])
  }, [_c('v-card-title', {
    staticClass: "text-h5",
    staticStyle: {
      "justify-content": "center"
    }
  }, [_vm._v(" Select tenant for the send verify link")]), _c('v-card-actions', [_c('v-spacer'), _c('div', {
    staticClass: "d-flex flex-column p-4",
    staticStyle: {
      "gap": "2rem",
      "width": "100%",
      "justify-content": "center",
      "padding": "1rem"
    }
  }, [_c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "align-items": "center",
      "gap": "10px"
    }
  }, [_c('SelectTenant', {
    attrs: {
      "tenants": _vm.tenants,
      "allTenants": _vm.allTenants
    },
    on: {
      "onSelect": function ($event) {
        return _vm.selectTenant($event);
      }
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.language,
      "item-value": "value",
      "item-text": "label",
      "persistent-hint": "",
      "label": "Select language"
    },
    model: {
      value: _vm.selectedLanguage,
      callback: function ($$v) {
        _vm.selectedLanguage = $$v;
      },
      expression: "selectedLanguage"
    }
  })], 1)]), _c('v-spacer')], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": " 500px"
    },
    model: {
      value: _vm.directVerify,
      callback: function ($$v) {
        _vm.directVerify = $$v;
      },
      expression: "directVerify"
    }
  }, [_c('v-card', [_c('v-flex', {
    staticClass: "mb-12"
  }), _c('base-material-card', {
    attrs: {
      "color": "warning",
      "icon": "mdi-alert-circle-outline"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "red",
            "outlined": ""
          },
          on: {
            "click": _vm.closeDirectVerify
          }
        }, [_vm._v(" Not Now ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "success"
          },
          on: {
            "click": _vm.sendDirectVerify
          }
        }, [_c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading"
          }]
        }, [_vm._v("Verify ")]), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }],
          staticClass: "mng-loader"
        })])];
      },
      proxy: true
    }])
  }, [_c('v-card-title', {
    staticClass: "text-h5",
    staticStyle: {
      "justify-content": "center"
    }
  }, [_vm._v(" Do you want to verify this account? ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": " 600px"
    },
    model: {
      value: _vm.sendPassword,
      callback: function ($$v) {
        _vm.sendPassword = $$v;
      },
      expression: "sendPassword"
    }
  }, [_c('v-card', [_c('v-flex', {
    staticClass: "mb-12"
  }), _c('base-material-card', {
    attrs: {
      "color": "warning",
      "icon": "mdi-alert-circle-outline"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "red",
            "outlined": "",
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              _vm.sendPassword = false;
            }
          }
        }, [_vm._v("Not Now ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "success",
            "x-large": ""
          },
          on: {
            "click": _vm.sendResetPassword
          }
        }, [_c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading"
          }]
        }, [_vm._v("Send Password Link ")]), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }],
          staticClass: "mng-loader"
        })])];
      },
      proxy: true
    }])
  }, [_c('v-card-title', {
    staticClass: "text-h5",
    staticStyle: {
      "justify-content": "center"
    }
  }, [_vm._v(" Select tenant for the send reset password link")]), _c('v-divider', {
    staticClass: "mt-2"
  }), _c('v-card-actions', [_c('v-spacer'), _c('div', {
    staticClass: "d-flex flex-column p-4",
    staticStyle: {
      "gap": "2rem",
      "width": "100%",
      "justify-content": "center",
      "padding": "1rem"
    }
  }, [_c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "align-items": "center",
      "gap": "10px"
    }
  }, [_c('SelectTenant', {
    attrs: {
      "tenants": _vm.tenants,
      "allTenants": _vm.allTenants
    },
    on: {
      "onSelect": function ($event) {
        return _vm.selectTenant($event);
      }
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.language,
      "item-value": "value",
      "item-text": "label",
      "persistent-hint": "",
      "label": "Select language"
    },
    model: {
      value: _vm.selectedLanguage,
      callback: function ($$v) {
        _vm.selectedLanguage = $$v;
      },
      expression: "selectedLanguage"
    }
  })], 1)]), _c('v-spacer')], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }