<template>
  <div>
    <toolbarTitle title="Accounts" class="mb-4" />
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="pb-0">
        <v-select
          v-model="selectedTenants"
          :items="tenants"
          :menu-props="{ maxHeight: '400' }"
          :persistent-hint="false"
          label="Select tenants"
          class="align-stretch font-weight-bold"
          item-text="name"
          item-value="id"
          outlined
          clearable
          chips
          multiple
          hide-details>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < visibleChipLength">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === visibleChipLength" class="grey--text text-caption">
              (+{{ selectedTenants.length - visibleChipLength }})
            </span>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" lg="6" md="6" sm="6" class="pb-0">
        <v-autocomplete
          v-model="selectedCompanies"
          :items="companies"
          :loading="companyLoading"
          label="Select companies"
          item-text="name"
          item-value="id"
          class="font-weight-bold"
          multiple
          chips
          clearable
          hide-details
          outlined>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>No data available</v-list-item-title>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < visibleChipLength">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === visibleChipLength" class="grey--text text-caption">
              (+{{ selectedCompanies.length - visibleChipLength }} more)
            </span>
          </template>

          <template v-slot:item="{ item }">
            <v-list-item-avatar color="primary" class="text-h5 font-weight-light white--text">
              <v-icon color="#fff">mdi-domain</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.fullName ?? item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" lg="6" md="6" sm="6" xs="12" class="pb-0">
        <v-text-field
          v-model.trim="filter.query"
          outlined
          clearable
          class="font-weight-bold"
          :autofocus="filter.query == null"
          prepend-inner-icon="mdi-magnify"
          label="Search account by query"
          hint="Search by Query"
          hide-details
          color="orange"
          v-on:keyup.enter="listAccounts()"></v-text-field>
      </v-col>

      <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="pb-0">
        <v-select
          outlined
          v-model="filter.deleted"
          :items="isDeletedArray"
          menu-props="auto"
          placeholder="Account Status"
          hide-details
          item-text="label"
          item-value="value"
          single-line></v-select>
      </v-col>

      <v-col cols="12" lg="2" md="12" sm="12">
        <v-btn block color="accent" height="40" @click="listAccounts()" style="height: 100%; min-height: 56px">
          SEARCH
        </v-btn>
      </v-col>
    </v-row>

    <!-- empty line-->
    <v-row>
      <v-col></v-col>
    </v-row>

    <h4 v-if="!searchedAtLeastOnce" style="display: table; margin: 0 auto">Start a search to see the orders.</h4>

    <v-data-table
      :mobile-breakpoint="1500"
      v-if="searchedAtLeastOnce"
      :headers="headers"
      :items="orders"
      :server-items-length="ordersTotalCount"
      :loading="loading"
      class="elevation-1 mt-2"
      :items-per-page="filter.itemsPerPage"
      :options.sync="filter"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 45, 100, 500],
      }">
      <template v-slot:[`item.isVerified`]="{ item }">
        <div class="email">
          <div>
            <h4 class="grey--text text--darken-4" style="display: flex; flex-wrap: nowrap; justify-content: start">
              <span v-show="item.isVerified" class="ml-1 font-weight-bold">
                <img style="width: 20px" src="../../../assets/accept.png" alt="" />
              </span>
              <span v-show="!item.isVerified" class="ml-1 font-weight-bold">
                <img style="width: 20px" src="../../../assets/cancel.png" alt="" />
              </span>
            </h4>
          </div>
        </div>
      </template>

      <template v-slot:[`item.isDeleted`]="{ item }">
        <div class="email">
          <div>
            <h4 class="grey--text text--darken-4" style="display: flex; flex-wrap: nowrap; justify-content: start">
              <span v-if="!item.isDeleted" class="ml-1 font-weight-bold" style="display: flex; gap: 10px">
                <img style="width: 20px" src="../../../assets/accept.png" alt="" />
                Active
              </span>
              <span v-if="item.isDeleted" class="ml-1 font-weight-bold" style="display: flex; gap: 10px">
                <img style="width: 20px" src="../../../assets/cancel.png" alt="" />
                Deleted
              </span>
            </h4>
          </div>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ item.fullName }}
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <copyToClipboardField :item="item.email"></copyToClipboardField>
      </template>

      <template v-slot:[`item.companies`]="{ item }">
        <v-chip
          @click="companyDetail(elem.company)"
          v-for="(elem, index) in customerFilter(item.companyCustomers)"
          :key="index"
          class="font-weight-bold cliporder mr-1"
          style="min-width: 10px; font-size: 12px; min-height: 27px; text-transform: capitalize"
          link
          x-small>
          {{ elem.company.name }}
        </v-chip>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <div>
          <v-btn icon :href="`/user/${item.id}`">
            <v-icon color="accent">mdi-eye</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.tenants`]="{ item }">
        <div style="display: flex; flex-direction: column; gap: 0.5rem; margin-top: 0.5rem; margin-bottom: 0.5rem">
          <div style="display: flex; gap: 5px">
            <div v-for="(elem, i) of takeTenants(item.tenants)">
              <div class="soldBY">
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on }">
                    <v-avatar width="20" height="20" tile min-width="30" class="mr-1" v-on="on">
                      <img
                        :src="
                          'https://assets.trainplanet.com/wlabel/' + fixUrl(elem.name) + '/logos/favicon-32x32.png'
                        " />
                    </v-avatar>
                  </template>
                  <span style="display: block; text-align: center">{{ elem.name }}</span>
                </v-tooltip>
                <!-- <span>{{ elem.name }}</span> -->
              </div>
            </div>
            <!-- <v-divider style="margin-top: 0.5rem;" v-if="i != takeTenants(item.tenants).length - 1"></v-divider> -->
          </div>
        </div>
      </template>

      <template v-slot:[`item.savedPassengers`]="{ item }">
        <div class="email">
          <div>
            <h4 class="grey--text text--darken-4" style="display: flex; flex-wrap: nowrap; justify-content: start">
              <span class="ml-1 font-weight-bold">
                {{ item.savedPassengers.length }}
              </span>
            </h4>
          </div>
        </div>
      </template>
    </v-data-table>
    <v-progress-linear v-if="loading" indeterminate color="#f4846e"></v-progress-linear>

    <v-dialog
      v-model="companyDetailDialog"
      :loading="loading"
      width="1000px"
      class="pa-5"
      @click:outside="companyDetailDialog = false">
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dense dark>
          <v-toolbar-title class="ml-2">Company: {{ companyData && companyData.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="companyDetailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <companyDetail v-if="companyData" :company="companyData" :loading="loading" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AccountIDField from "@/components/common/AccountDField";
import OrderIconsField from "@/components/common/OrderIconsField";
import copyToClipboardField from "@/components/common/copyToClipboardField";
import dateTimeField from "@/components/common/dateTimeField";
import toolbarTitle from "@/components/common/toolbarTitle";
import companyDetail from "@/components/info/companyDetail";
import router from "@/router";
import AuthApi from "@/services/auth.api";
import store from "@/store";
import { copyToClipboard, fixUrl } from "@/util/helpers";
import ValidationSystems from "@/views/Orders/Order/ValidationSystems.vue";
import { mapGetters } from "vuex";
import common from "../../../mixins/common";
import Trainplanet from "@/util/trainplanet.api";

Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + days);
  return this;
};

export default {
  name: "SearchAccount",
  components: {
    ValidationSystems,
    AccountIDField,
    OrderIconsField,
    toolbarTitle,
    dateTimeField,
    copyToClipboardField,
    companyDetail,
  },
  mixins: [common],
  props: {
    dates: Array,
    status: String,
    query: String,
    customer_query: String,
  },
  data: () => ({
    orders: [],
    ordersTotalCount: 0,
    selectedTenants: [],
    selectedCompanies: [],
    companies: [],
    companyLoading: false,
    searchedAtLeastOnce: false,
    pageWatcherShouldReact: true,
    screenWidth: window.innerWidth,
    companyDetailDialog: false,
    isShowedArray: [],

    companyData: {
      iataNumber: null,
      reference: null, //other
      fortnoxNumber: null,
      contactPersonName: null,
      invoiceEmail: null,
      name: null,
      organizationNumber: null,
      phone: null,
      email: null,
      tenants: [],
      limit: {
        amount: 0,
        currency: "SEK",
      },
      comment: null,
      customers: [],
      trusted: true,
      automaticInvoiceFeeEnabled: true,
      allowedDaysToPay: 10,
      vatNumber: null,
      address: null,
      postalCode: null,
      city: null,
      country: null,
    },
    isDeletedArray: [
      { label: "All", value: null },
      { label: "Deleted", value: true },
      { label: "Active", value: false },
    ],
    filter: {
      query: null,
      customer_query: null,
      dates: null,
      companyCustomer: false,
      deleted: null,
    },
    loading: false,
    headers: [
      {
        text: "Name",
        value: "name",
        sortable: false,
      },
      {
        text: "Email",
        value: "email",
        sortable: false,
      },
      {
        text: "Companies",
        value: "companies",
        sortable: false,
      },

      {
        text: "Email Verified",
        value: "isVerified",
        sortable: false,
      },

      {
        text: "Account Status",
        value: "isDeleted",
        sortable: false,
      },

      {
        text: "Tenants",
        value: "tenants",
        sortable: false,
      },

      {
        text: "Actions",
        value: "id",
        sortable: false,
      },
    ],
  }),

  computed: {
    AuthModel() {
      return new AuthApi(this.tenantId, this.token);
    },
    ...mapGetters({
      tenants: "auth/userTenants",
    }),
    visibleChipLength() {
      switch (true) {
        case this.screenWidth >= 1800:
          return 4;
        case this.screenWidth >= 1400:
          return 3;
        case this.screenWidth >= 1200:
          return 2;
        default:
          return 1;
      }
    },
  },

  created() {
    this.getTenant();
    this.getToken();
    // parse URL parameters
    if (this.query) {
      this.filter.query = this.query;
    }
    if (this.customer_query) {
      this.filter.customer_query = this.customer_query;
    }
    if (this.dates) {
      this.filter.dates = this.dates;
    }
  },
  mounted() {
    this.getCompanies();
    this.selectedTenants = this.tenants.map((item) => item.id);
    window.addEventListener("resize", this.updateScreenWidth);
  },
  watch: {
    "filter.page": {
      handler: function f(val) {
        if (this.pageWatcherShouldReact === true) {
          this.listAccounts(false);
        }
      },
    },
    "filter.itemsPerPage": {
      handler: function f(val) {
        if (this.pageWatcherShouldReact === true) {
          this.listAccounts(false);
        }
      },
    },

    tenants: {
      deep: true,
      handler: function (value) {
        this.selectedTenants = this.tenants.map((item) => item.id);
      },
    },
  },
  methods: {
    getCompanies() {
      this.companyLoading = true;
      Trainplanet.getCompanies()
        .then((res) => {
          this.companies = res.company;
        })
        .catch((err) => {
          this.$store.dispatch("error", err);
        })
        .finally(() => {
          this.companyLoading = false;
        });
    },
    takeTenants(tempTenant) {
      var tenantArray = [];
      tempTenant.forEach((element) => {
        if (this.tenants.filter((e) => e.id === element).length > 0) {
          var index = this.tenants.findIndex((e) => e.id == element);
          tenantArray.push(this.tenants[index]);
        }
      });
      return tenantArray;
    },
    customerFilter(arr) {
      var showedArray = [];
      var showedStringArray = [];
      var a = 0;
      arr.forEach((element) => {
        if (showedStringArray.includes(element.company.id)) {
        } else {
          showedStringArray.push(element.company.id);
          showedArray.push(element);
        }
      });
      return showedArray;
    },

    companyDetail(item) {
      this.companyData = item;
      this.companyDetailDialog = true;
    },
    async getTenant() {
      const res = await this.$store.dispatch("auth/getUser");
      this.allTenants = res.tenants;
    },
    async getToken() {
      this.token = await store.dispatch("auth/acquireToken", router.history.current.meta.scopes);
      this.listAccounts(true);
    },
    fixUrl,
    copyToClipboard,
    async listAccounts(resetPage = true) {
      this.searchedAtLeastOnce = true;
      this.loading = true;

      if (resetPage === true) {
        this.pageWatcherShouldReact = false; // enable this back after fetching the orders, otherwise it's too fast for watcher to react
        this.filter.page = 1;
        this.filter.itemsPerPage = 20;
      }
      try {
        let [fromDate, untilDate] = this.filter.dates ?? [];

        if (fromDate && !untilDate) {
          const date = new Date(fromDate);
          untilDate = date.addDays(1).toISOString();
        }

        const params = {
          q: this.filter.query,
          limit: this.filter.itemsPerPage,
          offset: (this.filter.page - 1) * this.filter.itemsPerPage,
          tenantIds: this.selectedTenants,
          companyIds: this.selectedCompanies,
          includeDeleted: this.filter.deleted != null ? this.filter.deleted : null,
        };
        const response = await this.AuthModel.accountList(params);
        this.orders = response.data;
        this.ordersTotalCount = parseInt(response.meta.total, 10);
      } catch (error) {
        await this.$store.dispatch("error", error.message);
      } finally {
        this.loading = false;
        this.pageWatcherShouldReact = true; // do this after fetching the orders, otherwise it's too fast for watcher to react
      }
    },
    getColorBalance(status) {
      switch (status) {
        case "completed":
          return "grey--text  font-weight-bold";
        case "refunded":
          return "mango-red  font-weight-bold";
        case "partially_refunded":
          return "mango-orange  font-weight-bold";
        default:
          return "grey--text  font-weight-bold";
      }
    },
    getColorStatus(status) {
      switch (status) {
        case "completed":
          return "mango-green-status";
        case "refunded":
          return "mango-red-status ";
        case "partially_refunded":
          return "mango-orange-status ";
        case "pending":
          return "mango-gray-status  font-weight-bold";
        default:
          return "mango-orange-status ";
      }
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped lang="scss">
.secondary.red {
  background-color: red !important;
}

.clock {
  font-size: 15px !important;
  margin-top: -1px !important;
  margin-left: 5px !important;
  margin-right: 2px !important;
}

.minw100 {
  min-width: 100px;
}

.v-chip {
  min-width: 130px;
  text-align: center;
  justify-content: center;
}

.tickettooltip {
  background-color: #fac557;
}

.one-line {
  white-space: nowrap;
}

::v-deep .v-select__selections {
  min-height: 56px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

@media screen and (max-width: 1650px) {
  .refunded {
    max-width: 3vw;
  }
}
</style>
