var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content flex flex-row justify-space-between",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v(_vm._s(_vm.header ? _vm.header : "Saved Passenger") + " ")])]), _vm.haveActions ? _c('v-btn', {
    staticStyle: {
      "height": "40px",
      "font-size": "13px"
    },
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.isAdd = true;
        _vm.passengerDialog = true;
        _vm.selectedPassenger = {};
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v("mdi-account")]), _vm._v(" Create Passenger")], 1) : _vm._e()], 1)])]), _c('v-data-table', {
    staticClass: "elevation-1 mt-2",
    attrs: {
      "loading": _vm.loading || _vm.loadingInner,
      "mobile-breakpoint": 1500,
      "headers": _vm.headersPassenger,
      "items": _vm.passenger,
      "footer-props": {}
    },
    scopedSlots: _vm._u([{
      key: `item.firstName`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "orderID"
        }, [_c('div', {
          staticClass: "d-flex one-line"
        }, [_c('span', [_vm._v(_vm._s(item.firstName) + " " + _vm._s(item.lastName))])])])];
      }
    }, {
      key: `item.email`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('copyToClipboardField', {
          attrs: {
            "item": item.email
          }
        })];
      }
    }, {
      key: `item.phone`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('copyToClipboardField', {
          attrs: {
            "item": item.phone
          }
        })];
      }
    }, {
      key: `item.countryOfResidence`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "orderID"
        }, [_c('div', {
          staticClass: "d-flex one-line"
        }, [_c('span', [_vm._v(_vm._s(_vm.titleize(item.countryOfResidence)))])])])];
      }
    }, _vm.haveActions ? {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              _vm.isAdd = false;
              _vm.passengerDialog = true;
              _vm.choosePassenger(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "third"
          }
        }, [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              _vm.dialogDelete = true;
              _vm.choosePassenger(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-delete")])], 1)], 1)];
      }
    } : null], null, true)
  })], 1), _vm.passengerDialog ? _c('v-dialog', {
    staticClass: "pa-5",
    attrs: {
      "width": "1000px"
    },
    on: {
      "click:outside": function ($event) {
        _vm.passengerDialog = false;
      }
    },
    model: {
      value: _vm.passengerDialog,
      callback: function ($$v) {
        _vm.passengerDialog = $$v;
      },
      expression: "passengerDialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "ml-2"
  }, [_vm._v("Passenger "), !_vm.isAdd ? _c('span', [_vm._v("Update")]) : _c('span', [_vm._v("Create")])]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.passengerDialog = false;
        _vm.selectedPassenger = {};
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _vm.selectedPassenger && _vm.selectedPassenger != 'x' ? _c('passengerDetail', {
    attrs: {
      "accountId": _vm.accountId,
      "passenger": _vm.selectedPassenger,
      "isAdd": _vm.isAdd
    },
    on: {
      "close": function ($event) {
        _vm.passengerDialog = false;
        _vm.savedPassengers = {};
        _vm.getToken();
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "800px"
    },
    model: {
      value: _vm.dialogDelete,
      callback: function ($$v) {
        _vm.dialogDelete = $$v;
      },
      expression: "dialogDelete"
    }
  }, [_c('v-card', {
    staticClass: "delete-dialog"
  }, [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v("Are you sure you want to delete this passenger?")]), _c('v-card', [_c('v-row', {
    staticClass: "mt-5 mb-5"
  }, [_c('v-col', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "0.5rem",
      "align-items": "center"
    },
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('h4', {
    staticClass: "accent--text"
  }, [_vm._v("First Name")]), _c('div', [_vm._v(_vm._s(_vm.selectedPassenger.firstName))])]), _c('v-col', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "0.5rem",
      "align-items": "center"
    },
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('h4', {
    staticClass: "accent--text"
  }, [_vm._v("Last Name")]), _c('div', [_vm._v(_vm._s(_vm.selectedPassenger.lastName))])]), _c('v-col', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "0.5rem",
      "align-items": "center"
    },
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('h4', {
    staticClass: "accent--text"
  }, [_vm._v("Email")]), _c('div', [_vm._v(_vm._s(_vm.selectedPassenger.email))])]), _c('v-col', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "0.5rem",
      "align-items": "center"
    },
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('h4', {
    staticClass: "accent--text"
  }, [_vm._v("Country of Residance")]), _c('div', [_vm._v(_vm._s(_vm.selectedPassenger.countryOfResidence))])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.dialogDelete = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.deletePassenger
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading || _vm.loadingInner,
      expression: "!loading || loadingInner"
    }]
  }, [_vm._v(" Delete ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading || _vm.loadingInner,
      expression: "loading || loadingInner"
    }],
    staticClass: "mng-loader"
  })]), _c('v-spacer')], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }