<template>
  <div class="my-5">
    <Header title="Inventory" />

    <v-data-table
      :headers="headers"
      :items="boxes"
      :loading="loading"
      class="elevation-1"
      :items-per-page="10"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getColor(item.status)" small style="min-width:80px;" dark>
          {{ item.status }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";

export default {
  props: {
    getPapers: {
      required: true,
      type: Function,
    },
    boxes: {
      required: true,
      type: Array,
    },
  },
  components: {
    Header,
  },
  data: () => ({
    loading: false,
    filter: {
      itemsPerPage: 10,
    },
    headers: [
      {
        text: "Box Number",
        value: "boxNumber",
        sortable: false,
      },
      {
        text: "Min Paper Number",
        value: "minPaperNumber",
        sortable: false,
      },
      {
        text: "Max Paper Number",
        value: "maxPaperNumber",
        sortable: false,
      },
      {
        text: "Papers Left",
        value: "papersLeft",
        sortable: false,
      },
      {
        text: "Total",
        value: "papersTotal",
        sortable: false,
      },
      {
        text: "Created",
        value: "created",
        sortable: false,
      },
      {
        text: "Last Used",
        value: "lastUsed",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        align :"end"
      },
    ],
  }),
  mounted() {
    this.getPapers();
  },

  watch: {
    filter(val) {
      // this code also initilazing mounted data on start...
      //if (val.itemsPerPage) {
      //  this.getPapers();
      //}
    },
  },
  methods: {
    getColor(item) {
      if (item == "Opened") return "greeny";
      if (item == "Not Used") return "accent";
      if (item == "Used") return "primary";

      return "primary";
    },
  },
};
</script>

<style lang="scss" scoped></style>
