<template>
  <div>
    <!-- TOOL BAR -->
    <v-row>
      <v-col cols="12" lg="6" md="12" sm="12" xs="12">
        <!-- Search Input Field -->
        <v-text-field
          solo
          autofocus
          v-model="options.search"
          hide-details
          prepend-inner-icon="mdi-magnify"
          placeholder="Search for groups..."
          persistent-hint
          clearable></v-text-field>
      </v-col>
      <v-col cols="12" lg="6" md="12" sm="12" xs="12">
        <div class="d-flex">
          <!-- Select Tenants -->
          <v-select
            solo
            class="align-stretch font-weight-bold"
            v-model="selectedTenants"
            :items="tenants"
            chips
            hide-details
            item-text="name"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="Select tenants"
            multiple
            :persistent-hint="false">
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 3" class="grey--text text-caption"> (+{{ selectedTenants.length - 3 }}) </span>
            </template>
          </v-select>
          <!-- Add Group Button (visible only for admins) -->
          <v-btn
            class="ml-5"
            style="height: 48px; width: 170px"
            dark
            small
            color="accent"
            @click="openCreateDialog"
            v-if="userCanManageGroups">
            <v-icon class="mr-3 mb-1">mdi-account-group</v-icon>
            ADD GROUP
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- Create Group Component -->
    <create-group
      @created="getGroups"
      @onClose="
        isDialogOpened = false;
        editItem = null;
      "
      :isDialogOpen="isDialogOpened"
      :edited-group="editItem" />
    <!-- DATA TABLE -->
    <v-data-table
      class="mt-6"
      :headers="headers"
      :server-items-length="totalGroups"
      :items="groups"
      :loading="loading"
      :items-per-page="options.itemsPerPage"
      :options.sync="options"
      @update:items-per-page="getGroups"
      @update:page="getGroups"
      :footer-props="{
        'items-per-page-options': [50, 100],
      }"
      ><template v-slot:[`item.users`]="{ item }">
        <v-chip
          v-for="(user, index) in item.users.slice(0, 3)"
          :key="index"
          class="font-weight-bold cliporder mr-1"
          style="min-width: 10px; font-size: 12px; min-height: 27px; text-transform: capitalize"
          link
          x-small>
          {{ user.fullName ?? user.name }}
        </v-chip>
        <span v-if="item.users.length > 3" class="grey--text text-caption"> (+{{ item.users.length - 3 }}) </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div v-if="userCanManageGroups">
          <!-- View Button -->
          <v-btn @click="view(item)" icon>
            <v-icon color="accent">mdi-eye</v-icon>
          </v-btn>
          <!-- Edit Button -->
          <v-btn @click="openEditModal(item)" icon>
            <v-icon color="third">mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <!-- Detail Group Component -->
    <detail-group
      :item="editItem"
      v-if="editItem"
      @onClose="
        isDetailDialogOpen = false;
        editItem = null;
      "
      :isDialogOpen="isDetailDialogOpen" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "@/util/helpers";
import CreateGroup from "./components/CreateGroup.vue";
import common from "@/mixins/common";
import Trainplanet from "@/util/trainplanet.api";
import DetailGroup from "@/views/User/Group/components/DetailGroup.vue";
import { ROLES } from "@/store/modules/roles";

export default {
  components: {
    CreateGroup,
    DetailGroup,
  },
  mixins: [common],
  data: () => ({
    options: {
      search: null,
      page: 1,
      itemsPerPage: 50,
      total: 0,
    },
    totalGroups: 0,
    groups: [],
    isDetailDialogOpen: false,
    isDialogOpened: false,
    warnNoEmployee: false,
    detailDialog: false,
    detail: null,
    dialog: false,
    saveLoader: false,
    isLoading: false,
    loading: false,
    selectedTenants: [],
    headers: [
      {
        text: "Group Name",
        value: "name",
        width: 250,
        sortable: false,
      },
      {
        text: "Users",
        value: "users",
        align: "start",
        width: 500,
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        width: 100,
        sortable: false,
      },
    ],
    editItem: null,
    isSelectedTenantWatchable: true,
  }),
  mounted() {
    this.getGroups();
    this.isSelectedTenantWatchable = false;
  },
  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
      userRoles: "auth/userRoles",
      user: "auth/user",
    }),
    userCanManageGroups() {
      const roles = [ROLES.ACCOUNT_MANAGER, ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN];

      return this.userRoles.some((role) => roles.includes(role));
    },
  },
  watch: {
    tenants: {
      deep: true,
      handler: function () {
        // Update selected tenants when tenants change
        this.selectedTenants = this.tenants.map((item) => item.id);
      },
    },
    selectedTenants: {
      deep: true,
      handler: debounce(function () {
        if (this.isSelectedTenantWatchable) {
          this.getGroups();
        }
        this.isSelectedTenantWatchable = true;
      }, 500),
    },
    "options.search": {
      handler: debounce(function () {
        this.getGroups();
      }, 500),
    },
  },
  methods: {
    openCreateDialog() {
      // Open create dialog for adding a group
      this.isDialogOpened = true;
      this.editItem = null;
    },
    closeDialog() {
      // Emit event to close dialog
      this.$emit("onClose");
    },
    view(item) {
      // Open detail dialog to view a group
      this.isDetailDialogOpen = true;
      this.editItem = item;
    },
    openEditModal(item) {
      // Open edit dialog for a group
      this.editItem = item;
      this.isDialogOpened = true;
    },
    async getGroups() {
      this.loading = true;
      try {
        // Fetch groups from the server
        const response = await Trainplanet.getGroups({
          tenantIds: this.selectedTenants,
          q: this.options.search,
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
        });
        this.groups = response.groups;
        this.totalGroups = response.meta.total;
      } catch (error) {
        // Handle error
        await this.$store.dispatch("error", error.response.data.message || error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
