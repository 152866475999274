var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "elevation-1 rounded pa-5 white"
  }, [_c('v-row', {
    staticClass: "align-center"
  }, [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v(" " + _vm._s(_vm.account.id) + " " + _vm._s(_vm.account.name) + " ")])])]), _c('v-spacer')], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }