var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    attrs: {
      "title": "Insert New Box"
    }
  }), _c('v-card', {
    staticClass: "px-4 py-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "md": "4",
      "xs": "12",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "solo": "",
      "clearable": "",
      "autofocus": "",
      "placeholder": "First Paper Number",
      "type": "number"
    },
    model: {
      value: _vm.paperBody.firstPaperNumber,
      callback: function ($$v) {
        _vm.$set(_vm.paperBody, "firstPaperNumber", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "paperBody.firstPaperNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "4",
      "xs": "12",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "solo": "",
      "clearable": "",
      "autofocus": "",
      "placeholder": "Last Paper Number",
      "type": "number"
    },
    model: {
      value: _vm.paperBody.lastPaperNumber,
      callback: function ($$v) {
        _vm.$set(_vm.paperBody, "lastPaperNumber", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "paperBody.lastPaperNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "4",
      "xs": "12",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "solo": "",
      "clearable": "",
      "autofocus": "",
      "placeholder": "Box Number"
    },
    model: {
      value: _vm.paperBody.boxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.paperBody, "boxNumber", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "paperBody.boxNumber"
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-end w-100",
    staticStyle: {
      "width": "100%",
      "justify-content": "flex-end"
    }
  }, [_c('v-btn', {
    attrs: {
      "saveLoader": _vm.saveLoading,
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.savePaper();
      }
    }
  }, [_vm._v("Save")])], 1)], 1), _c('PaperStorageTable', {
    attrs: {
      "boxes": _vm.boxes,
      "getPapers": _vm.getPapers
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }