var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-5"
  }, [_c('Header', {
    attrs: {
      "title": "Inventory"
    }
  }), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.boxes,
      "loading": _vm.loading,
      "items-per-page": 10,
      "footer-props": {
        'items-per-page-options': [10, 20, 50, 100]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.status`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-chip', {
          staticStyle: {
            "min-width": "80px"
          },
          attrs: {
            "color": _vm.getColor(item.status),
            "small": "",
            "dark": ""
          }
        }, [_vm._v(" " + _vm._s(item.status) + " ")])];
      }
    }], null, true)
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }