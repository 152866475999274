<template>
  <div>
    <div class="elevation-1 rounded pa-5 white">
      <v-row class="align-center">
        <v-col md="auto">
          <div class="d-flex align-center">
            <h1 class="secondary--text">
              {{ account.id }}
              {{ account.name }}
            </h1>
            <!--
            <v-btn v-if="account.inquiries && account.inquiries.length" @click="navInquiries(account.inquiries)"
              class="ml-2" small color="accent">HAS CLAIM TICKET
            </v-btn> -->
          </div>

          <!-- <div class="caption secondary--text">
            Created
            <code class="text-caption">{{ account.created | getOnlyDate }}
                                {{ account.created | getOnlyTime }}</code>
            (GMT+{{ getGmtDiff }}) by
            <code class="text-caption">{{ account.seller.user.name }}</code>
          </div> -->
        </v-col>
        <v-spacer></v-spacer>
        <!-- These slots for validation systems. -->

      </v-row>
    </div>
  </div>
</template>

<script>
import toolbarTitle from "@/components/common/toolbarTitle";
import common from "@/mixins/common";
import { copyToClipboard, fixUrl } from "@/util/helpers";
import moment from "moment";

export default {
  components: {
    toolbarTitle,
    copyToClipboard,
  },
  mixins: [common],
  data: () => ({}),
  computed: {
    getGmtDiff() {
      return moment().utcOffset() / 60;
    },
  },
  filters: {
    humanDatetime(datetime) {
      return moment.utc(datetime).local().fromNow();
    },
    getOnlyDate(datetime) {
      return moment.utc(datetime).local().format("YYYY-MM-DD");
    },
    getOnlyTime(datetime) {
      // get gmt offset
      return moment.utc(datetime).local().format("HH:mm");
    },
  },
  methods: {
    navInquiries(value) {
      window.open("/orders/inquiries/" + value);
    },
    copyToClipboard,
    fixUrl,
    groupedByExternalSystem(items = []) {
      return items.reduce((acc, curr) => {
        const externalSystem = acc.find(
          (item) => curr.externalSystem === item.externalSystem
        );
        if (externalSystem) {
          externalSystem.groupedExternalIds.push(curr.externalOrderId);
        } else {
          acc.push({ ...curr, groupedExternalIds: [curr.externalOrderId] });
        }
        return acc;
      }, []);
    },
  },
  props: {
    account: [],
  },
};
</script>

<style lang="scss" scoped></style>
