<template>
  <div>





    <header class="theme--light v-navigation-drawer elevation-1"
      style="height: 50px; border-radius: 5px; margin-bottom: 10px">
      <div class="v-toolbar__content flex flex-row justify-space-between" style="height: 50px; padding: 25px">
        <div class="v-toolbar__title">
          <h4 class="black--text">Companies </h4>
        </div>
        <div style="display:flex;gap:10px">
          <v-btn @click="goToCompanyList" color="primary" style="height: 40px; font-size: 13px">
            <v-icon class="mr-3">mdi-domain</v-icon>
            Go To Company List
          </v-btn>

          <v-btn :disabled="isDeleted" color="primary" @click="
            isAdd = true;
          customerDialog = true;
          selectedCustomer = {}
            " style="height: 40px; font-size: 13px"> <v-icon class="mr-3">mdi-account</v-icon> Add
            Customer</v-btn>
        </div>
      </div>

    </header>


    <v-dialog v-model="customerDialog" v-if="customerDialog" width="90%" class="pa-5"
      @click:outside="customerDialog = false">
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dense dark>
          <v-toolbar-title class="ml-2">Customer <span>Add</span>
            </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="customerDialog = false; selectedCustomer = {}">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <CustomerAdd v-on:close="customerDialog = false; takeToken()" :accountId="accountId" v-if="customerDialog"
          :ignoredCustomers="connectedCustomers" />
      </v-card>
    </v-dialog>




    <v-data-table :hide-default-footer="true" :disable-pagination="true" :headers="headers" :items="companies"
      :loading="loading" :server-items-length="companiesTotalCount" :items-per-page="options.itemsPerPage" show-expand
      :options.sync="options" class="elevation-1 " :footer-props="{
        'items-per-page-options': [50, 100],
      }">

      <template v-slot:[`item.name`]="{ item }">
        <v-icon class="mr-2">mdi-domain </v-icon>
        <b>{{ item.name }}</b>
      </template>
      <template v-slot:[`item.organizationNumber`]="{ item }">
        <copyToClipboardField :item="item.organizationNumber"></copyToClipboardField>
      </template>
      <template v-slot:[`item.fortnoxNumber`]="{ item }">
        <copyToClipboardField :item="item.fortnoxNumber"></copyToClipboardField>
      </template>
      <template v-slot:[`item.email`]="{ item }">
        <copyToClipboardField :item="item.email"></copyToClipboardField>
      </template>
      <!-- <template v-slot:[`item.remainingLimit.plainText`]="{ item }">
        <b :class="item.limit.amount > 0 ? 'greeny--text' : 'primary--text'">{{ item.remainingLimit.plainText }}
        </b>
      </template> -->
      <template v-slot:[`item.tenants`]="{ item }">
        <v-chip v-for="tenant in item.tenants" :key="tenant.id" color="secondary"
          class="font-weight-bold cliporder mr-1 mb-1" link small>
          {{ tenant.name }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn icon @click="
            companyData = { ...item };
          initCustomersByCompanyId(item.id);
          detailDialog = true;
          ">
            <v-icon color="accent">mdi-eye</v-icon>
          </v-btn>

          <!-- <v-btn v-if="isAdmin" @click="deleteCompany(item.id)" icon>
            <v-icon color="primary">mdi-delete</v-icon>
          </v-btn> -->
        </div>
      </template>
      <template v-slot:[`expanded-item`]="{ headers, item }">

        <td :colspan="headers.length">
          <div class="py-4">
            <CustomerList v-on:takeCustomer="takeToken" :customer="item.customers" :loading="loading"
              :accountId="accountId" />
          </div>
        </td>


      </template>

    </v-data-table>

    <!-- CREATE & UPDATE DIALOG -->
    <v-dialog v-model="dialog" :loading="isLoading" max-width="70%" @click:outside="closeDialog">
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dense dark>
          <v-toolbar-title>{{ editing ? "Update" : "Create" }} Company</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="companyForm">
          <v-card-text>
            <v-container class="mt-1 pt-0">
              <h3 class="my-3 mt-0">Company Information</h3>
              <v-row>
                <v-col cols="12" lg="6" md="12" sm="12" xs="12">
                  <v-text-field v-model="companyData.name" :rules="validate.input" outlined label="Company Name" dense
                    hide-details clearable />
                </v-col>
                <v-col cols="12" lg="6" md="12" sm="12" xs="12">
                  <v-text-field outlined label="Organization Number" hide-details :rules="validate.input" clearable dense
                    v-model="companyData.organizationNumber" required />
                </v-col>
              </v-row>
              <h3 class="my-3">Contact Person</h3>
              <v-row>
                <v-col cols="12" lg="6" md="12" sm="12" xs="12">
                  <v-text-field outlined label="Full Name" :rules="validate.input" dense hide-details clearable
                    v-model="companyData.contactPersonName" />
                </v-col>
                <v-col cols="12" lg="3" md="12" sm="12" xs="12">
                  <v-text-field outlined label="Contact Person's Phone Number" hide-details :rules="validate.phone" dense
                    clearable v-model="companyData.phone" />
                </v-col>
                <v-col cols="12" lg="3" md="12" sm="12" xs="12">
                  <v-text-field outlined label="Contact Person's E-mail" :rules="validate.email" dense hide-details
                    clearable v-model="companyData.email" />
                </v-col>
              </v-row>
              <h3 class="my-3">Reference</h3>
              <v-row>
                <v-col cols="12" lg="6" md="12" sm="12" xs="12">
                  <v-text-field outlined label="Email for Invoice or E-Invoice" :rules="validate.email" dense hide-details
                    clearable v-model="companyData.invoiceEmail" />
                </v-col>
                <v-col cols="12" lg="3" md="12" sm="12" xs="12">
                  <v-text-field outlined label="Fortnox Number" hide-details dense clearable
                    v-model="companyData.fortnoxNumber" />
                </v-col>

                <!-- <v-col cols="12" lg="3" md="12" sm="12" xs="12">
                  <v-text-field
                    outlined
                    label="IATA-Number"
                    hide-details
                    dense
                    clearable
                    v-model="companyData.iataNumber"
                  />
                </v-col> -->

                <v-col cols="12" lg="3" md="12" sm="12" xs="12">
                  <v-text-field outlined label="Other / Branch" hide-details dense clearable
                    v-model="companyData.reference" />
                </v-col>
              </v-row>

              <h3 class="my-3">Finance</h3>
              <v-row>
                <v-col cols="12" lg="3" md="12" sm="12" xs="12">
                  <v-text-field outlined label="Limit" hide-details dense clearable type="number"
                    v-model="companyData.limit.amount" v-on:change="
                      companyData.limit.amount = parseFloat(
                        companyData.limit.amount
                      )
                      " />
                </v-col>
                <v-col cols="12" lg="3" md="12" sm="12" xs="12">
                  <v-select outlined v-model="companyData.limit.currency" :items="['SEK']" dense hide-details
                    :menu-props="{ maxHeight: '400' }" label="Select currency" :persistent-hint="false"></v-select>
                </v-col>
                <v-col cols="12" lg="3" md="12" sm="12" xs="12">
                  <v-select outlined v-model="companyData.allowedDaysToPay" :items="[10, 15, 20, 30]" dense hide-details
                    :menu-props="{ maxHeight: '400' }" label="Allowed days to pay the invoice" :persistent-hint="false"
                    class="max-h"></v-select>
                </v-col>
                <v-col cols="12" lg="3" md="12" sm="12" xs="12" class="d-flex align-center">
                  <v-switch v-model="companyData.automaticInvoiceFeeEnabled" color="greeny" hide-details
                    label="Automatically Add Invoice Fee in New Orders" class="pa-0 ma-0 switch" />
                </v-col>
              </v-row>
              <v-textarea class="mt-3" outlined rows="2" style="background-color: #fff6d9" label="Internal Message"
                hide-details clearable v-model="companyData.comment" />
              <h3 class="my-3">Authorized Employees</h3>
              <p>You can add an employee just by the IATA number, or filling up the rest of the fields.</p>
              <v-form ref="employeeForm" class="mb-2">
                <v-row>
                  <v-col cols="12" lg="2" md="12" sm="12" xs="12">
                    <v-text-field outlined label="First Name" dense clearable v-model="customer.firstName"
                      :rules="[v => (!!customer.firstName || (!!validate.input && !customer.lastName && !customer.email && !customer.phone)) || 'First name is required.']" />
                  </v-col>
                  <v-col cols="12" lg="2" md="12" sm="12" xs="12">
                    <v-text-field outlined label="Last Name" dense clearable v-model="customer.lastName"
                      :rules="[v => (!!customer.lastName || (!!validate.input && !customer.firstName && !customer.email && !customer.phone)) || 'Last name is required.']" />
                  </v-col>
                  <v-col cols="12" lg="3" md="12" sm="12" xs="12">
                    <v-text-field outlined label="E-Mail Address" dense clearable v-model="customer.email"
                      :rules="[v => (!!customer.email || (!!validate.input && !customer.firstName && !customer.lastName && !customer.phone)) || 'E-Mail address is required.']" />
                  </v-col>
                  <v-col cols="12" lg="2" md="12" sm="12" xs="12">
                    <v-text-field outlined label="Phone Number" dense clearable v-model="customer.phone"
                      :rules="[v => (!!customer.phone || (!!validate.input && !customer.firstName && !customer.lastName && !customer.email)) || 'Phone number is required.']" />
                  </v-col>
                  <v-col cols="12" lg="2" md="12" sm="12" xs="12">
                    <v-text-field outlined label="IATA Number"
                      :rules="[v => (!!v || (!!customer.firstName && !!customer.lastName && !!customer.email && !!customer.phone)) || (!!customer.firstName || !!customer.lastName || !!customer.email || !!customer.phone) || 'IATA Number or other fields are required.']"
                      dense clearable v-model="customer.companyCustomerNumber" />
                  </v-col>
                  <v-col cols="12" class="mb-6 pr-lg-0" lg="1" md="12" sm="12" xs="12">
                    <v-btn color="accent" dark height="100%" block small @click.prevent="addCustomer">
                      ADD
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-divider style="margin-bottom: 10px;"></v-divider>

              <div class="text-center">
                <v-progress-circular :size="50" color="primary" class="mt-2" v-if="isLoading"
                  indeterminate></v-progress-circular>
              </div>
              <v-row v-for="(item, index) in companyData.customers" :key="index" class="my-0 py-0">
                <v-col cols="12" lg="2" md="12" sm="12" xs="12" style="position: relative">
                  <v-icon color="mango-red " style="
                      position: absolute;
                      z-index: 999999;
                      right: 25px;
                      top: 23px;
                    " @click="copyToClipboard(item.firstName)" small>
                    mdi-content-copy
                  </v-icon>
                  <v-text-field outlined readonly label="First Name" hide-details dense v-model="item.firstName" />
                </v-col>

                <v-col cols="12" lg="2" md="12" sm="12" xs="12" style="position: relative">
                  <v-icon color="mango-red " style="
                      position: absolute;
                      z-index: 999999;
                      right: 25px;
                      top: 23px;
                    " @click="copyToClipboard(item.lastName)" small>
                    mdi-content-copy
                  </v-icon>
                  <v-text-field outlined readonly label="Last Name" hide-details dense v-model="item.lastName" />
                </v-col>

                <v-col cols="12" lg="3" md="12" sm="12" xs="12" style="position: relative">
                  <v-icon color="mango-red " style="
                      position: absolute;
                      z-index: 999999;
                      right: 25px;
                      top: 23px;
                    " @click="copyToClipboard(item.email)" small>
                    mdi-content-copy
                  </v-icon>
                  <v-text-field outlined readonly label="E-mail" hide-details dense v-model="item.email" />
                </v-col>

                <v-col cols="12" lg="2" md="12" sm="12" xs="12" style="position: relative">
                  <v-icon color="mango-red " style="
                      position: absolute;
                      z-index: 999999;
                      right: 25px;
                      top: 23px;
                    " @click="copyToClipboard(item.phone)" small>
                    mdi-content-copy
                  </v-icon>

                  <v-text-field outlined readonly label="Phone" hide-details dense v-model="item.phone" />
                </v-col>

                <v-col cols="12" lg="2" md="12" sm="12" xs="12" style="position: relative">
                  <v-icon color="mango-red " style="
                      position: absolute;
                      z-index: 999999;
                      right: 25px;
                      top: 23px;
                    " @click="copyToClipboard(item.companyCustomerNumber)" small>
                    mdi-content-copy
                  </v-icon>

                  <v-text-field outlined label="IATA Number" hide-details dense readonly
                    v-model="item.companyCustomerNumber" />
                </v-col>

                <v-col cols="12" lg="1" md="12" sm="12" xs="12">
                  <v-btn color="primary" block height="100%" small dark @click="removeCustomer(index)">
                    REMOVE
                  </v-btn>
                </v-col>
              </v-row>

              <alert v-if="warnNoEmployee &&
                companyData.customers &&
                companyData.customers.length === 0
                " type="error" color="primary"
                textBold="You must add at least one authorized employee for the company you will add." />
            </v-container>
          </v-card-text>

          <v-card-actions class="pa-7 pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="closeDialog"> Cancel </v-btn>
            <v-btn :loading="saveLoader" color="accent" dark @click="createOrUpdateCompany">
              {{ editing ? "Update" : "Create" }} Company
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- COMPANY DETAIL DIALOG -->
    <v-dialog v-model="detailDialog" :loading="isLoading" width="1000px" class="pa-5"
      @click:outside="detailDialog = false">
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dense dark>
          <v-toolbar-title class="ml-2">Company: {{ companyData && companyData.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <companyDetail v-if="companyData" :company="companyData" :loading="isLoading" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import alert from "@/components/common/alert";
import copyToClipboardField from "@/components/common/copyToClipboardField";
import companyDetail from "@/components/info/companyDetail";
import common from "@/mixins/common";
import { copyToClipboard, debounce, validator } from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api";
import CustomerList from "@/views/User/Account/customer-list";
import CustomerAdd from "@/views/User/Account/customerAdd.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import countryList from "../../../static/countries.json";

export default {
  components: {
    alert,
    companyDetail,
    ValidationObserver,
    ValidationProvider,
    copyToClipboardField,
    CustomerList,
    CustomerAdd
  },
  emits: ['refresh'],
  props: {
    propCompanies: "",
    loading: false,
    isDeleted: false,
    connectedCustomers: [],
    accountId: ""
  },
  mixins: [common],
  data: () => ({
    options: {},
    customerDialog: false,
    companiesTotalCount: 0,
    warnNoEmployee: false,
    countries: countryList,
    validate: {
      greater: validator.greaterZeroCheck(),
      input: validator.required(),
      phone: validator.phone(),
      postalcode: validator.postalCode(),
      email: validator.email(),
      orderNumber: validator.orderNumber(),
      select: validator.select(),
    },
    detailDialog: false,
    detail: null,
    customer: {
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      companyCustomerNumber: null,
    },
    dialog: false,
    editing: true,
    saveLoader: false,
    isLoading: false,
    searchQuery: "",
    isCustomerAddingCollapseOpen: false,
    dtoCompanyData: {
      iataNumber: null,
      reference: null, //other
      fortnoxNumber: null,
      contactPersonName: null,
      invoiceEmail: null,
      name: null,
      organizationNumber: null,
      phone: null,
      email: null,
      tenants: [],
      limit: {
        amount: 0,
        currency: "SEK",
      },
      comment: null,
      customers: [],
      trusted: true,
      automaticInvoiceFeeEnabled: true,
      allowedDaysToPay: 10,
      vatNumber: null,
      address: null,
      postalCode: null,
      city: null,
      country: null,
    },
    companyData: {
      iataNumber: null,
      reference: null, //other
      fortnoxNumber: null,
      contactPersonName: null,
      invoiceEmail: null,
      name: null,
      organizationNumber: null,
      phone: null,
      email: null,
      tenants: [],
      limit: {
        amount: 0,
        currency: "SEK",
      },
      comment: null,
      customers: [],
      trusted: true,
      automaticInvoiceFeeEnabled: true,
      allowedDaysToPay: 10,
      vatNumber: null,
      address: null,
      postalCode: null,
      city: null,
      country: null,
    },
    selectedTenants: [],
    companies: [],
    headerCustomer: [{
      text: " Name",
      value: "firstName",
    }],
    headers: [
      {
        text: "Company Name",
        value: "name",
      },
      {
        text: "Organization Number",
        value: "organizationNumber",
        width: 180,
      },
      {
        text: "Fortnox Number",
        value: "fortnoxNumber",
      },
      {
        text: "Phone Number",
        value: "phone",
      },
      {
        text: "E-Mail",
        value: "email",
        width: 220,
      },
      {
        text: "Limit",
        value: "limit.plainText",
        align: "end",
      },
      {
        text: "Remaining Limit",
        value: "remainingLimit.plainText",
        align: "end",
      },
      {
        text: "Actions",
        value: "actions",
        align: "end",
        width: 150,
      },
    ],
  }),
  async mounted() {
    this.selectedTenants = this.tenants.map((item) => item.id);

  },

  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
      isAdmin: "auth/isAdmin",
    }),
  },
  watch: {

    'propCompanies': {
      handler: function () {
        this.getCompanies()
      },
    },

    options: {
      deep: true,
      handler: debounce(function () {
        this.getCompanies();
      }, 500),
    },
    detailDialog: {
      deep: true,
      handler: function () {
        if (this.detail) {
          this.initCustomersByCompanyId(this.detail.id);
        }
      },
    },
    searchQuery: {
      deep: true,
      handler: debounce(function () {
        this.getCompanies();
      }, 200),
    },
    tenants: {
      deep: true,
      handler: function () {
        this.selectedTenants = this.tenants.map((item) => item.id);
      },
    },
    selectedTenants: {
      deep: true,
      handler: debounce(function () {
        if (this.tenants.length > 0) {
          this.getCompanies();
        }
      }, 500),
    },
  },
  methods: {
    takeToken() {
      this.$emit('refresh')
    },
    goToCompanyList() {
      this.$router.push({
        name: "companies"

      });
    },
    copyToClipboard,
    clearCompanyData() {
      this.companyData = { ...this.dtoCompanyData };
      this.companyData.customers = [];
    },
    closeDialog() {
      this.editing = false;
      this.dialog = false;
    },
    async openCreateCompanyForm() {
      this.dialog = true;
      this.editing = false;
      this.warnNoEmployee = false;
      this.clearCompanyData();
    },
    async addCustomer() {
      if (this.$refs[`employeeForm`].validate()) {
        this.companyData.customers.push({
          ...this.customer,
          delivery: { ...this.customer },
          billing: { ...this.customer },
          company: { ...this.customer },
          payment: { ...this.customer },
        });
        this.$refs[`employeeForm`].reset();
      }
    },
    removeCustomer(index) {
      this.isLoading = true;
      this.companyData.customers.splice(index, 1);
      this.isLoading = false;
    },
    async getCompanies() {

      this.companies = this.propCompanies;
      this.companiesTotalCount = 10;

    },
    async createOrUpdateCompany() {
      if (this.$refs[`companyForm`].validate()) {
        if (this.companyData.customers.length === 0) {
          this.warnNoEmployee = true;
        } else {
          this.saveLoader = true;
          try {
            if (this.editing) {
              await Trainplanet.updateCompany(
                this.companyData.id,
                this.companyData
              );
            } else {
              await Trainplanet.createCompany(this.companyData);
            }
            await this.$store.dispatch(
              "success",
              `You have successfully ${this.editing ? "updated" : "created"
              } a company!`
            );
            this.dialog = false;
            this.clearCompanyData();
            await this.getCompanies();
          } catch (error) {
            await this.$store.dispatch(
              "error",
              error.response.data.message || error.message
            );
          } finally {
            this.saveLoader = false;
          }
        }
      }
    },
    async deleteCompany(companyId) {
      this.$confirm("Are you sure that you want to delete this company?", {
        color: "primary",
        title: "Delete Company",
      }).then(async (res) => {
        if (!res) return;
        await this.$store.dispatch("loading", true);
        try {
          await Trainplanet.deleteCompany(companyId);
          await this.$store.dispatch(
            "success",
            "You have successfully deleted a company!"
          );
          await this.getCompanies();
        } catch (error) {
          await this.$store.dispatch(
            "error",
            error.response.data.message || error.message
          );
        } finally {
          await this.$store.dispatch("loading", false);
        }
      });
    },
    async initCustomersByCompanyId(companyId) {
      try {
        this.isLoading = true;
        const res = await Trainplanet.getCustomerList({ companyId });
        this.companyData.customers = res.customers;
      } catch (error) {
        await this.$store.dispatch(
          "error",
          error.response.data.message || error.message
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
