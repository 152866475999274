<template>
  <v-card class="account-detail">



    <header class="theme--light v-navigation-drawer elevation-1"
      style="height: 50px; border-radius: 5px; margin-bottom: 10px">
      <div class="v-toolbar__content flex flex-row justify-space-between" style="height: 50px; padding: 25px">
        <div class="v-toolbar__title">
          <h4 class="black--text">Account Detail </h4>
        </div>
        <div class="d-flex" style="gap: 20px;">


          <div class="d-flex lock" style="gap: 20px;">
            <h3>Account Verified</h3>
            <img src="../../../assets/accept.png" v-if="account.isVerified" alt="">
            <img src="../../../assets/cancel.png" v-if="!account.isVerified" alt="">
            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span>Accounts have to verify their e-mail addresses to be able to log in.</span>
            </v-tooltip>
          </div>


          <div class="d-flex lock" style="gap: 20px;">
            <h3 v-if="account.isDeleted" alt="">Account Deleted</h3>
            <h3 v-if="!account.isDeleted" alt="">Account Active</h3>
            <img src="../../../assets/accept.png" v-if="!account.isDeleted" alt="">
            <img src="../../../assets/cancel.png" v-if="account.isDeleted" alt="">

          </div>

          <v-btn color="primary" @click="isUpdate = !isUpdate" style="height: 40px; font-size: 13px">
            <v-icon class="mr-3">mdi-refresh</v-icon>
            Update Account</v-btn>
        </div>
      </div>

    </header>







    <AccountUpdate :account="account" :isUpdate="isUpdate" v-on:success="getToken(); isUpdate = false" />


    <passengerlist :haveActions="true" :tenant="tenant" :token="token" v-on:change="getToken" :accountId="account.id"
      :loading="loading" :passenger="account.savedPassengers" />

    <v-row class="mt-4">


      <v-col cols="12">
        <GlobalCompanyList :isDeleted="account.isDeleted" v-on:refresh="getToken" :connectedCustomers="customer"
          :loading="loading" :accountId="accountId" :propCompanies="customerFilter(account.companyCustomers)">
        </GlobalCompanyList>
      </v-col>



    </v-row>


    <float-buttons :isDeleted="account.isDeleted" :isVerified="account.isVerified" :isSendVerify="isSendVerify"
      :isSendPassword="isSendPassword" :isDirectVerify="isDirectVerify" :loading="loading" :tenants="account.tenants"
      v-on:sendVerify="sendVerifyLink($event);" v-on:openSendVerify="isSendVerify = true"
      v-on:closeSendVerify="isSendVerify = false" v-on:sendDirectVerify="sendDirectVerify"
      v-on:openDirectVerify="isDirectVerify = true" v-on:closeDirectVerify="isDirectVerify = false"
      v-on:closeSendPassword="isSendPassword = false" v-on:openSendPassword="isSendPassword = true"
      v-on:sendResetPassword="sendPassword($event);" v-on:delete="dialogDeleteAccount = true" />




    <v-dialog v-model="dialogDeleteAccount" max-width="600px" style="padding: 1rem;">
      <v-card>
        <v-card-title v-if="!account.isDeleted" class="text-h5">Are you sure you want to delete this
          account?</v-card-title>
        <v-card-title v-if="account.isDeleted" class="text-h5">Are you sure you want to undelete this
          account?</v-card-title>


        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogDeleteAccount = false">Cancel

          </v-btn>
          <v-btn v-if="!account.isDeleted" color="accent" @click="deleteAccount"> <span v-show="!loading"> Delete
            </span>
            <div v-show="loading" class="mng-loader"></div>
          </v-btn>
          <v-btn v-if="account.isDeleted" color="accent" @click="deleteAccount"> <span v-show="!loading"> Undelete
            </span>
            <div v-show="loading" class="mng-loader"></div>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <header class="theme--light v-navigation-drawer elevation-1 mt-4 mb-4"
      style="height: 50px; border-radius: 5px; margin-bottom: 10px">
      <div class="v-toolbar__content flex flex-row justify-space-between" style="height: 50px; padding: 25px">
        <div class="v-toolbar__title">
          <h4 class="black--text">Account Logs</h4>
        </div>

      </div>

    </header>

    <div v-if="events.length > 0">
      <v-card-text class="elevation-1">
        <v-timeline dense align-top>

          <event-list :events="events" />
        </v-timeline>
      </v-card-text>
    </div>






  </v-card>
</template>
<script>
import AccountIDField from "@/components/common/AccountDField";
import OrderIconsField from "@/components/common/OrderIconsField";
import copyToClipboardField from "@/components/common/copyToClipboardField";
import dateTimeField from "@/components/common/dateTimeField";
import toolbarTitle from "@/components/common/toolbarTitle";
import accountInfoCard from "@/components/info/accountInfoCard";
import router from "@/router";
import AccountApi from "@/services/account.api";
import AuthApi from "@/services/auth.api";
import store from "@/store";
import GlobalCompanyList from "@/views/Company/Company/GlobalCompanyList";
import ValidationSystems from "@/views/Orders/Order/ValidationSystems.vue";
import EventList from "@/views/Orders/components/EventList";
import CustomerList from "@/views/User/Account/customer-list";
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import common from "../../../mixins/common";
import floatButtons from "./FloatButtons.vue";
import AccountUpdate from "./account-update";
import passengerlist from "./passenger-list.vue";

Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + days);
  return this;
};

export default {
  name: "Search",
  components: {
    ValidationSystems,
    AccountUpdate,
    AccountIDField,
    CustomerList,
    OrderIconsField,
    toolbarTitle,
    dateTimeField,
    accountInfoCard,
    GlobalCompanyList,
    copyToClipboardField,
    floatButtons,
    EventList,
    passengerlist,
    ValidationObserver,
    ValidationObserver
  },
  mixins: [common],
  props: {
    dates: Array,
    status: String,
    query: String,
    customer_query: String,
    searchOnce: String // actually boolean but since it is in link query, it's a string
  },
  data: () => ({
    orders: [],
    customer: [],
    dialogDelete: false,
    ordersTotalCount: 0,
    dialogDeleteAccount: false,
    datepicker: false,
    selectedTenants: [],
    searchedAtLeastOnce: false,
    pageWatcherShouldReact: true,
    screenWidth: window.innerWidth,
    token: "",
    passengerDialog: false,
    account: "",
    isSendVerify: false,
    isDirectVerify: false,
    isSendPassword: false,
    isAdd: false,
    selectedPassenger: "",
    events: [],
    accountId: 0,
    tempTenant: "",
    tenant: "",
  
    isUpdate: false,
    selectedLanguage: "",
    loading: false,
    headers: [
      {
        text: "ID",
        value: "id",
        sortable: false,
        width: 250,
      },
      {
        text: "Name",
        value: "name",
        sortable: false,
      },
      {
        text: "Email",
        value: "email",
        sortable: false,
      },


    ],
    headersCompanies: [
      {
        text: "Name",
        value: "name",
        sortable: false,
      },
      {
        text: "ContactPerson",
        value: "contactPersonName",
        sortable: false,
      },
      {
        text: "Email",
        value: "invoiceEmail",
        sortable: false,
      },
      {
        text: "Organization Number",
        value: "organizationNumber",
        sortable: false,
      },
      {
        text: "Phone",
        value: "phone",
        sortable: false,
      },



    ],

    headersPassenger: [
      {
        text: "Full Name",
        value: "firstName",
        sortable: false,
        width: 250,
      },
      {
        text: "Email",
        value: "email",
        sortable: false,
      },
      {
        text: "Phone",
        value: "phone",
        sortable: false,
      },
      {
        text: "Birthdate",
        value: "birthdate",
        sortable: false,
      },
      {
        text: "Country",
        value: "countryOfResidence",
        sortable: false,
      },

      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },

    ],

  }),

  computed: {
    AccountModel() {
      return new AccountApi(this.tenant, this.token);
    },

    AuthModel() {
      return new AuthApi(this.tenant, this.token);
    },
    AuthWithTenant() {
      return new AuthApi(this.tempTenant, this.token);
    },
    AuthWithTenantLanguage() {
      return new AuthApi(this.tempTenant, this.token, this.selectedLanguage);
    },
    ...mapGetters({
      tenants: "auth/userTenants",
    })
  },

  created() {
    this.getTenant();
    this.getToken();

    this.accountId = this.$route.params.id



  },
  mounted() {
    this.selectedTenants = this.tenants.map((item) => item.id);

    if (this.searchOnce === "true") {
      this.listOrders();
    }
    window.addEventListener('resize', this.updateScreenWidth);
  },
  watch: {
    tenants: {
      deep: true,
      handler: function (value) {
        this.selectedTenants = this.tenants.map((item) => item.id);
      },
    },
    'account.events': {
      deep: true,
      handler: function (value) {
        if (this.account.events.length > 0) {
          this.events = this.account.events;
        }
      },
    },
  },
  methods: {
    customerFilter(arr) {
      if (arr) {
        var showedArray = []
        var showedStringArray = []
        var a = 0
        arr.forEach(element => {

          if (showedStringArray.includes(element.company.id)) {

          } else {
            showedStringArray.push(element.company.id)
            showedArray.push(element.company)
          }
        });
        showedArray.forEach(element => {
          element.customers = []
          this.customer.forEach(elem => {
            if (elem.company.id == element.id) {
              element.customers.push(elem)
            }
          })
        });
        return showedArray
      }
    },
    sendVerifyLink(event) {
      this.loading = true
      this.isSendVerify = true
      this.tempTenant = event.tenant
      this.selectedLanguage = event.language
      this.AuthWithTenantLanguage.resendVerifyLink({ email: this.account.email })
        .then(({ data }) => {

          this.$store.dispatch("success", "Verify Mail Successfuly Sent");
          this.isSendVerify = false

        })
        .catch((error) => {
          this.$store.dispatch("error", error.response.data.message);
        })
        .finally(() => {
          this.loading = false;

        });
    },

    sendDirectVerify(event) {
      this.loading = true
      this.isDirectVerify = true

      this.AuthWithTenant.verifyAdmin(this.accountId)
        .then(({ data }) => {

          this.$store.dispatch("success", "Account verified successfuly");
          this.isDirectVerify = false

          this.getToken();

        })
        .catch((error) => {
          this.$store.dispatch("error", error.response.data.message);
        })
        .finally(() => {
          this.loading = false;

        });
    },

    sendPassword(event) {
      this.loading = true
      this.tempTenant = event
      this.isSendPassword = true
      this.tempTenant = event.tenant
      this.selectedLanguage = event.language
      this.AuthWithTenantLanguage.resetPasswordLink({ email: this.account.email })
        .then(({ data }) => {

          this.$store.dispatch("success", "Reset Password Mail Successfuly Sent");
          this.isSendPassword = false

        })
        .catch((error) => {
          this.$store.dispatch("error", error.response.data.message);
        })
        .finally(() => {
          this.loading = false;

        });
    },
    titleize(value) {
      value = value.toLowerCase();
      return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
    },
    choosePassenger(item) {
      this.selectedPassenger = item;
    }
    ,
    getAccount(id) {
      // this.hasError = this.successMsg = false;
      this.loading = true;

      this.AuthModel.getAccount(id)
        .then(({ data }) => {
          this.account = data
          this.customer = data.companyCustomers
        })
        .catch((error) => {
          this.$store.dispatch("error", error.response.data.message || error.message || error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteAccount() {
      this.loading = true;

      this.AuthWithTenant.deleteAccount(this.accountId)
        .then(({ data }) => {
          if (this.account.isDeleted) {
            this.$store.dispatch("success", "Account Undeleted Successfuly");

          } else {
            this.$store.dispatch("success", "Account Deleted Successfuly");

          }
          this.dialogDeleteAccount = false

          this.getToken();
        })
        .catch((error) => {
          this.$store.dispatch("error", error);


        })
        .finally(() => {
          this.loading = false;


        });
    },
    async getTenant() {
      const res = await this.$store.dispatch("auth/getUser");
      this.allTenants = res.tenants

    },
    async getToken() {
      this.token = await store.dispatch("auth/acquireToken", router.history.current.meta.scopes);
      this.getAccount(this.$route.params.id)
      this.accountId = this.$route.params.id
    },



    getColorBalance(status) {
      switch (status) {
        case "completed":
          return "grey--text  font-weight-bold";
        case "refunded":
          return "mango-red  font-weight-bold";
        case "partially_refunded":
          return "mango-orange  font-weight-bold";
        default:
          return "grey--text  font-weight-bold";
      }
    },
    getColorStatus(status) {
      switch (status) {
        case "completed":
          return "mango-green-status";
        case "refunded":
          return "mango-red-status ";
        case "partially_refunded":
          return "mango-orange-status ";
        case "pending":
          return "mango-gray-status  font-weight-bold";
        default:
          return "mango-orange-status ";
      }
    },
    getMaxChipsForScreenWidth(width) {
      if (width >= 1830) return 3;
      if (width >= 1410) return 2;
      if (width >= 1260) return 1;
      return 3;
    },
    shouldShowChip(index) {
      const maxChips = this.getMaxChipsForScreenWidth(this.screenWidth);
      return index < maxChips;
    },
    shouldShowChipIndex() {
      return this.getMaxChipsForScreenWidth(this.screenWidth);
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped lang="scss">
.secondary.red {
  background-color: red !important;
}

.clock {
  font-size: 15px !important;
  margin-top: -1px !important;
  margin-left: 5px !important;
  margin-right: 2px !important;
}

.minw100 {
  min-width: 100px;
}

.v-chip {
  min-width: 130px;
  text-align: center;
  justify-content: center;
}

.tickettooltip {
  background-color: #fac557;
}

.notpaid {
  background-color: #f4846e !important;
  color: #753a2e !important;
}

.paid {
  background-color: #9bce85 !important;
  color: #447242 !important;
}

.one-line {
  white-space: nowrap;
}

.v-select__selections {
  min-height: 56px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

@media screen and (min-width: 1500px) {}

@media screen and (max-width: 1650px) {
  .refunded {
    max-width: 3vw
  }
}

.account-detail {
  height: 82%;
  background-color: white;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
  padding: 1.5rem
}

.account-detail .item:not(.item:first-child) {
  margin-top: 1rem;
  font-size: 20px;
}

.account-detail .item {
  font-size: 20px;
}

.lock img {
  width: 30px;
  height: 30px;
}

.lock {
  align-items: center;
}

.account-detail .v-toolbar__content {
  height: 100% !important;
  padding: 1rem;
}

.account-detail .v-toolbar {
  height: unset !important;
}
</style>
