var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "450"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-3",
          staticStyle: {
            "width": "120px"
          },
          attrs: {
            "dark": "",
            "elevation": "1",
            "small": "",
            "color": _vm.distribution.isLocked ? 'primary' : 'greeny'
          }
        }, 'v-btn', attrs, false), on), [_vm.distribution.isLocked ? _c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v("mdi-lock")]) : _c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v("mdi-lock-open")]), _c('span', [_vm._v(_vm._s(_vm.distribution.isLocked ? "Locked" : "Unlocked"))])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticClass: "px-5"
  }, [_c('v-card-title', {
    staticClass: "text-h5 px-0"
  }, [!_vm.distribution.isLocked ? _c('div', [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v("mdi-lock")]), _c('span', [_vm._v("Lock Distribution")])], 1) : _c('div', [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v("mdi-lock")]), _c('span', [_vm._v("Unlock Distribution")])], 1)]), _c('v-textarea', {
    attrs: {
      "outlined": "",
      "name": "input-7-4",
      "label": "Reason",
      "value": _vm.reason
    },
    model: {
      value: _vm.reason,
      callback: function ($$v) {
        _vm.reason = $$v;
      },
      expression: "reason"
    }
  }), _c('v-card-actions', {
    staticClass: "pa-0 pb-5"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "dark": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Disagree ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "dark": "",
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.continueClick();
      }
    }
  }, [_vm._v(" Agree ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }