var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "margin-top": "2rem !important"
    }
  }, [_c('v-form', {
    ref: "updateForm",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateAccount.apply(null, arguments);
      }
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "rules": [_vm.rules.required],
      "disabled": !_vm.isUpdate,
      "outlined": "",
      "clearable": "",
      "label": "First Name",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.form.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "firstName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.firstName"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "disabled": !_vm.isUpdate,
      "outlined": "",
      "clearable": "",
      "label": "Last Name",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.form.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lastName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.lastName"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "rules": [_vm.rules.required],
      "disabled": !_vm.isUpdate,
      "outlined": "",
      "clearable": "",
      "label": "Email",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('VuePhoneNumberInput', {
    staticStyle: {
      "height": "50px"
    },
    attrs: {
      "rules": [_vm.rules.required],
      "disabled": !_vm.isUpdate,
      "default-country-code": _vm.selectedCountryCode,
      "error": !_vm.phoneValid,
      "clearable": _vm.isUpdate
    },
    on: {
      "update": _vm.logPhone
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "12",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "height": "56px",
      "width": "100%"
    },
    attrs: {
      "type": "button",
      "disabled": !_vm.isUpdate,
      "color": "accent"
    },
    on: {
      "click": _vm.uuidv4
    }
  }, [_vm._v("Generate Password")])], 1), _c('v-col', {
    staticClass: "pb-0",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "12",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "disabled": !_vm.isUpdate,
      "append-icon": _vm.passEye ? 'mdi-eye' : 'mdi-eye-off',
      "outlined": "",
      "clearable": "",
      "label": "New password",
      "hide-details": "",
      "color": "orange",
      "type": _vm.passEye ? 'text' : 'password'
    },
    on: {
      "click:append": function ($event) {
        _vm.passEye = !_vm.passEye;
      }
    },
    model: {
      value: _vm.form.passwords.newPassword,
      callback: function ($$v) {
        _vm.$set(_vm.form.passwords, "newPassword", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.passwords.newPassword"
    }
  }), _c('password-control', {
    attrs: {
      "ifCondition": this.form.passwords.newPassword && this.form.passwords.newPassword != '',
      "password": this.form.passwords.newPassword,
      "passwordConfirm": _vm.confirmPassword
    },
    on: {
      "done": function ($event) {
        _vm.isDone = $event;
      }
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "12",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "disabled": !_vm.isUpdate,
      "append-icon": _vm.confirmEye ? 'mdi-eye' : 'mdi-eye-off',
      "outlined": "",
      "clearable": "",
      "label": "Confirm New Pasword",
      "hide-details": "",
      "color": "orange",
      "type": _vm.confirmEye ? 'text' : 'password'
    },
    on: {
      "click:append": function ($event) {
        _vm.confirmEye = !_vm.confirmEye;
      }
    },
    model: {
      value: _vm.confirmPassword,
      callback: function ($$v) {
        _vm.confirmPassword = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "confirmPassword"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "8",
      "md": "8",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "white elevation-1 rounded pa-5 pt-2 mt-2"
  }, [_c('v-row', [_c('v-col', [_c('h4', {
    staticClass: "mb-1 mt-3"
  }, [_vm._v("Message Internal")]), _c('vue-editor', {
    staticClass: "vue-editor",
    staticStyle: {
      "background-color": "#fff6d9"
    },
    attrs: {
      "disabled": !_vm.isUpdate,
      "editor-toolbar": _vm.customToolbar
    },
    model: {
      value: _vm.form.internalMessage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "internalMessage", $$v);
      },
      expression: "form.internalMessage"
    }
  })], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "pb-0 d-flex",
    staticStyle: {
      "justify-content": "space-between",
      "align-items": "center",
      "gap": "10px",
      "flex-direction": "column"
    },
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "40px"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-top": "50px"
    }
  }, [_c('v-select', {
    staticClass: "align-stretch font-weight-bold",
    attrs: {
      "disabled": !_vm.isUpdate,
      "outlined": "",
      "items": _vm.tenants,
      "item-text": "name",
      "chips": "",
      "item-value": "id",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select tenants",
      "multiple": "",
      "hide-details": "",
      "persistent-hint": false
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [_vm.shouldShowChip(index) ? _c('v-chip', [_c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e(), index === 3 ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.form.tenantIds.length - _vm.shouldShowChipIndex()) + ") ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.form.tenantIds,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tenantIds", $$v);
      },
      expression: "form.tenantIds"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center",
    staticStyle: {
      "align-items": "center",
      "gap": "10px"
    }
  }, [_c('v-checkbox', {
    staticClass: "font-weight-bold",
    staticStyle: {
      "margin": "0"
    },
    attrs: {
      "disabled": !_vm.isUpdate,
      "outlined": "",
      "clearable": "",
      "label": "Account Locked",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.form.isLocked,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isLocked", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.isLocked"
    }
  }), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "color": "black"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
      }
    }])
  }, [_c('span', [_vm._v("This account belongs to an authorized employee of a company. Thus, it is locked for changes and the account owner can not update its details. However, you can update account details from Mango nonetheless.")])])], 1)]), _c('v-btn', {
    staticStyle: {
      "height": "56px",
      "width": "100%"
    },
    attrs: {
      "type": "submit",
      "disabled": _vm.dynamicDisabled,
      "color": "primary"
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }]
  }, [_vm._v(" Update ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mng-loader"
  })])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }