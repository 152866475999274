var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content flex flex-row justify-space-between",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v("Saved Customers")])]), _c('div', {
    staticClass: "d-flex gap-4",
    staticStyle: {
      "gap": "10px"
    }
  }, [_vm.selecteds.length > 0 ? _c('v-btn', {
    staticStyle: {
      "height": "40px",
      "font-size": "13px"
    },
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.dialogDelete = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-delete")])], 1) : _vm._e()], 1)])])]), _c('v-data-table', {
    staticClass: "elevation-1 mt-2",
    attrs: {
      "hide-default-footer": true,
      "disable-pagination": true,
      "loading": _vm.isLoading,
      "show-select": "",
      "mobile-breakpoint": 1500,
      "headers": _vm.headersPassenger,
      "items": _vm.customer,
      "footer-props": {}
    },
    scopedSlots: _vm._u([{
      key: `item.firstName`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "orderID"
        }, [_c('div', {
          staticClass: "d-flex one-line"
        }, [_c('span', {
          class: {
            'grey--text text--darken-1': !item.fullName
          }
        }, [_vm._v(" " + _vm._s(item.fullName ? item.fullName : "N/A") + " ")])])])];
      }
    }, {
      key: `item.email`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('copyToClipboardField', {
          attrs: {
            "item": item.email
          }
        })];
      }
    }, {
      key: `item.phone`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "orderID"
        }, [_c('div', {
          staticClass: "d-flex one-line"
        }, [_c('span', {
          class: {
            'grey--text text--darken-1': !item.phone
          }
        }, [_vm._v(" " + _vm._s(item.phone ? item.phone : "N/A") + " ")])])])];
      }
    }, {
      key: `item.companyCustomerNumber`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "orderID"
        }, [_c('div', {
          staticClass: "d-flex one-line"
        }, [_c('span', {
          class: {
            'grey--text text--darken-1': !item.companyCustomerNumber
          }
        }, [_vm._v(" " + _vm._s(item.companyCustomerNumber ? item.companyCustomerNumber : "N/A") + " ")])])])];
      }
    }], null, true),
    model: {
      value: _vm.selecteds,
      callback: function ($$v) {
        _vm.selecteds = $$v;
      },
      expression: "selecteds"
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "max-width": "580px"
    },
    model: {
      value: _vm.dialogDelete,
      callback: function ($$v) {
        _vm.dialogDelete = $$v;
      },
      expression: "dialogDelete"
    }
  }, [_c('v-card', {
    staticClass: "delete-dialog"
  }, [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v("Are you sure you want to remove this customers?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.dialogDelete = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.deleteCustomer
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoading,
      expression: "!isLoading"
    }]
  }, [_vm._v(" Remove ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticClass: "mng-loader"
  })]), _c('v-spacer')], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }