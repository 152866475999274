import Trainplanet from "@/util/trainplanet.api";
import moment from "moment";

export default {
  data: () => ({
    ordersTotalCount: 0,
    orders: [],
    loading: false,
    filter: {
      query: null,
      dates: null,
      printable: false,
      paid: true,
    },
    headers: [
      {
        text: "Order ID",
        value: "id",
        sortable: false,
        width: "140px",
      },
      // {
      //   text: "Order Currency",
      //   value: "currency",
      //   sortable: true,
      //   align: "right"
      // },
      // {
      //   text: "Country",
      //   value: "country",
      //   sortable: false
      // },
      {
        text: "Delivery method",
        value: "distribution.title",
        sortable: false,
        width: "120px",
      },

      // {
      //   text: "Company",
      //   value: "companyName",
      //   sortable: false
      // },

      //{
      //  text: "Delivery Time Slot",
      //  value: "minDeliveryDays",
      //  sortable: false
      //},
      {
        text: "Purchase Date",
        value: "created",
        sortable: false,
        width: "150px",
      },
      {
        text: "Customer",
        value: "customer",
        sortable: false,
        width: "300px",
        class: "pl-0",
      },

      {
        text: "Earliest Departure Date",
        value: "earliestDepartureDate",
        sortable: false,
        width: "180px",
      },

      {
        text: "Internal Message",
        value: "distribution.comment",
        sortable: false,
        width: "160px",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "left",
        width: "150px",
      },
    ],
  }),
  filters: {
    humanDatetime(datetime) {
      return moment.utc(datetime).local().fromNow();
    },
  },

  async mounted() {
    // await this.listOrders();  // this is being done with filter watchers
  },
  methods: {
    pageLoader(value) {
      this.$store.dispatch("loading", value);
    },
    onError(message) {
      this.$store.dispatch("error", message);
    },
    onSuccess(message) {
      this.$store.dispatch("success", message);
    },
    copyToClipboard(data) {
      navigator.clipboard.writeText(data);
      this.onSuccess(`Copied: ${data}`);
    },
    async listOrders() {
      try {
        this.loading = true;

        const response = await Trainplanet.listOrders({
          query: this.filter.query,
          perPage: this.filter.itemsPerPage,
          page: this.filter.page,
          printable: !this.printedTickets,
          printed: this.printedTickets,
          includePrintableInformation: true,
        });

        this.orders = this.orderDistribution(response.data, this.printedTickets);
        this.ordersTotalCount = parseInt(response.meta.count, 10);
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message || error);
      } finally {
        this.loading = false;
      }
    },
    orderDistribution(orders, printedTickets) {
      return orders.flatMap((i) => {
        if (i.printableDistributions.length) {
          const distributions = i.printableDistributions.filter(
            (i) =>
              i.type === "distribution" && i.category === "physical" && (printedTickets ? i.trackingId : !i.trackingId)
          );

          if (!distributions.length) return [];

          return distributions.map((distribution) => ({
            ...i,
            tenantName: i.seller.tenantName,
            distribution,
          }));
        }
        return [];
      });
    },
  },
};
