var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "order-print"
    }
  }, [_c('toolbarTitle', {
    attrs: {
      "title": "Printable Tickets",
      "color": "accent"
    }
  }), _c('v-data-table', {
    staticClass: "elevation-1 row-height-50",
    attrs: {
      "fixed-header": "",
      "mobile-breakpoint": "0",
      "headers": _vm.headers,
      "items": _vm.orders,
      "server-items-length": _vm.ordersTotalCount,
      "loading": _vm.loading,
      "items-per-page": _vm.filter.itemsPerPage,
      "options": _vm.filter,
      "footer-props": {
        'items-per-page-options': [20, 50, 100]
      }
    },
    on: {
      "update:options": function ($event) {
        _vm.filter = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var _item$delivery;
        var item = _ref.item;
        return [_c('tr', {
          class: item.distribution.isLocked && 'grey lighten-4'
        }, [_c('td', {
          class: item.distribution.isLocked && 'disableCss'
        }, [_c('div', {
          staticClass: "d-flex flex-row",
          staticStyle: {
            "width": "180px"
          }
        }, [_c('OrderIDField', {
          attrs: {
            "order": item
          }
        }), _c('OrderIconsField', {
          attrs: {
            "order": item,
            "showPrintableInfo": false,
            "showDistributedInfo": true,
            "showGroupOrderInfo": true,
            "showClaimInfo": true,
            "showArchiveOrder": true
          }
        })], 1)]), _c('td', {
          class: item.distribution.isLocked && 'disableCss'
        }, [_c('OrderPrintDeliverField', {
          attrs: {
            "order": item
          }
        })], 1), _c('td', {
          class: item.distribution.isLocked && 'disableCss'
        }, [_c('div', [_c('b', [_vm._v(_vm._s(_vm._f("localizeUTCAndGetDate")(item.purchaseDate)))]), _vm._v(" " + _vm._s(_vm._f("localizeUTCAndGetTime")(item.purchaseDate)) + " ")])]), _c('td', {
          staticClass: "pl-0",
          class: item.distribution.isLocked && 'disableCss'
        }, [_c('orderCustomerField', {
          attrs: {
            "order": item,
            "address": true
          }
        })], 1), _c('td', {
          class: item.distribution.isLocked && 'disableCss',
          staticStyle: {
            "min-width": "160px"
          }
        }, [_c('earliestDepartureDateField', {
          attrs: {
            "order": item
          }
        })], 1), _c('td', {
          staticStyle: {
            "min-width": "160px"
          }
        }, [_c('div', {
          staticClass: "d-flex align-center"
        }, [item.internalMessage ? _c('v-tooltip', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "color": "black",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-1",
                attrs: {
                  "color": "mango-red",
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-message-reply-text ")]), _c('span', _vm._g(_vm._b({
                staticClass: "threeDots",
                class: item.distribution.isLocked && 'disableCss',
                staticStyle: {
                  "max-width": "170px"
                }
              }, 'span', attrs, false), on), [_vm._v(" " + _vm._s(_vm._f("cleanHtml")(item.internalMessage)))])];
            }
          }], null, true)
        }, [item.internalMessage ? _c('div', {
          staticStyle: {
            "max-width": "400px"
          }
        }, [_vm._v(" " + _vm._s(_vm._f("cleanHtml")(item.internalMessage)) + " ")]) : _vm._e()]) : _vm._e()], 1)]), _c('td', {
          staticStyle: {
            "min-width": "190px",
            "text-align": "right !important"
          }
        }, [_c('v-row', [_c('v-col', {
          staticClass: "d-flex justify-start align-center w-100",
          staticStyle: {
            "width": "100%",
            "display": "flex"
          },
          attrs: {
            "md": "auto"
          }
        }, [_c('v-tooltip', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "color": "black",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-switch', {
                staticClass: "lock",
                attrs: {
                  "dense": "",
                  "color": "mango-red",
                  "small": ""
                },
                on: {
                  "click": function ($event) {
                    return _vm.lockDistribution(item.id, item.distribution);
                  }
                },
                model: {
                  value: item.distribution.isLocked,
                  callback: function ($$v) {
                    _vm.$set(item.distribution, "isLocked", $$v);
                  },
                  expression: "item.distribution.isLocked"
                }
              })], 1)];
            }
          }], null, true)
        }, [item.distribution.isLocked ? _c('div', [_vm._v("Order is locked for printing.")]) : _c('div', [_vm._v("Switch to lock order for not be printable.")])]), _c('v-tooltip', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "color": "black",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on,
                attrs = _ref4.attrs;
              return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', {
                attrs: {
                  "color": "mango-red",
                  "disabled": item.distribution.isLocked,
                  "icon": ""
                },
                on: {
                  "click": function ($event) {
                    return _vm.cancelPrinting({
                      orderId: item.id,
                      itemId: item.distribution.id
                    });
                  }
                }
              }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)];
            }
          }], null, true)
        }, [_c('div', [_vm._v("Delete order from printing section.")])]), !item.distribution.trackingId ? _c('v-tooltip', {
          attrs: {
            "disabled": !!((_item$delivery = item.delivery) !== null && _item$delivery !== void 0 && _item$delivery.fullAddress),
            "color": "black",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref5) {
              var _item$delivery2;
              var on = _ref5.on,
                attrs = _ref5.attrs;
              return [_c('div', _vm._g({}, on), [_c('v-btn', _vm._b({
                attrs: {
                  "loading": _vm.printing.distribution.id === item.distribution.id && _vm.printDialogOpen,
                  "disabled": !((_item$delivery2 = item.delivery) !== null && _item$delivery2 !== void 0 && _item$delivery2.fullAddress),
                  "color": "accent",
                  "icon": ""
                },
                on: {
                  "click": function ($event) {
                    $event.preventDefault();
                    _vm.printDialogOpen = true;
                    _vm.printOrder(item, item.distribution);
                  }
                }
              }, 'v-btn', attrs, false), [_c('v-icon', [_vm._v("mdi-printer")])], 1)], 1)];
            }
          }], null, true)
        }, [_vm._v(" Delivery address is required to print the order. ")]) : _c('v-btn', {
          attrs: {
            "disabled": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-printer-check")]), _vm._v(" Order printed ")], 1)], 1)], 1)], 1)])];
      }
    }])
  }), _vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "color": "#f4846e"
    }
  }) : _vm._e(), _c('v-dialog', {
    attrs: {
      "value": _vm.printDialogOpen,
      "max-width": "500px",
      "scrollable": "",
      "transition": "dialog-bottom-transition",
      "persistent": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticStyle: {
      "width": "100%",
      "justify-content": "space-between"
    },
    attrs: {
      "justify": "between"
    }
  }, [_c('span', {
    staticClass: "text-h5"
  }, [_vm._v("Print order: "), _c('b', [_vm._v(_vm._s(_vm.printing.order.id))])])]), _c('v-card-text', {
    staticStyle: {
      "min-height": "55vh",
      "max-height": "80vh"
    }
  }, [_vm.bookingsToPrint.some(function (item) {
    return item.mustBePrinted;
  }) || _vm.bookingsToPrint.every(function (item) {
    return !item.mustBePrinted;
  }) ? _c('v-list', {
    attrs: {
      "subheader": "",
      "two-line": ""
    }
  }, [_c('v-subheader', [_c('span', {
    staticClass: "mr-1"
  }, [_vm._v("1. Print the following external bookings:")])]), _c('v-list-item-group', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.printing.bookings,
      callback: function ($$v) {
        _vm.$set(_vm.printing, "bookings", $$v);
      },
      expression: "printing.bookings"
    }
  }, _vm._l(_vm.bookingsToPrint, function (booking, i) {
    return _c('div', {
      key: i,
      attrs: {
        "value": booking
      }
    }, [booking.mustBePrinted ? _c('v-list-item', {
      on: {
        "click": function ($event) {
          return _vm.copy(booking);
        }
      }
    }, [[_c('v-list-item-action', [_c('v-icon', [_vm._v("mdi-content-copy")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(booking.externalOrderId))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(booking.externalSystem))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Currency")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(booking.externalCurrency) + " ")])], 1), booking.externalItemIds.length > 0 ? _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Row Count")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(booking.externalItemIds.length) + " ")])], 1) : _vm._e()]], 2) : _vm._e()], 1);
  }), 0)], 1) : _vm._e(), _c('v-divider'), _c('v-subheader', [_vm._v("2. Scan/register all printed tickets:")]), _c('div', {
    ref: "tickets"
  }, [_c('v-container', _vm._l(_vm.printing.distribution.tickets, function (ticket, i) {
    return _c('v-text-field', {
      key: i,
      ref: "ticket",
      refInFor: true,
      attrs: {
        "type": "number",
        "label": "Ticket ID",
        "rules": [_vm.validateTicketNumber.required, _vm.validateTicketNumber.min, _vm.validateTicketNumber.isConsecutive],
        "outlined": "",
        "dense": ""
      },
      nativeOn: {
        "input": function ($event) {
          return _vm.addEmptyTicket($event);
        }
      },
      scopedSlots: _vm._u([{
        key: "append-outer",
        fn: function () {
          return [_c('v-btn', {
            attrs: {
              "color": "primary",
              "icon": ""
            },
            on: {
              "click": function ($event) {
                $event.preventDefault();
                return _vm.removeTicket(i);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.printing.distribution.tickets[i],
        callback: function ($$v) {
          _vm.$set(_vm.printing.distribution.tickets, i, $$v);
        },
        expression: "printing.distribution.tickets[i]"
      }
    });
  }), 1)], 1)], 1), _vm.nonEmptyTicketsSize !== 0 ? _c('span', {
    staticStyle: {
      "margin-left": "15px",
      "margin-bottom": "-10px"
    }
  }, [_c('span', [_vm._v("Scanned Ticket Count: ")]), _c('b', [_vm._v(" " + _vm._s(_vm.nonEmptyTicketsSize))])]) : _vm._e(), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.resetPrintingState();
      }
    }
  }, [_vm._v("Abort")]), _c('div', {
    staticClass: "ml-2"
  }, [_c('v-tooltip', {
    attrs: {
      "disabled": !_vm.saveButtonDisabled,
      "color": "black",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var on = _ref6.on,
          attrs = _ref6.attrs;
        return [_c('div', _vm._g({}, on), [_c('v-btn', _vm._b({
          attrs: {
            "loading": _vm.printLoading,
            "disabled": _vm.saveButtonDisabled,
            "color": "accent"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.confirmPrint();
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" Save & continue ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.saveButtonTooltip))])])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "my-5"
  }, [_c('PrintedTickets', {
    attrs: {
      "refreshPrintedOrders": _vm.isPrintingCompleted,
      "refreshPrintableOrdersCallback": this.listOrders
    }
  })], 1), _c('v-snackbar', {
    attrs: {
      "timeout": 3000,
      "color": "success",
      "app": "",
      "bottom": "",
      "right": ""
    },
    model: {
      value: _vm.copied,
      callback: function ($$v) {
        _vm.copied = $$v;
      },
      expression: "copied"
    }
  }, [_vm._v("Booking number copied to clipboard! ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }