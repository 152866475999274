<template>
  <div v-if="order.earliestDepartureDate !== null">
    <v-tooltip color="black" bottom style="cursor: pointer">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="font-weight-medium one-line"
          v-bind="attrs"
          v-on="on"
        >
          <b>
            {{ order.earliestDepartureDate | localizeUTCAndGetDate }}</b
          >
          <span
            v-if="
              getDayCount(order.earliestDepartureDate) < 6
            "
            :class="
              getDayCount(order.earliestDepartureDate) < 6
                ? 'mango-red'
                : ''
            "
          >
            {{
              getDayCount(order.earliestDepartureDate) + " days"
            }}</span
          >
        </div>
      </template>
      <div>The customer's first travel day.</div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "earliestDepartureDateField",
  props: ['order'],
  methods: {
    getDayCount(date) {
      let dateCalc = new Date(date);
      let toDay = new Date();
      let difference = dateCalc.getTime() - toDay.getTime();

      return Math.ceil(difference / (1000 * 3600 * 24));
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
