var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "autofocus": "",
      "hide-details": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": "Search for groups...",
      "persistent-hint": "",
      "clearable": ""
    },
    model: {
      value: _vm.options.search,
      callback: function ($$v) {
        _vm.$set(_vm.options, "search", $$v);
      },
      expression: "options.search"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-select', {
    staticClass: "align-stretch font-weight-bold",
    attrs: {
      "solo": "",
      "items": _vm.tenants,
      "chips": "",
      "hide-details": "",
      "item-text": "name",
      "item-value": "id",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select tenants",
      "multiple": "",
      "persistent-hint": false
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [index < 3 ? _c('v-chip', [_c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e(), index === 3 ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.selectedTenants.length - 3) + ") ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selectedTenants,
      callback: function ($$v) {
        _vm.selectedTenants = $$v;
      },
      expression: "selectedTenants"
    }
  }), _vm.userCanManageGroups ? _c('v-btn', {
    staticClass: "ml-5",
    staticStyle: {
      "height": "48px",
      "width": "170px"
    },
    attrs: {
      "dark": "",
      "small": "",
      "color": "accent"
    },
    on: {
      "click": _vm.openCreateDialog
    }
  }, [_c('v-icon', {
    staticClass: "mr-3 mb-1"
  }, [_vm._v("mdi-account-group")]), _vm._v(" ADD GROUP ")], 1) : _vm._e()], 1)])], 1), _c('create-group', {
    attrs: {
      "isDialogOpen": _vm.isDialogOpened,
      "edited-group": _vm.editItem
    },
    on: {
      "created": _vm.getGroups,
      "onClose": function ($event) {
        _vm.isDialogOpened = false;
        _vm.editItem = null;
      }
    }
  }), _c('v-data-table', {
    staticClass: "mt-6",
    attrs: {
      "headers": _vm.headers,
      "server-items-length": _vm.totalGroups,
      "items": _vm.groups,
      "loading": _vm.loading,
      "items-per-page": _vm.options.itemsPerPage,
      "options": _vm.options,
      "footer-props": {
        'items-per-page-options': [50, 100]
      }
    },
    on: {
      "update:options": function ($event) {
        _vm.options = $event;
      },
      "update:items-per-page": _vm.getGroups,
      "update:page": _vm.getGroups
    },
    scopedSlots: _vm._u([{
      key: `item.users`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._l(item.users.slice(0, 3), function (user, index) {
          var _user$fullName;
          return _c('v-chip', {
            key: index,
            staticClass: "font-weight-bold cliporder mr-1",
            staticStyle: {
              "min-width": "10px",
              "font-size": "12px",
              "min-height": "27px",
              "text-transform": "capitalize"
            },
            attrs: {
              "link": "",
              "x-small": ""
            }
          }, [_vm._v(" " + _vm._s((_user$fullName = user.fullName) !== null && _user$fullName !== void 0 ? _user$fullName : user.name) + " ")]);
        }), item.users.length > 3 ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(item.users.length - 3) + ") ")]) : _vm._e()];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm.userCanManageGroups ? _c('div', [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.view(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "accent"
          }
        }, [_vm._v("mdi-eye")])], 1), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openEditModal(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "third"
          }
        }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()];
      }
    }], null, true)
  }), _vm.editItem ? _c('detail-group', {
    attrs: {
      "item": _vm.editItem,
      "isDialogOpen": _vm.isDetailDialogOpen
    },
    on: {
      "onClose": function ($event) {
        _vm.isDetailDialogOpen = false;
        _vm.editItem = null;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }