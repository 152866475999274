var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.order.earliestDepartureDate !== null ? _c('div', [_c('v-tooltip', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "color": "black",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "font-weight-medium one-line"
        }, 'div', attrs, false), on), [_c('b', [_vm._v(" " + _vm._s(_vm._f("localizeUTCAndGetDate")(_vm.order.earliestDepartureDate)))]), _vm.getDayCount(_vm.order.earliestDepartureDate) < 6 ? _c('span', {
          class: _vm.getDayCount(_vm.order.earliestDepartureDate) < 6 ? 'mango-red' : ''
        }, [_vm._v(" " + _vm._s(_vm.getDayCount(_vm.order.earliestDepartureDate) + " days"))]) : _vm._e()])];
      }
    }], null, false, 3709142917)
  }, [_c('div', [_vm._v("The customer's first travel day.")])])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }