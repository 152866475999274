var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('toolbarTitle', {
    staticClass: "mb-4",
    attrs: {
      "title": "Accounts"
    }
  }), _c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('v-select', {
    staticClass: "align-stretch font-weight-bold",
    attrs: {
      "items": _vm.tenants,
      "menu-props": {
        maxHeight: '400'
      },
      "persistent-hint": false,
      "label": "Select tenants",
      "item-text": "name",
      "item-value": "id",
      "outlined": "",
      "clearable": "",
      "chips": "",
      "multiple": "",
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [index < _vm.visibleChipLength ? _c('v-chip', [_c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e(), index === _vm.visibleChipLength ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.selectedTenants.length - _vm.visibleChipLength) + ") ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selectedTenants,
      callback: function ($$v) {
        _vm.selectedTenants = $$v;
      },
      expression: "selectedTenants"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('v-autocomplete', {
    staticClass: "font-weight-bold",
    attrs: {
      "items": _vm.companies,
      "loading": _vm.companyLoading,
      "label": "Select companies",
      "item-text": "name",
      "item-value": "id",
      "multiple": "",
      "chips": "",
      "clearable": "",
      "hide-details": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_c('v-list-item', [_c('v-list-item-title', [_vm._v("No data available")])], 1)];
      },
      proxy: true
    }, {
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item,
          index = _ref2.index;
        return [index < _vm.visibleChipLength ? _c('v-chip', [_c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e(), index === _vm.visibleChipLength ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.selectedCompanies.length - _vm.visibleChipLength) + " more) ")]) : _vm._e()];
      }
    }, {
      key: "item",
      fn: function (_ref3) {
        var _item$fullName;
        var item = _ref3.item;
        return [_c('v-list-item-avatar', {
          staticClass: "text-h5 font-weight-light white--text",
          attrs: {
            "color": "primary"
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "#fff"
          }
        }, [_vm._v("mdi-domain")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s((_item$fullName = item.fullName) !== null && _item$fullName !== void 0 ? _item$fullName : item.name) + " ")])], 1)];
      }
    }]),
    model: {
      value: _vm.selectedCompanies,
      callback: function ($$v) {
        _vm.selectedCompanies = $$v;
      },
      expression: "selectedCompanies"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "clearable": "",
      "autofocus": _vm.filter.query == null,
      "prepend-inner-icon": "mdi-magnify",
      "label": "Search account by query",
      "hint": "Search by Query",
      "hide-details": "",
      "color": "orange"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.listAccounts();
      }
    },
    model: {
      value: _vm.filter.query,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "query", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "filter.query"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "6",
      "sm": "6",
      "xs": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "items": _vm.isDeletedArray,
      "menu-props": "auto",
      "placeholder": "Account Status",
      "hide-details": "",
      "item-text": "label",
      "item-value": "value",
      "single-line": ""
    },
    model: {
      value: _vm.filter.deleted,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "deleted", $$v);
      },
      expression: "filter.deleted"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "12",
      "sm": "12"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "height": "100%",
      "min-height": "56px"
    },
    attrs: {
      "block": "",
      "color": "accent",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.listAccounts();
      }
    }
  }, [_vm._v(" SEARCH ")])], 1)], 1), _c('v-row', [_c('v-col')], 1), !_vm.searchedAtLeastOnce ? _c('h4', {
    staticStyle: {
      "display": "table",
      "margin": "0 auto"
    }
  }, [_vm._v("Start a search to see the orders.")]) : _vm._e(), _vm.searchedAtLeastOnce ? _c('v-data-table', {
    staticClass: "elevation-1 mt-2",
    attrs: {
      "mobile-breakpoint": 1500,
      "headers": _vm.headers,
      "items": _vm.orders,
      "server-items-length": _vm.ordersTotalCount,
      "loading": _vm.loading,
      "items-per-page": _vm.filter.itemsPerPage,
      "options": _vm.filter,
      "footer-props": {
        'items-per-page-options': [10, 20, 30, 45, 100, 500]
      }
    },
    on: {
      "update:options": function ($event) {
        _vm.filter = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: `item.isVerified`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "email"
        }, [_c('div', [_c('h4', {
          staticClass: "grey--text text--darken-4",
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "justify-content": "start"
          }
        }, [_c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: item.isVerified,
            expression: "item.isVerified"
          }],
          staticClass: "ml-1 font-weight-bold"
        }, [_c('img', {
          staticStyle: {
            "width": "20px"
          },
          attrs: {
            "src": require("../../../assets/accept.png"),
            "alt": ""
          }
        })]), _c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !item.isVerified,
            expression: "!item.isVerified"
          }],
          staticClass: "ml-1 font-weight-bold"
        }, [_c('img', {
          staticStyle: {
            "width": "20px"
          },
          attrs: {
            "src": require("../../../assets/cancel.png"),
            "alt": ""
          }
        })])])])])];
      }
    }, {
      key: `item.isDeleted`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "email"
        }, [_c('div', [_c('h4', {
          staticClass: "grey--text text--darken-4",
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "justify-content": "start"
          }
        }, [!item.isDeleted ? _c('span', {
          staticClass: "ml-1 font-weight-bold",
          staticStyle: {
            "display": "flex",
            "gap": "10px"
          }
        }, [_c('img', {
          staticStyle: {
            "width": "20px"
          },
          attrs: {
            "src": require("../../../assets/accept.png"),
            "alt": ""
          }
        }), _vm._v(" Active ")]) : _vm._e(), item.isDeleted ? _c('span', {
          staticClass: "ml-1 font-weight-bold",
          staticStyle: {
            "display": "flex",
            "gap": "10px"
          }
        }, [_c('img', {
          staticStyle: {
            "width": "20px"
          },
          attrs: {
            "src": require("../../../assets/cancel.png"),
            "alt": ""
          }
        }), _vm._v(" Deleted ")]) : _vm._e()])])])];
      }
    }, {
      key: `item.name`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.fullName) + " ")];
      }
    }, {
      key: `item.email`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('copyToClipboardField', {
          attrs: {
            "item": item.email
          }
        })];
      }
    }, {
      key: `item.companies`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return _vm._l(_vm.customerFilter(item.companyCustomers), function (elem, index) {
          return _c('v-chip', {
            key: index,
            staticClass: "font-weight-bold cliporder mr-1",
            staticStyle: {
              "min-width": "10px",
              "font-size": "12px",
              "min-height": "27px",
              "text-transform": "capitalize"
            },
            attrs: {
              "link": "",
              "x-small": ""
            },
            on: {
              "click": function ($event) {
                return _vm.companyDetail(elem.company);
              }
            }
          }, [_vm._v(" " + _vm._s(elem.company.name) + " ")]);
        });
      }
    }, {
      key: `item.id`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('div', [_c('v-btn', {
          attrs: {
            "icon": "",
            "href": `/user/${item.id}`
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "accent"
          }
        }, [_vm._v("mdi-eye")])], 1)], 1)];
      }
    }, {
      key: `item.tenants`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column",
            "gap": "0.5rem",
            "margin-top": "0.5rem",
            "margin-bottom": "0.5rem"
          }
        }, [_c('div', {
          staticStyle: {
            "display": "flex",
            "gap": "5px"
          }
        }, _vm._l(_vm.takeTenants(item.tenants), function (elem, i) {
          return _c('div', [_c('div', {
            staticClass: "soldBY"
          }, [_c('v-tooltip', {
            attrs: {
              "bottom": "",
              "color": "black"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref11) {
                var on = _ref11.on;
                return [_c('v-avatar', _vm._g({
                  staticClass: "mr-1",
                  attrs: {
                    "width": "20",
                    "height": "20",
                    "tile": "",
                    "min-width": "30"
                  }
                }, on), [_c('img', {
                  attrs: {
                    "src": 'https://assets.trainplanet.com/wlabel/' + _vm.fixUrl(elem.name) + '/logos/favicon-32x32.png'
                  }
                })])];
              }
            }], null, true)
          }, [_c('span', {
            staticStyle: {
              "display": "block",
              "text-align": "center"
            }
          }, [_vm._v(_vm._s(elem.name))])])], 1)]);
        }), 0)])];
      }
    }, {
      key: `item.savedPassengers`,
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "email"
        }, [_c('div', [_c('h4', {
          staticClass: "grey--text text--darken-4",
          staticStyle: {
            "display": "flex",
            "flex-wrap": "nowrap",
            "justify-content": "start"
          }
        }, [_c('span', {
          staticClass: "ml-1 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.savedPassengers.length) + " ")])])])])];
      }
    }], null, true)
  }) : _vm._e(), _vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "color": "#f4846e"
    }
  }) : _vm._e(), _c('v-dialog', {
    staticClass: "pa-5",
    attrs: {
      "loading": _vm.loading,
      "width": "1000px"
    },
    on: {
      "click:outside": function ($event) {
        _vm.companyDetailDialog = false;
      }
    },
    model: {
      value: _vm.companyDetailDialog,
      callback: function ($$v) {
        _vm.companyDetailDialog = $$v;
      },
      expression: "companyDetailDialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "ml-2"
  }, [_vm._v("Company: " + _vm._s(_vm.companyData && _vm.companyData.name))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.companyDetailDialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _vm.companyData ? _c('companyDetail', {
    attrs: {
      "company": _vm.companyData,
      "loading": _vm.loading
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }