var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('OrderList', {
    attrs: {
      "isAccount": true,
      "accountId": _vm.accountId,
      "header": _vm.header
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }