var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-container', {
    staticClass: "mt-0 py-10",
    staticStyle: {
      "margin-top": "60px"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v("Select Companies")]), _c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "clearable": "",
      "label": "Filter By Name",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.filterCompany,
      callback: function ($$v) {
        _vm.filterCompany = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "filterCompany"
    }
  })], 1), _c('v-list', {
    staticStyle: {
      "max-height": "400px",
      "overflow": "auto"
    },
    attrs: {
      "lines": "three"
    }
  }, _vm._l(_vm.tempCompanies, function (item, idx) {
    return _c('v-list-item', {
      key: idx,
      staticClass: "mt-4",
      class: {
        'selected-item-company': _vm.selectedCompanyId == item.id
      },
      staticStyle: {
        "border-radius": "6px",
        "box-shadow": "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),"
      },
      attrs: {
        "disabled": _vm.loadingInner,
        "subtitle": "Lorem ipsum dolor sit amet consectetur adipisicing elit"
      },
      on: {
        "click": function ($event) {
          _vm.selectedCompanyId = item.id;
        }
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "justify-content": "space-between",
        "width": "100%"
      }
    }, [_c('div', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticStyle: {
        "display": "flex",
        "align-items": "center"
      }
    }, [_c('strong', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.loadingInner || !_vm.loadingInner && !_vm.selectedCompanyId == item.id,
        expression: "!loadingInner || (!loadingInner && !selectedCompanyId == item.id)"
      }],
      staticClass: "span-select"
    }, [_vm._v(" Select ")]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.loadingInner && _vm.selectedCompanyId == item.id,
        expression: "loadingInner && selectedCompanyId == item.id"
      }],
      staticClass: "mng-loader"
    })])])])]);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v("Select Customers")])]), _c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "clearable": "",
      "label": "Filter By Customer Name",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.filterCustomers,
      callback: function ($$v) {
        _vm.filterCustomers = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "filterCustomers"
    }
  }), _c('v-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loadingInner,
      expression: "!loadingInner"
    }],
    staticStyle: {
      "max-height": "400px",
      "overflow": "auto"
    },
    attrs: {
      "lines": "three"
    }
  }, _vm._l(_vm.tempCustomer, function (item, idx) {
    return _c('v-list-item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.calculateShow(item.id),
        expression: "calculateShow(item.id)"
      }],
      key: idx,
      staticClass: "mt-4",
      staticStyle: {
        "border-radius": "6px",
        "box-shadow": "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),"
      },
      attrs: {
        "subtitle": "Lorem ipsum dolor sit amet consectetur adipisicing elit"
      },
      on: {
        "click": function ($event) {
          return _vm.addCustomer(item);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "justify-content": "space-between",
        "width": "100%"
      }
    }, [_c('div', [_vm._v(" " + _vm._s(item.fullName ? item.fullName : "") + " " + _vm._s(item.companyCustomerNumber ? `#${item.companyCustomerNumber}` : "") + " ")]), _c('div', [_c('strong', {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c('v-icon', {
      staticClass: "mr-3"
    }, [_vm._v("mdi-plus")])], 1)])])]);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v("Selected Customers")])]), _c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "clearable": "",
      "label": "Filter By Customer Name",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.filterSelectedCustomers,
      callback: function ($$v) {
        _vm.filterSelectedCustomers = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "filterSelectedCustomers"
    }
  }), _c('v-list', {
    staticStyle: {
      "max-height": "500px",
      "overflow": "auto"
    },
    attrs: {
      "lines": "three"
    }
  }, _vm._l(_vm.tempSelectedCustomer, function (item, idx) {
    return _c('v-list-item', {
      key: idx,
      staticClass: "mt-4",
      staticStyle: {
        "border-radius": "6px",
        "box-shadow": "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),"
      },
      attrs: {
        "subtitle": "Lorem ipsum dolor sit amet consectetur adipisicing elit"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteCustomer(item);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "justify-content": "space-between",
        "width": "100%"
      }
    }, [_c('div', [_vm._v(" " + _vm._s(item.fullName ? item.fullName : "") + " " + _vm._s(item.companyCustomerNumber ? `#${item.companyCustomerNumber}` : "") + " ")]), _c('div', [_c('strong', {
      staticStyle: {
        "font-size": "18px"
      }
    }, [_c('v-icon', {
      staticClass: "mr-3"
    }, [_vm._v("mdi-minus")])], 1)])])]);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "10"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "2"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "height": "40px",
      "font-size": "13px"
    },
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.addCustomers
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loadingInner,
      expression: "!loadingInner"
    }],
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v("mdi-plus")]), _vm._v(" Add Customers ")], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loadingInner,
      expression: "loadingInner"
    }],
    staticClass: "mng-loader"
  })])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }