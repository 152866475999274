<template>
  <div>
    <v-tooltip color="black" bottom style="cursor: pointer" :disabled="!order.isBusinessOrder">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="ml-5" style="position: relative">
          <div
            class="font-weight-bold"
            style="position: absolute; margin-left: -20px; margin-top: -1px"
            v-if="order.isBusinessOrder">
            <v-icon color="black" small> mdi-domain</v-icon>
          </div>
          <!-- <div
            class="font-weight-bold"
            v-if="order.customer && order.customer.category === 'company'"
          >
            {{ order.customer.company.name }}

            <span
              class="font-weight-regular"
              v-if="
                order.customer.delivery.firstName &&
                order.customer.delivery.lastName
              "
              >(
              {{
                order.customer.delivery.firstName +
                " " +
                order.customer.delivery.lastName
              }}
              )
            </span>
            <span v-else class="font-weight-regular"
              >({{ order.customer.firstName }}
              {{ order.customer.lastName }})</span
            >
          </div> -->

          <div clasS="font-weight-bold">
            <span v-if="order.delivery.fullName">
              {{ order.delivery.fullName }}
            </span>
            <span v-else>{{ order.customer.fullName }}</span>
          </div>

          <div v-if="order.delivery.fullAddress && address" class="grey--text text--darken-1">
            {{ order.delivery.fullAddress }}
          </div>
        </div>
      </template>
      <div>Company Order</div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "orderCustomerField",
  props: {
    order: [],
    address: Boolean,
  },
  data() {
    return {
      customerData: null,
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
