var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "value": _vm.isDialogOpen,
      "transition": "dialog-top-transition",
      "max-width": "50%",
      "scrollable": false
    },
    on: {
      "click:outside": _vm.closeDialog
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v(" " + _vm._s(_vm.item.name) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "px-4 py-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "py-2 px-4 mb-4"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Users (" + _vm._s(_vm.item.users.length) + ")")]), _vm._l(_vm.item.users, function (user, index) {
    var _user$fullName;
    return _c('v-chip', {
      key: index,
      staticClass: "font-weight-semibold mr-2 mb-2",
      attrs: {
        "link": "",
        "medium": "",
        "color": "primary"
      }
    }, [_vm._v(" " + _vm._s((_user$fullName = user.fullName) !== null && _user$fullName !== void 0 ? _user$fullName : user.name) + " ")]);
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "py-2 px-4 mb-4"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Tenants (" + _vm._s(_vm.groupTenants.length) + ")")]), _vm._l(_vm.groupTenants, function (tenant, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "font-weight-semibold mr-2 mb-2",
      attrs: {
        "link": "",
        "medium": "",
        "color": "primary"
      }
    }, [_vm._v(" " + _vm._s(tenant.name) + " ")]);
  })], 2)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }