<template>
  <OrderList :isAccount="true" :accountId="accountId" :header="header"></OrderList>
</template>

<script>

import OrderList from "@/views/Orders/Order/Search";

export default {
  props: {
    header: ""
  },
  components: {
    OrderList
  },
  data() {
    return {

      accountId: 0
    }
  }
  ,
  created() {
    this.accountId = this.$route.params.id

  },

}
</script>
