var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('toolbarTitle', {
    attrs: {
      "title": "Printed Tickets",
      "color": "greeny"
    }
  }), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "fixed-header": "",
      "height": "500px",
      "headers": _vm.printedOrdersHeaders,
      "items": _vm.orders,
      "server-items-length": _vm.ordersTotalCount,
      "loading": _vm.loading,
      "items-per-page": _vm.filter.itemsPerPage,
      "options": _vm.filter,
      "footer-props": {
        'items-per-page-options': [20, 50, 100]
      }
    },
    on: {
      "update:options": function ($event) {
        _vm.filter = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', {
          class: item.distribution.isLocked && 'grey lighten-4'
        }, [_c('td', {
          class: item.distribution.isLocked && 'disableCss'
        }, [_c('div', {
          staticClass: "d-flex flex-row",
          staticStyle: {
            "width": "180px"
          }
        }, [_c('OrderIDField', {
          attrs: {
            "order": item
          }
        }), _c('OrderIconsField', {
          attrs: {
            "order": item,
            "showPrintableInfo": false,
            "showDistributedInfo": true,
            "showGroupOrderInfo": true,
            "showClaimInfo": true,
            "showArchiveOrder": true
          }
        })], 1)]), _c('td', {
          class: item.distribution.isLocked && 'disableCss'
        }, [_c('OrderPrintDeliverField', {
          attrs: {
            "order": item
          }
        })], 1), _c('td', {
          class: item.distribution.isLocked && 'disableCss'
        }, [_c('div', [_c('b', [_vm._v(_vm._s(_vm._f("localizeUTCAndGetDate")(item.purchaseDate)))]), _vm._v(" " + _vm._s(_vm._f("localizeUTCAndGetTime")(item.purchaseDate)) + " ")])]), _c('td', {
          staticClass: "pl-0",
          class: item.distribution.isLocked && 'disableCss'
        }, [_c('orderCustomerField', {
          attrs: {
            "order": item,
            "address": true
          }
        })], 1), _c('td', {
          class: item.distribution.isLocked && 'disableCss',
          staticStyle: {
            "min-width": "160px"
          }
        }, [_c('earliestDepartureDateField', {
          attrs: {
            "order": item
          }
        })], 1), _c('td', {
          staticStyle: {
            "min-width": "160px"
          }
        }, [_c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('b', {
          staticClass: "threeDots",
          staticStyle: {
            "max-width": "120px"
          }
        }, [_vm._v(" " + _vm._s(item.distribution.printedByFullName ? item.distribution.printedByFullName : ""))]), _c('span', {
          staticClass: "grey--text text--darken-1"
        }, [_vm._v(" " + _vm._s(item.distribution.printedDate ? item.distribution.printedDate : ""))])])]), _c('td', {
          staticStyle: {
            "min-width": "160px"
          }
        }, [_c('div', {
          staticClass: "d-flex align-center"
        }, [item.internalMessage ? _c('v-tooltip', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "color": "black",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-1",
                attrs: {
                  "color": "mango-red",
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-message-reply-text ")]), _c('span', _vm._g(_vm._b({
                staticClass: "threeDots",
                class: item.distribution.isLocked && 'disableCss',
                staticStyle: {
                  "max-width": "170px"
                }
              }, 'span', attrs, false), on), [_vm._v(" " + _vm._s(_vm._f("cleanHtml")(item.internalMessage)))])];
            }
          }], null, true)
        }, [item.internalMessage ? _c('div', {
          staticStyle: {
            "max-width": "400px"
          }
        }, [_vm._v(" " + _vm._s(_vm._f("cleanHtml")(item.internalMessage)) + " ")]) : _vm._e()]) : _vm._e()], 1)]), _c('td', {
          staticStyle: {
            "min-width": "190px"
          }
        }, [_c('v-row', [_c('v-col', {
          staticClass: "d-flex justify-start align-center w-100",
          staticStyle: {
            "width": "100%",
            "display": "flex"
          },
          attrs: {
            "md": "auto"
          }
        }, [_c('v-tooltip', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "color": "black",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', {
                attrs: {
                  "disabled": !item.distribution.trackingId,
                  "loading": _vm.loadingButton === `cancel_button_${item.id}`,
                  "color": "primary",
                  "small": ""
                },
                on: {
                  "click": function ($event) {
                    _vm.loadingButton = `cancel_button_${item.id}`;
                    _vm.cancelDistribution(item);
                  }
                }
              }, [_vm._v(" CANCEL TO RE-PRINT ")])], 1)];
            }
          }], null, true)
        }, [_c('div', [_vm._v("Cancel for re-print.")])])], 1)], 1)], 1)])];
      }
    }])
  }), _vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "color": "#f4846e"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }