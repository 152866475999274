import { render, staticRenderFns } from "./AccountDField.vue?vue&type=template&id=0083252f&scoped=true"
import script from "./AccountDField.vue?vue&type=script&lang=js"
export * from "./AccountDField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0083252f",
  null
  
)

export default component.exports