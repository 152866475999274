var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "value": _vm.isDialogOpen,
      "transition": "dialog-top-transition",
      "max-width": "80%",
      "scrollable": false
    },
    on: {
      "click:outside": _vm.closeDialog
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v(_vm._s(_vm.editing ? "Update" : "Create New") + " Group")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "px-7 py-7 rounded-lg"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-1",
    attrs: {
      "label": "Group Name",
      "outlined": "",
      "required": "",
      "dense": "",
      "error-messages": _vm.groupNameErrors
    },
    model: {
      value: _vm.editItem.name,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "name", $$v);
      },
      expression: "editItem.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-select', {
    staticClass: "align-stretch font-weight-bold",
    attrs: {
      "solo": "",
      "items": _vm.tenants,
      "chips": "",
      "hide-details": "",
      "item-text": "name",
      "item-value": "id",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select tenants",
      "multiple": "",
      "persistent-hint": false
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [index < 3 ? _c('v-chip', [_c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e(), index === 3 ? _c('span', {
          staticClass: "grey&#45;&#45;text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.selectedTenants.length - 3) + ") ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selectedTenants,
      callback: function ($$v) {
        _vm.selectedTenants = $$v;
      },
      expression: "selectedTenants"
    }
  })], 1), _vm.tenantSelectionErrors.length > 0 ? _c('div', {
    staticClass: "mt-1 ml-3 red--text text-caption"
  }, [_vm._v(" " + _vm._s(_vm.tenantSelectionErrors[0]) + "! ")]) : _vm._e()])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto bg-danger overflow-hidden",
    attrs: {
      "max-width": "100%"
    }
  }, [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": "",
      "flat": ""
    }
  }, [_vm._v("Responsible Users ")]), _c('v-text-field', {
    ref: "search",
    staticClass: "mx-4 my-4",
    attrs: {
      "dense": "",
      "full-width": "",
      "hide-details": "",
      "label": "Search",
      "outlined": "",
      "loading": _vm.searching,
      "prepend-inner-icon": "mdi-account-search",
      "single-line": ""
    },
    model: {
      value: _vm.userSearchQuery,
      callback: function ($$v) {
        _vm.userSearchQuery = $$v;
      },
      expression: "userSearchQuery"
    }
  }), _c('v-row', {
    staticClass: "pl-5 pr-5"
  }, [_c('v-col', [_c('h4', {
    staticClass: "px-3 mb-0 accent--text"
  }, [_vm._v("Search Result")]), _c('v-list', {
    staticStyle: {
      "height": "300px",
      "overflow-y": "scroll"
    },
    attrs: {
      "dense": ""
    }
  }, [_vm.filteredUsers.length > 0 ? _c('div', [_vm._l(_vm.filteredUsers, function (item) {
    var _item$fullName, _item$fullName2;
    return [_c('v-list-item', {
      key: (_item$fullName = item.fullName) !== null && _item$fullName !== void 0 ? _item$fullName : item.name,
      attrs: {
        "dense": "",
        "disabled": _vm.loading
      },
      on: {
        "click": function ($event) {
          return _vm.createUserSelection(item);
        }
      }
    }, [_c('v-list-item-avatar', {
      attrs: {
        "size": "20"
      }
    }, [_c('v-icon', {
      attrs: {
        "disabled": false,
        "dense": ""
      },
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    })], 1), _c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s((_item$fullName2 = item.fullName) !== null && _item$fullName2 !== void 0 ? _item$fullName2 : item.name)
      }
    })], 1)];
  })], 2) : _vm._e()])], 1), _c('v-col', [_c('h4', {
    staticClass: "px-3 mb-0"
  }, [_vm._v("Selected Users (" + _vm._s(_vm.editItem.users.length) + ")"), _vm.userSelectionErrors.length > 0 ? _c('span', {
    staticClass: "red--text ml-3 text-caption"
  }, [_vm._v(" " + _vm._s(_vm.userSelectionErrors[0]) + "! ")]) : _vm._e()]), _c('v-list', {
    staticStyle: {
      "height": "300px",
      "overflow-y": "scroll"
    }
  }, [_c('draggable', {
    staticClass: "draggable-list",
    attrs: {
      "list": _vm.editItem.users,
      "group": "my-group"
    }
  }, _vm._l(_vm.editItem.users, function (selection, i) {
    var _selection$fullName, _selection$fullName2;
    return _c('v-list-item', {
      key: (_selection$fullName = selection.fullName) !== null && _selection$fullName !== void 0 ? _selection$fullName : selection.name,
      staticClass: "list-item shrink",
      staticStyle: {
        "cursor": "pointer"
      },
      style: {
        background: `rgba(243,122,98,${20 / (i + 0.01) / 40})`
      }
    }, [_c('v-list-item-avatar', [_c('v-icon', {
      attrs: {
        "color": `rgba(${255 / (i + 1)},${255 / (i + 1)},${255 / (i + 1)})`,
        "disabled": false
      },
      domProps: {
        "textContent": _vm._s('mdi-account')
      }
    })], 1), _c('v-list-item-title', {
      style: {
        color: `rgba(${255 / (i + 1)},${255 / (i + 1)},${255 / (i + 1)})`
      }
    }, [_vm._v(_vm._s((_selection$fullName2 = selection.fullName) !== null && _selection$fullName2 !== void 0 ? _selection$fullName2 : selection.name) + " ")]), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeUserSelection(i);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end pb-4 pr-6 px-4"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("Close")]), _c('v-btn', {
    attrs: {
      "dark": "",
      "color": "accent",
      "loading": _vm.submitLoader
    },
    on: {
      "click": function ($event) {
        _vm.validateGroupName(true);
        _vm.validateUserSelection(true);
        _vm.validateTenantSelection(true);
        _vm.createGroups();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.editing ? 'Update' : 'Create') + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }