<template>
  <!-- Dialog component -->
  <v-dialog
    @click:outside="closeDialog"
    :value="isDialogOpen"
    transition="dialog-top-transition"
    max-width="80%"
    :scrollable="false"
    style="overflow: hidden"
  >
    <!-- Card component -->
    <v-card>
      <!-- Toolbar component -->
      <v-toolbar fixed color="titlebg" class="title" dense dark>
        <v-toolbar-title>{{ editing ? "Update" : "Create New" }} Group</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Main content of the dialog -->
      <div class="px-7 py-7 rounded-lg">
        <!-- Grid layout -->
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="6" lg="6" md="12" sm="12" xs="12">
                <!-- Text field for Group Name -->
                <v-text-field class="mt-1"
                              label="Group Name"
                              outlined
                              required
                              dense
                              v-model="editItem.name"
                              :error-messages="groupNameErrors"
                ></v-text-field>
              </v-col>
              <v-col cols="6" lg="6" md="12" sm="12" xs="12">
                <div class="d-flex align-center">
                  <!-- Select dropdown for tenants -->
                  <v-select
                    solo
                    class="align-stretch font-weight-bold"
                    v-model="selectedTenants"
                    :items="tenants"
                    chips
                    hide-details
                    item-text="name"
                    item-value="id"
                    :menu-props="{ maxHeight: '400' }"
                    label="Select tenants"
                    multiple
                    :persistent-hint="false"
                  >
                    <!-- Slot for selected tenants chips -->
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 3">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 3" class="grey&#45;&#45;text text-caption">
                (+{{ selectedTenants.length - 3 }})
              </span>
                    </template>
                  </v-select>
                </div>
                <div v-if="tenantSelectionErrors.length > 0"
                     class="mt-1 ml-3 red--text text-caption">
                  {{ tenantSelectionErrors[0] }}!
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <!-- Card component for Responsible Users -->
            <v-card class="mx-auto bg-danger overflow-hidden" max-width="100%">
              <!-- Toolbar for Responsible Users section -->
              <v-toolbar fixed color="titlebg" class="title" dense dark flat
              >Responsible Users
              </v-toolbar
              >
              <!-- Search input field -->
              <v-text-field
                class="mx-4 my-4"
                ref="search"
                dense
                v-model="userSearchQuery"
                full-width
                hide-details
                label="Search"
                outlined
                :loading="searching"
                prepend-inner-icon="mdi-account-search"
                single-line
              ></v-text-field>
              <v-row class="pl-5 pr-5">
                <v-col>
                  <h4 class="px-3 mb-0 accent--text">Search Result</h4>
                  <!-- List component for displaying search results -->
                  <v-list dense style="height: 300px; overflow-y: scroll">
                    <div v-if="filteredUsers.length > 0">
                      <!-- Loop through filtered users -->
                      <template v-for="item in filteredUsers">
                        <!-- List item for each user -->
                        <v-list-item
                          dense
                          :key="item.fullName ?? item.name"
                          :disabled="loading"
                          @click="createUserSelection(item)"
                        >
                          <!-- User avatar -->
                          <v-list-item-avatar size="20">
                            <v-icon
                              :disabled="false"
                              dense
                              v-text="item.icon"
                            ></v-icon>
                          </v-list-item-avatar>
                          <!-- User name -->
                          <v-list-item-title
                            v-text="item.fullName ?? item.name"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                    </div>
                  </v-list>
                </v-col>
                <v-col>
                  <h4 class="px-3 mb-0">Selected Users ({{ editItem.users.length }})<span
                    v-if="userSelectionErrors.length > 0" class="red--text   ml-3  text-caption">
                      {{ userSelectionErrors[0] }}!
                    </span>
                  </h4>
                  <!-- List component for displaying selected users -->
                  <v-list style="height: 300px; overflow-y: scroll">
                    <!-- Draggable component for reordering users -->
                    <draggable
                      class="draggable-list"
                      :list="editItem.users"
                      group="my-group"
                    >
                      <!-- Loop through selected users -->
                      <v-list-item
                        v-for="(selection, i) in editItem.users"
                        :key="selection.fullName ?? selection.name"
                        style="cursor: pointer"
                        :style="{
                          background: `rgba(243,122,98,${
                            20 / (i + 0.01) / 40
                          })`,
                        }"
                        class="list-item shrink"
                      >
                        <!-- User avatar -->
                        <v-list-item-avatar>
                          <v-icon
                            :color="`rgba(${255 / (i + 1)},${255 / (i + 1)},${
                              255 / (i + 1)
                            })`"
                            :disabled="false"
                            v-text="'mdi-account'"
                          ></v-icon>
                        </v-list-item-avatar>
                        <!-- User name -->
                        <v-list-item-title
                          :style="{
                            color: `rgba(${255 / (i + 1)},${255 / (i + 1)},${
                              255 / (i + 1)
                            })`,
                          }"
                        >{{ selection.fullName ?? selection.name }}
                        </v-list-item-title>
                        <!-- Remove button for user selection -->
                        <v-btn
                          icon
                          @click="removeUserSelection(i)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn
                        >
                      </v-list-item>
                    </draggable>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- Card actions -->
      <v-card-actions class="justify-end pb-4 pr-6 px-4">
        <!-- Close button -->
        <v-btn color="primary" @click="closeDialog">Close</v-btn>
        <!-- Create/Update button -->
        <v-btn
          dark
          color="accent"
          :loading="submitLoader"
          @click="validateGroupName(true); validateUserSelection(true); validateTenantSelection(true); createGroups()"
        >
          {{ editing ? 'Update' : 'Create' }}
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {debounce} from "@/util/helpers";
import TrainplanetApi from "@/util/trainplanet.api";
import {mapGetters} from "vuex";
import draggable from "vuedraggable";
import group_data from "@/views/User/Group/components/group_data";

export default {
  props: ["editedGroup", "isDialogOpen", "groups"],
  components: {draggable},

  data: () => ({
    tenantSelectionErrors: [],
    userSelectionErrors: [],
    groupNameErrors: [],
    userSearchQuery: "", // Stores the search query entered by the user
    searching: false, // Indicates whether a search is in progress
    textFieldProps: {
      outlined: true,
    },
    submitLoader: false, // Indicates whether the create/update action is in progress
    items: [],
    loading: false, // Indicates whether data is being loaded
    search: "", // Stores the search query entered by the user (used for debouncing)
    userList: [], // Stores the list of users
    editing: false, // Indicates whether the dialog is in editing mode or create mode
    editItem: JSON.parse(JSON.stringify(group_data)), // Stores the data of the group being edited/created
    selectedTenants: [], // Stores the selected tenants
  }),
  // This lifecycle hook is executed after the component is mounted
  mounted() {
    this.loading = true;
    this.getUsers(); // Fetches the list of users
    this.loading = false;
  },

  computed: {
    ...mapGetters({
      tenants: "auth/userTenants", // Retrieves the list of tenants from Vuex store
      allTenants: "allTenants"
    }),
    users() {
      const search = this.search.toLowerCase();
      if (!search) return this.userList; // Returns the full user list if no search query is entered
      return this.userList.filter((item) => {
        const text = item.text.toLowerCase();
        return text.indexOf(search) > -1; // Filters the user list based on the search query
      });
    },
    filteredUsers() {
      return this.userList.filter(user => {
        return !this.editItem.users.some(u => u.id === user.id); // Filters the user list to exclude already selected users
      });
    },
  },

  watch: {
    editedGroup() {
      if (this.editedGroup) {
        this.editing = true;
        this.editItem = this.editedGroup; // Sets the editItem data to the group being edited
        this.selectedTenants = this.editItem.tenants; // Sets the selected tenants
      } else {
        this.editing = false;
        this.editItem = JSON.parse(JSON.stringify(group_data)); // Resets the editItem data to default values for new group creation
        this.selectedTenants = [];
      }
    },
    selected() {
      this.search = ""; // Resets the search query when a user is selected
    },
    userSearchQuery: {
      deep: true,
      handler: debounce(function () {
        this.getUsers(); // Performs a search for users with the debounced search query
      }, 200),
    },
  },

  methods: {
    validateTenantSelection(validateButtonClicked) {
      if (validateButtonClicked) {
        this.tenantSelectionErrors = [];
        if (this.selectedTenants.length === 0) {
          this.tenantSelectionErrors.push('At least one tenant must be selected');
        }
      }
    },
    validateUserSelection(validateButtonClicked) {
      if (validateButtonClicked) {
        this.userSelectionErrors = [];
        if (this.editItem.users.length === 0) {
          this.userSelectionErrors.push('At least one user must be selected');
        }
      }
    },
    validateGroupName(validateButtonClicked) {
      if (validateButtonClicked) {
        this.groupNameErrors = [];
        if (!this.editItem.name) {
          this.groupNameErrors.push('Group Name is required!');
        }
      }
    },
    createUserSelection(item) {
      const isUserExist = this.editItem.users.some(user => user.id === item.id);
      if (!isUserExist) {
        this.editItem.users.push({
          id: item.id,
          name: item.fullName ?? item.name,
        });
        this.userList = this.userList.filter(user => user.id !== item.id); // Removes the selected user from the user list
      }
    },
    removeUserSelection(index) {
      const removedUser = this.editItem.users.splice(index, 1)[0];
      if (!this.filteredUsers.some(user => user.id === removedUser.id)) {
        this.userList.push({
          id: removedUser.id,
          name: removedUser.fullName ?? removedUser.name,
          tenants: removedUser.tenants,
          icon: "mdi-account",
        }); // Adds the removed user back to the user list
      }
    },
    getImgUrl(imgUrl) {
      return require("@/assets/" + imgUrl); // Returns the image URL for the given image file path
    },
    closeDialog() {
      this.$emit("onClose");
      this.editItem = JSON.parse(JSON.stringify(group_data));
      this.selectedTenants = [];
      this.userSelectionErrors = [];
      this.tenantSelectionErrors = [];
      this.groupNameErrors = [];
      this.$emit("onClose");
    },
    next() {
      this.loading = true;
      setTimeout(() => {
        this.search = "";
        this.selected = [];
        this.loading = false;
      }, 2000);
    },
    async getUsers() {
      this.searching = true;
      const res = await TrainplanetApi.getUsers(this.userSearchQuery); // Calls the API to fetch users based on the search query
      this.userList = res.users.map((item) => ({
        ...item,
        name: item.fullName ?? item.name,
        tenants: item.tenants,
        icon: "mdi-account",
      })); // Stores the fetched users in the userList array
      this.searching = false;
    },
    async createGroups() {
      if (this.groupNameErrors.length === 0 && this.userSelectionErrors.length === 0 && this.tenantSelectionErrors.length === 0) {
        try {
          this.submitLoader = true;

          if (this.editing) {
            this.editItem = await TrainplanetApi.updateGroup(this.editItem.id, {
              ...this.editItem,
              tenants: this.selectedTenants
            }); // Calls the API to update the group
          } else {
            this.editItem = await TrainplanetApi.createGroup({
              ...this.editItem,
              tenants: this.selectedTenants
            }); // Calls the API to create a new group
          }

          this.closeDialog(); // Closes the dialog
          this.$emit("created"); // Emits the "created" event to notify the parent component
          await this.getUsers(); // Refreshes the user list
        } catch (error) {
          await this.$store.dispatch("error", error.response.data.message); // Displays an error message
        } finally {
          this.submitLoader = false; // Resets the submitLoader flag
        }
      }
    },
  },
};
</script>


<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 1px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(138, 138, 138) !important;
}

.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}

.v-dialog > .v-card > .v-card__actions {
  background: white;
  position: sticky;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  bottom: 0;
  z-index: 999;
}
</style>
