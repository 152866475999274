var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-tabs', {
    attrs: {
      "align-tabs": "center"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    attrs: {
      "value": 1
    }
  }, [_vm._v("Account Detail")]), _c('v-tab', {
    attrs: {
      "value": 2
    }
  }, [_vm._v("Account Order")])], 1), _c('v-window', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-window-item', [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('AccountDetail')], 1)], 1), _c('v-window-item', [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('AccountOrder', {
    attrs: {
      "header": 'Account Orders'
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }