var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-4",
    staticStyle: {
      "padding": "3rem"
    }
  }, [_c('v-form', {
    ref: "passengerForm"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "clearable": "",
      "label": "First Name",
      "hide-details": "",
      "color": "orange",
      "required": ""
    },
    model: {
      value: _vm.form.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "firstName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.firstName"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "clearable": "",
      "label": "Last Name",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.form.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lastName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.lastName"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "clearable": "",
      "label": "Email",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('VuePhoneNumberInput', {
    staticStyle: {
      "height": "55px"
    },
    attrs: {
      "default-country-code": _vm.selectedCountryCode,
      "error": !_vm.phoneValid,
      "clearable": true
    },
    on: {
      "update": _vm.logPhone
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    staticStyle: {
      "font-weight": "bolder"
    },
    attrs: {
      "outlined": "",
      "clearable": "",
      "items": _vm.allCountries.interrailCountryCodes,
      "item-text": "countryNameTranslations.EN",
      "color": "orange",
      "item-value": "countryNameTranslations.EN",
      "menu-props": "auto",
      "hide-details": "",
      "label": "Country of Residance ",
      "single-line": ""
    },
    model: {
      value: _vm.form.countryOfResidence,
      callback: function ($$v) {
        _vm.$set(_vm.form, "countryOfResidence", $$v);
      },
      expression: "form.countryOfResidence"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "clearable": "",
      "label": "Birthdate",
      "hide-details": "",
      "color": "orange"
    },
    on: {
      "click": function ($event) {
        _vm.datePickerOpen = true;
      }
    },
    model: {
      value: _vm.form.birthdate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "birthdate", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.birthdate"
    }
  }), _vm.datePickerOpen ? _c('v-dialog', {
    staticClass: "pa-5",
    attrs: {
      "width": "300px"
    },
    on: {
      "click:outside": function ($event) {
        _vm.datePickerOpen = false;
      }
    },
    model: {
      value: _vm.datePickerOpen,
      callback: function ($$v) {
        _vm.datePickerOpen = $$v;
      },
      expression: "datePickerOpen"
    }
  }, [_c('v-date-picker', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.datePickerOpen,
      expression: "datePickerOpen"
    }],
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "max": _vm.date,
      "full-width": ""
    },
    model: {
      value: _vm.form.birthdate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "birthdate", $$v);
      },
      expression: "form.birthdate"
    }
  })], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "pb-0 mt-4 mt-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-combobox', {
    staticClass: "font-weight-bold",
    attrs: {
      "error": !!_vm.membershipError,
      "label": "SJPrio Membership Number",
      "color": "orange",
      "height": "56",
      "chips": "",
      "deletable-chips": "",
      "clearable": "",
      "multiple": "",
      "outlined": "",
      "hide-details": "auto",
      "append-icon": "",
      "persistent-placeholder": ""
    },
    on: {
      "input": function ($event) {
        return _vm.handleInput(_vm.sjMembershipValues);
      },
      "blur": _vm.handleBlur
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('div', {
          staticClass: "membership-prefix"
        }, [_vm._v("9752 2102")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sjMembershipValues,
      callback: function ($$v) {
        _vm.sjMembershipValues = $$v;
      },
      expression: "sjMembershipValues"
    }
  }), _vm.membershipError ? _c('span', {
    staticClass: "error-message"
  }, [_vm._v(_vm._s(_vm.membershipError))]) : _vm._e()], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "type": "number",
      "outlined": "",
      "clearable": "",
      "label": "Identification",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.form.identification,
      callback: function ($$v) {
        _vm.$set(_vm.form, "identification", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.identification"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "clearable": "",
      "label": "Reference",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.form.reference,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reference", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.reference"
    }
  })], 1), !_vm.isAdd ? _c('v-col', {
    staticClass: "pb-0 mt-4 mt-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "height": "56px"
    },
    attrs: {
      "block": "",
      "color": "primary",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.updatePassenger();
      }
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }]
  }, [_vm._v(" Update ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mng-loader"
  })])], 1) : _vm._e(), _vm.isAdd ? _c('v-col', {
    staticClass: "pb-0 mt-4 mt-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "height": "56px"
    },
    attrs: {
      "block": "",
      "color": "primary",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.createPassenger();
      }
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }]
  }, [_vm._v(" Create ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mng-loader"
  })])], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }