<template>
  <div>
    <header
      class="theme--light v-navigation-drawer  elevation-1 mb-10"
      style="height: 50px; border-radius: 5px; margin-bottom: 10px"
    >
      <div
        class="v-toolbar__content flex justify-space-between"
        style="height: 50px; padding: 25px"
      >
        <div class="v-toolbar__title">
          <h4 class="black--text">Paper Report</h4>
        </div>
        <v-btn dark class="button greeny text-button" @click="getPapersReportExcel">
          <v-icon>
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </div>
    </header>

    <v-card>
      <v-row align="center" class="px-6 py-3">
        <v-col cols="12" sm="6">
          <v-select
            outlined
            v-model="month"
            :items="months"
            :menu-props="{ maxHeight: '400' }"
            label="Month"
            hint="Please select month"
            persistent-hint
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            outlined
            v-model="year"
            :items="years"
            :menu-props="{ maxHeight: '400' }"
            label="Year"
            hint="Please select year"
            persistent-hint
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="papers"
        :loading="loading"
        :items-per-page="filter.itemsPerPage"
        :options.sync="filter"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="boxNumber"
        :show-expand="true"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100]
        }"
      >

        <template v-slot:expanded-item="{ headers, item }" :colspan="headers.length">
          <td :colspan="headers.length" class="py-3">
            <div class="mb-2" style="width:100%">
              <v-simple-table>

                <template v-slot:default>

                  <tbody>
                  <tr v-for="(interval, index) in item.intervals" :key="index">
                    <td width="40px"></td>
                    <td width="250px"></td>
                    <td width="250px">{{ interval.beginning }}</td>
                    <td width="250px">{{ interval.end }}</td>
                    <td></td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api.js";
import Header from "@/components/common/Header.vue";

export default {
  components: {
    Header
  },
  data: () => ({
    expanded: [],
    loading: false,
    singleExpand: false,
    filter: {
      itemsPerPage: 10
    },
    papers: [],
    month: "May",
    year: new Date().getFullYear(),
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    headers: [
      {
        text: "",
        value: "data-table-expand",
        sortable: false
      },
      {
        text: "Box Number",
        value: "boxNumber",
        sortable: false,
        width: "250px"
      },
      {
        text: "Beginning",
        value: "beginning",
        sortable: false,
        width: "250px"
      },
      {
        text: "End",
        value: "end",
        sortable: false,
        width: "250px"
      },
      {
        text: "Used Papers",
        value: "usedPapersTotal",
        sortable: false
      }
    ],
    years: []
  }),
  mounted() {
    this.getPapersReport({
      month: this.months.indexOf(this.month) + 1,
      year: this.year
    });
    const year = new Date().getFullYear();
    const years = [year];

    for (let i = 0; i < 25; i++) {
      years.push(years[i] - 1)
    } // prettier-ignore

    this.years = years;
  },
  watch: {
    month(val) {
      this.getPapersReport({
        month: this.months.indexOf(val) + 1,
        year: this.year
      });
    },
    year(val) {
      this.getPapersReport({
        month: this.months.indexOf(this.month) + 1,
        year: val
      });
    }
  },
  methods: {
    async getPapersReportExcel() {
      await this.$store.dispatch("loading", true);
      try {
        const data = await Trainplanet.getPapersReportExcel({
          month: this.months.indexOf(this.month) + 1,
          year: this.year
        });

        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
      } catch (error) {
        await this.$store.dispatch("error", "There is an error occurred while fetching data!");
      }
      await this.$store.dispatch("loading", false);
    },
    async getPapersReport({month, year}) {
      this.loading = true;
      try {
        const res = await Trainplanet.getPapersReport({month, year});
        this.papers = res.map(item => ({
          ...item
        }));
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
