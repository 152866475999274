var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tooltip', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "color": "black",
      "bottom": "",
      "disabled": !_vm.order.isBusinessOrder
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "ml-5",
          staticStyle: {
            "position": "relative"
          }
        }, 'div', attrs, false), on), [_vm.order.isBusinessOrder ? _c('div', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "position": "absolute",
            "margin-left": "-20px",
            "margin-top": "-1px"
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "black",
            "small": ""
          }
        }, [_vm._v(" mdi-domain")])], 1) : _vm._e(), _c('div', {
          attrs: {
            "clasS": "font-weight-bold"
          }
        }, [_vm.order.delivery.fullName ? _c('span', [_vm._v(" " + _vm._s(_vm.order.delivery.fullName) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.order.customer.fullName))])]), _vm.order.delivery.fullAddress && _vm.address ? _c('div', {
          staticClass: "grey--text text--darken-1"
        }, [_vm._v(" " + _vm._s(_vm.order.delivery.fullAddress) + " ")]) : _vm._e()])];
      }
    }])
  }, [_c('div', [_vm._v("Company Order")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }