<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12">
        <div style="position: relative">
          <header
            class="theme--light v-navigation-drawer elevation-1"
            style="height: 50px; border-radius: 5px; margin-bottom: 10px">
            <div class="v-toolbar__content flex flex-row justify-space-between" style="height: 50px; padding: 25px">
              <div class="v-toolbar__title">
                <h4 class="black--text">Saved Customers</h4>
              </div>
              <div class="d-flex gap-4" style="gap: 10px">
                <v-btn
                  color="primary"
                  v-if="selecteds.length > 0"
                  @click="dialogDelete = true"
                  style="height: 40px; font-size: 13px">
                  <v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
            </div>
          </header>
        </div>
        <v-data-table
          :hide-default-footer="true"
          v-model="selecteds"
          :disable-pagination="true"
          :loading="isLoading"
          show-select
          :mobile-breakpoint="1500"
          :headers="headersPassenger"
          :items="customer"
          class="elevation-1 mt-2"
          :footer-props="{}">
          <template v-slot:[`item.firstName`]="{ item }">
            <div class="orderID">
              <div class="d-flex one-line">
                <span :class="{ 'grey--text text--darken-1': !item.fullName }">
                  {{ item.fullName ? item.fullName : "N/A" }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <copyToClipboardField :item="item.email"></copyToClipboardField>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <div class="orderID">
              <div class="d-flex one-line">
                <span :class="{ 'grey--text text--darken-1': !item.phone }">
                  {{ item.phone ? item.phone : "N/A" }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.companyCustomerNumber`]="{ item }">
            <div class="orderID">
              <div class="d-flex one-line">
                <span :class="{ 'grey--text text--darken-1': !item.companyCustomerNumber }">
                  {{ item.companyCustomerNumber ? item.companyCustomerNumber : "N/A" }}
                </span>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-dialog v-model="dialogDelete" max-width="580px">
        <v-card class="delete-dialog">
          <v-card-title class="text-h5">Are you sure you want to remove this customers?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogDelete = false"> Cancel </v-btn>
            <v-btn color="accent" @click="deleteCustomer">
              <span v-show="!isLoading"> Remove </span>
              <div v-show="isLoading" class="mng-loader"></div>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import copyToClipboardField from "@/components/common/copyToClipboardField";
import AccountApi from "@/services/account.api";
import Trainplanet from "@/util/trainplanet.api";
import CustomerAdd from "./customerAdd.vue";

export default {
  name: "passenger-list",
  components: {
    copyToClipboardField,
    CustomerAdd,
  },
  props: {
    accountId: "",
    loading: false,
    customer: [],
    tenant: "",
    token: "",
    haveActions: false,
  },
  computed: {
    AccountModel() {
      return new AccountApi(this.tenant, this.token);
    },
  },
  emit: ["change", "takeCustomer"],
  data() {
    return {
      isLoading: false,
      selecteds: [],
      passengerDialog: "",
      selectedCustomer: "",
      isAdd: false,
      dialogDelete: false,
      headersPassenger: [],
    };
  },
  created() {
    this.headersPassenger = [
      {
        text: "Full Name",
        value: "firstName",
        sortable: false,
        width: 250,
      },
      {
        text: "Email",
        value: "email",
        sortable: false,
      },
      {
        text: "Phone",
        value: "phone",
        sortable: false,
      },
      {
        text: "Company Customer Number",
        value: "companyCustomerNumber",
        sortable: false,
      },
    ];
  },
  methods: {
    async customerList() {
      this.isLoading = true;
      try {
        const response = await Trainplanet.getCustomerList();
        this.companies = response.company;
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error);
      } finally {
        this.isLoading = false;
      }
    },
    deleteCustomer() {
      this.isLoading = true;
      var deletedIds = [];
      this.selecteds.forEach((element) => {
        deletedIds.push(element.id);
      });
      Trainplanet.removeCustomer(this.accountId, { customerIds: deletedIds })
        .then(({ data }) => {
          this.$store.dispatch("success", "Customer Removed Successfuly");
          this.dialogDelete = false;
          this.selecteds = [];
          this.$emit("takeCustomer");
          this.isLoading = false;
        })
        .catch((error) => {
          this.$store.dispatch("error", error);
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    titleize(value) {
      if (value) {
        value = value.toLowerCase();
        return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
      }
    },
    chooseCustomer(item) {
      this.selectedCustomer = item;
    },
    async getToken() {
      this.$emit("change");
    },
  },
};
</script>
