<template>
  <div>
    <v-container class="mt-0 py-10" style="margin-top: 60px">
      <v-row>
        <v-col cols="12" lg="4" md="4">
          <div class="v-toolbar__title">
            <h4 class="black--text">Select Companies</h4>
            <v-text-field
              v-model.trim="filterCompany"
              outlined
              clearable
              class="font-weight-bold"
              label="Filter By Name"
              hide-details
              color="orange"></v-text-field>
          </div>
          <v-list style="max-height: 400px; overflow: auto" lines="three">
            <v-list-item
              v-for="(item, idx) of tempCompanies"
              :key="idx"
              style="
                border-radius: 6px;
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                  0 1px 5px 0 rgba(0, 0, 0, 0.12);
              "
              :class="{ 'selected-item-company': selectedCompanyId == item.id }"
              class="mt-4"
              @click="selectedCompanyId = item.id"
              :disabled="loadingInner"
              subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit">
              <div style="display: flex; justify-content: space-between; width: 100%">
                <div>
                  {{ item.name }}
                </div>
                <div style="display: flex; align-items: center">
                  <strong style="display: flex">
                    <span
                      v-show="!loadingInner || (!loadingInner && !selectedCompanyId == item.id)"
                      class="span-select">
                      Select
                    </span>
                    <div v-show="loadingInner && selectedCompanyId == item.id" class="mng-loader"></div>
                  </strong>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" lg="4" md="4">
          <div class="v-toolbar__title">
            <h4 class="black--text">Select Customers</h4>
          </div>

          <v-text-field
            v-model.trim="filterCustomers"
            outlined
            clearable
            class="font-weight-bold"
            label="Filter By Customer Name"
            hide-details
            color="orange"></v-text-field>
          <v-list v-show="!loadingInner" style="max-height: 400px; overflow: auto" lines="three">
            <v-list-item
              v-for="(item, idx) of tempCustomer"
              :key="idx"
              style="
                border-radius: 6px;
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                  0 1px 5px 0 rgba(0, 0, 0, 0.12);
              "
              @click="addCustomer(item)"
              class="mt-4"
              v-show="calculateShow(item.id)"
              subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit">
              <div style="display: flex; justify-content: space-between; width: 100%">
                <div>
                  {{ item.fullName ? item.fullName : "" }}
                  {{ item.companyCustomerNumber ? `#${item.companyCustomerNumber}` : "" }}
                </div>
                <div>
                  <strong style="font-size: 18px"> <v-icon class="mr-3">mdi-plus</v-icon> </strong>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" lg="4" md="4">
          <div class="v-toolbar__title">
            <h4 class="black--text">Selected Customers</h4>
          </div>

          <v-text-field
            v-model.trim="filterSelectedCustomers"
            outlined
            clearable
            class="font-weight-bold"
            label="Filter By Customer Name"
            hide-details
            color="orange"></v-text-field>
          <v-list style="max-height: 500px; overflow: auto" lines="three">
            <v-list-item
              v-for="(item, idx) of tempSelectedCustomer"
              :key="idx"
              style="
                border-radius: 6px;
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                  0 1px 5px 0 rgba(0, 0, 0, 0.12);
              "
              @click="deleteCustomer(item)"
              class="mt-4"
              subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit">
              <div style="display: flex; justify-content: space-between; width: 100%">
                <div>
                  {{ item.fullName ? item.fullName : "" }}
                  {{ item.companyCustomerNumber ? `#${item.companyCustomerNumber}` : "" }}
                </div>
                <div>
                  <strong style="font-size: 18px"> <v-icon class="mr-3">mdi-minus</v-icon> </strong>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" lg="10"></v-col>
        <v-col cols="12" lg="2">
          <v-btn color="primary" @click="addCustomers" style="height: 40px; font-size: 13px">
            <span v-show="!loadingInner" style="display: flex; align-items: center">
              <v-icon class="mr-3">mdi-plus</v-icon>
              Add Customers
            </span>
            <div v-show="loadingInner" class="mng-loader"></div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";

export default {
  name: "CustomerAdd",
  data() {
    return {
      companies: [],
      tempCompanies: [],
      tempCustomer: [],
      tempSelectedCustomer: [],
      selectedCompanyId: {},
      customers: [],
      addedCustomers: [],
      mainIgnoredCustomers: [],
      loadingInner: false,
      filterCompany: "",
      filterSelectedCustomers: "",
      filterCustomers: "",
    };
  },
  props: {
    accountId: 0,
    ignoredCustomers: [],
  },
  emits: ["close"],
  watch: {
    selectedCompanyId: {
      handler: function f(val) {
        this.getCustomer();
      },
    },
    companies: {
      handler: function f(val) {
        if (this.companies && this.companies.length > 0) {
          this.selectedCompanyId = this.companies[0].id;
          this.tempCompanies = this.companies;
        }
      },
    },

    customers: {
      handler: function f(val) {
        if (this.customers && this.customers.length > 0) {
          this.tempCustomer = this.customers;
          this.customersFilter(this.filterCustomers);
        }
      },
    },

    addedCustomers: {
      handler: function f(val) {
        if (this.addedCustomers && this.addedCustomers.length > 0) {
          this.tempSelectedCustomer = this.addedCustomers;
          this.addedCustomersFilter(this.filterSelectedCustomers);
        }
      },
    },

    filterCompany: {
      handler: function f(val) {
        if (val) {
          this.tempCompanies = this.companies.filter((x) => x.name.toLowerCase().includes(val.toLowerCase()));
        } else {
          this.tempCompanies = this.companies;
        }
      },
    },

    filterCustomers: {
      handler: function f(val) {
        this.customersFilter(val);
      },
    },

    filterSelectedCustomers: {
      handler: function f(val) {
        this.addedCustomersFilter(val);
      },
    },
  },
  created() {
    this.getCompanies();
    this.mainIgnoredCustomers = this.mainIgnoredCustomers.concat(this.ignoredCustomers);
  },
  methods: {
    customersFilter(val) {
      if (val) {
        this.tempCustomer = this.customers.filter((x) => {
          let fullNameMatch = x.fullName ? x.fullName.toLowerCase().includes(val.toLowerCase()) : false;
          let companyNumberMatch = x.companyCustomerNumber
            ? x.companyCustomerNumber.toLowerCase().includes(val.toLowerCase())
            : false;
          return fullNameMatch || companyNumberMatch;
        });
      } else {
        this.tempCustomer = this.customers;
      }
    },
    addedCustomersFilter(val) {
      if (val) {
        this.tempSelectedCustomer = this.addedCustomers.filter((x) => {
          let fullNameMatch = x.fullName ? x.fullName.toLowerCase().includes(val.toLowerCase()) : false;
          let companyNumberMatch = x.companyCustomerNumber
            ? x.companyCustomerNumber.toLowerCase().includes(val.toLowerCase())
            : false;
          return fullNameMatch || companyNumberMatch;
        });
      } else {
        this.tempSelectedCustomer = this.addedCustomers;
      }
    },
    async getCompanies() {
      this.loadingInner = true;
      try {
        const response = await Trainplanet.getCompanies();
        this.companies = response.company;
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error);
      } finally {
        this.loadingInner = false;
      }
    },

    async addCustomers() {
      this.loadingInner = true;
      var idArray = [];
      this.addedCustomers.forEach((element) => {
        idArray.push(element.id);
      });
      try {
        const response = await Trainplanet.addAccountCustomer(this.accountId, { customerIds: idArray });
        this.companies = response.company;
        await this.$store.dispatch("success", "Customers succesfully added on account");
        this.$emit("close");
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error);
      } finally {
        this.loadingInner = false;
      }
    },
    deleteCustomer(item) {
      var ignoreIndex = this.mainIgnoredCustomers.findIndex((x) => x.id == item.id);
      var addedIndex = this.addedCustomers.findIndex((x) => x.id == item.id);

      this.mainIgnoredCustomers.splice(ignoreIndex, 1);
      this.addedCustomers.splice(addedIndex, 1);
    },

    addCustomer(item) {
      this.mainIgnoredCustomers.push(item);
      this.addedCustomers.push(item);
    },
    getCustomer() {
      this.loadingInner = true;
      Trainplanet.getCustomerListParams({ companyId: this.selectedCompanyId })
        .then(({ data }) => {
          this.customers = data.customers;
          this.loadingInner = false;
        })
        .catch((error) => {
          this.$store.dispatch("error", error);
          this.loadingInner = false;
        })
        .finally(() => {
          this.loadingInner = false;
        });
    },

    calculateShow(id) {
      var elementBool = true;
      this.mainIgnoredCustomers.forEach((element) => {
        if (element.id == id) {
          elementBool = false;
        }
      });

      return elementBool;
    },
  },
};
</script>

<style scoped lang="scss">
.selected-item-company {
  background-color: #f37a62;
  color: white !important;

  .span-select {
    display: none;
  }
}
</style>
