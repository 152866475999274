<template>
  <div class="d-flex one-line">
    <v-menu offset-y open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <a :href="fromClaimPage == 'true' ? `/user/inquiries/${order.inquiries}` : `/user/${order.id}`" target="_blank"
            class="text-decoration-none">
            <v-chip color="secondary" class="font-weight-bold" style="min-width: 130px" link small>
              {{ order.id }}
            </v-chip>
          </a>
        </div>
      </template>
      <v-list dense>
        <v-list-item>
          <v-list-item-title>
            <v-btn text :href="`/user/${order.id}`" block small target="_blank" class="justify-start">
              <v-icon color="accent" small> mdi-eye </v-icon>
              <span class="ml-1 font-weight-medium">Open Order</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-btn text @click="copyToClipboard(order.id)" small block class="justify-start">
            <v-icon color="mango-red " small> mdi-content-copy </v-icon>
            <span class="ml-1 font-weight-medium" @click="copyToClipboard(order.id)">Copy Order Id</span>
          </v-btn>
        </v-list-item>
        <v-list-item v-if="order.inquiries && order.inquiries.length">
          <v-list-item-title>
            <v-btn text :href="`/user/inquiries/${order.inquiries}`" block small target="_blank" class="justify-start">
              <v-icon color="primary" small> mdi-lifebuoy </v-icon>
              <span class="ml-1 font-weight-medium">Open Order Claim</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import common from "@/mixins/common";
import { copyToClipboard } from "@/util/helpers";
export default {
  name: "AccountIDField",
  props: ["order", "fromClaimPage"],
  methods: {
    copyToClipboard,
  },
  mixins: [common],
};
</script>
<style scoped></style>
