var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "color": "black"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "d-flex align-center"
        }, 'div', attrs, false), on), [!_vm.order.delivery.country ? _c('div', {
          staticClass: "d-flex align-center justify-center rounded font-weight-bold black grey lighten-2",
          staticStyle: {
            "width": "70px",
            "height": "24px"
          }
        }, [_vm._v(" None ")]) : _vm.order.delivery.country === 'SE' ? _c('div', {
          staticClass: "d-flex align-items-center px-2 rounded",
          staticStyle: {
            "width": "70px",
            "height": "24px",
            "background": "#1ca2d7"
          }
        }, [_c('img', {
          attrs: {
            "width": "100%",
            "src": require("@/assets/postnord.svg"),
            "alt": "Postnord"
          }
        })]) : _c('div', {
          staticClass: "d-flex align-items-center px-2 rounded",
          staticStyle: {
            "width": "70px",
            "height": "24px",
            "background": "#f7c501"
          }
        }, [_c('img', {
          attrs: {
            "width": "100%",
            "src": require("@/assets/dhl.svg"),
            "alt": "DHL"
          }
        })]), _vm.order.distribution.slug === 'express_post' ? _c('img', {
          staticClass: "d-flex align-center ml-1",
          attrs: {
            "height": "16px",
            "src": require("@/assets/express.svg"),
            "alt": "Express Post"
          }
        }) : _vm._e()])];
      }
    }])
  }, [!_vm.order.delivery.country ? _c('span', [_vm._v(" Please enter a proper address and country in "), _c('br'), _vm._v(" order customer shipping/delivery details.")]) : _c('span', [_vm._v(_vm._s(_vm.order.distribution.title) + " "), _vm.order.delivery.country ? _c('span', [_vm._v(" " + _vm._s(" - " + _vm.getCountryName(_vm.order.delivery.country)) + " ")]) : _vm._e(), _vm.order.delivery.country !== 'SE' ? _c('span', [_c('br'), _vm._v("Use yellow envelope.")]) : _vm._e()])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }