<template>
  <div class="p-4" style="padding: 3rem">
    <v-form ref="passengerForm">
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field
            :rules="[rules.required]"
            v-model.trim="form.firstName"
            outlined
            clearable
            class="font-weight-bold"
            label="First Name"
            hide-details
            color="orange"
            required></v-text-field>
        </v-col>

        <v-col cols="12" class="pb-0">
          <v-text-field
            :rules="[rules.required]"
            v-model.trim="form.lastName"
            outlined
            clearable
            class="font-weight-bold"
            label="Last Name"
            hide-details
            color="orange"></v-text-field>
        </v-col>

        <v-col cols="12" class="pb-0">
          <v-text-field
            v-model.trim="form.email"
            outlined
            clearable
            class="font-weight-bold"
            label="Email"
            hide-details
            color="orange"></v-text-field>
        </v-col>

        <v-col cols="12" class="pb-0">
          <VuePhoneNumberInput
            :default-country-code="selectedCountryCode"
            @update="logPhone"
            :error="!phoneValid"
            v-model="form.phone"
            :clearable="true"
            style="height: 55px" />
        </v-col>

        <v-col cols="12" class="pb-0">
          <v-select
            outlined
            clearable
            v-model="form.countryOfResidence"
            :items="allCountries.interrailCountryCodes"
            item-text="countryNameTranslations.EN"
            color="orange"
            style="font-weight: bolder"
            item-value="countryNameTranslations.EN"
            menu-props="auto"
            hide-details
            label="Country of Residance "
            single-line></v-select>
        </v-col>

        <v-col cols="12" class="pb-0">
          <v-text-field
            v-model.trim="form.birthdate"
            outlined
            clearable
            class="font-weight-bold"
            @click="datePickerOpen = true"
            label="Birthdate"
            hide-details
            color="orange"></v-text-field>

          <v-dialog
            v-model="datePickerOpen"
            v-if="datePickerOpen"
            width="300px"
            class="pa-5"
            @click:outside="datePickerOpen = false">
            <v-date-picker
              :max="date"
              style="width: 300px"
              v-show="datePickerOpen"
              v-model="form.birthdate"
              full-width></v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" class="pb-0 mt-4 mt-md-0">
          <v-combobox
            v-model="sjMembershipValues"
            @input="handleInput(sjMembershipValues)"
            @blur="handleBlur"
            :error="!!membershipError"
            label="SJPrio Membership Number"
            class="font-weight-bold"
            color="orange"
            height="56"
            chips
            deletable-chips
            clearable
            multiple
            outlined
            hide-details="auto"
            append-icon=""
            persistent-placeholder>
            <template v-slot:prepend-inner>
              <div class="membership-prefix">9752 2102</div>
            </template>
          </v-combobox>
          <span v-if="membershipError" class="error-message">{{ membershipError }}</span>
        </v-col>

        <v-col cols="12" class="pb-0">
          <v-text-field
            v-model.trim="form.identification"
            type="number"
            outlined
            clearable
            class="font-weight-bold"
            label="Identification"
            hide-details
            color="orange"></v-text-field>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-text-field
            v-model.trim="form.reference"
            outlined
            clearable
            class="font-weight-bold"
            label="Reference"
            hide-details
            color="orange"></v-text-field>
        </v-col>

        <v-col v-if="!isAdd" cols="12" class="pb-0 mt-4 mt-md-0">
          <v-btn block color="primary" height="40" @click="updatePassenger()" style="height: 56px">
            <span v-show="!loading"> Update </span>
            <div v-show="loading" class="mng-loader"></div>
          </v-btn>
        </v-col>

        <v-col v-if="isAdd" cols="12" class="pb-0 mt-4 mt-md-0">
          <v-btn block color="primary" height="40" @click="createPassenger()" style="height: 56px">
            <span v-show="!loading"> Create </span>
            <div v-show="loading" class="mng-loader"></div>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import countries from "@/assets/countries.json";
import dateTimeField from "@/components/common/dateTimeField";
import toolbarTitle from "@/components/common/toolbarTitle";
import router from "@/router";
import AccountApi from "@/services/account.api";
import CountryList from "country-list-with-dial-code-and-flag";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import store from "@/store";
import ValidationSystems from "@/views/Orders/Order/ValidationSystems.vue";

export default {
  name: "passengerAddOrUpdate",

  props: {
    passenger: "",
    isAdd: "",
    accountId: "",
    isUpdate: false,
  },
  emits: ["close"],
  data() {
    return {
      selectedCountryCode: "SE",
      date: new Date(),

      rules: {
        required: (value) => !!value || "Required.",
        select2: [(v) => v.length > 0 || "Item is required in select 2"],
        select: [(v) => !!v || "Item is required"],
        chip: (val) => {
          val = val.replace(/\s/g, "");

          const pattern = /^[0-9\s*]*$/;
          if (!pattern.test(val)) return "Please enter only numeric characters";
          if (val.length !== 16) return "Input must be 16 digits long.";
          if (!this.luhnCheck(val)) return "Invalid membership number";

          return true;
        },
      },
      loading: false,
      phoneValid: true,
      form: {
        firstName: this.passenger.firstName ? this.passenger.firstName : "",
        lastName: this.passenger.lastName ? this.passenger.lastName : "",
        phone: this.passenger.phone ? this.passenger.phone : "",
        email: this.passenger.email ? this.passenger.email : "",
        birthdate: this.passenger.birthdate ? this.passenger.birthdate : "",
        identification: this.passenger.identification ? this.passenger.identification : "",
        countryOfResidence: "",
        reference: this.passenger.reference ? this.passenger.reference : "",
        memberships: [],
      },
      id: "",
      datePickerOpen: false,
      sjMembershipList: [],
      sjMembershipValues: [],
      membershipError: "",
    };
  },
  components: {
    ValidationSystems,
    toolbarTitle,
    dateTimeField,
    VuePhoneNumberInput,
  },
  computed: {
    AccountModel() {
      return new AccountApi(this.tenantId, this.token);
    },
  },
  created() {
    CountryList, this.getTenant();

    this.allCountries = countries;

    if (this.passenger.phone) {
      this.selectedCountryCode = this.passenger.phone.split(" ")[0];
      this.selectedCountryCode = "+" + this.selectedCountryCode.substring(1);
      this.form.phone = this.form.phone.split(" ")[1];
      this.selectedCountryCode = window.CountryList.findByDialCode(this.selectedCountryCode)[0].data.code;
    }
    this.getToken();
    this.dateParser();
    if (!this.isAdd) {
      if (this.passenger.countryOfResidence) {
        this.form.countryOfResidence = this.titleize(this.passenger.countryOfResidence.toLowerCase());
      }
      this.passenger.memberships.forEach((element) => {
        this.sjMembershipList.push(element);
        this.sjMembershipValues.push(element.number.replace(/(\d{4})/g, "$1 ").trim());
      });
    }

    if (this.accountId) {
      this.id = this.accountId;
    } else {
      this.id = window.location.pathname.split("/user/")[1];
    }
  },
  watch: {
    sjMembershipValues: {
      handler(newVal) {
        // Removing duplicates
        newVal.forEach((item) => {
          const itemCount = newVal.filter((val) => val === item).length;
          if (itemCount > 1) {
            const duplicateIndex = newVal.lastIndexOf(item);
            newVal.splice(duplicateIndex, 1);
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    logPhone(payload) {
      this.selectedCountryCode = payload.countryCode;
      if (this.form.phone) {
        this.phoneValid = payload.isValid;
      } else {
        this.phoneValid = true;
      }
    },
    dateParser() {
      var date = new Date();
      this.date =
        +date.getFullYear() +
        "-" +
        (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
        "-" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate());
    },
    // Resetting error state
    handleBlur() {
      this.membershipError = "";
    },
    luhnCheck(value) {
      const arr = `${value}`
        .split("")
        .reverse()
        .map((x) => Number.parseInt(x));
      const lastDigit = arr.shift();
      let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val)), 0);
      sum += lastDigit;
      return sum % 10 === 0;
    },
    // Handling all events on enter including add, error check, delete
    handleInput(value) {
      let membershipList, membershipValues, prefix;
      prefix = "97522102";

      const addPrefixAndRemoveSpaces = (item) => {
        item = item.replace(/\s/g, "");
        if (!item.includes(prefix)) {
          item = prefix + item;
        }
        return item;
      };

      value = value.map(addPrefixAndRemoveSpaces);
      membershipList = this.sjMembershipList;
      membershipValues = this.sjMembershipValues.map(addPrefixAndRemoveSpaces);

      let toRemove = [];
      toRemove = membershipList.filter((obj) => !value.includes(obj.number));
      toRemove.forEach((item) => {
        const index = membershipList.findIndex((obj) => obj.number === item.number);
        if (index !== -1) {
          membershipList.splice(index, 1);
        }
      });

      value.forEach((item) => {
        if (!membershipList.some((obj) => obj.number === item)) {
          const validationMessage = this.rules.chip(item);
          if (typeof validationMessage === "string") {
            membershipValues.splice(membershipValues.indexOf(item), 1);
            this.membershipError = validationMessage;
            return;
          }

          this.membershipError = "";
          const newMembership = { type: "F", number: item };
          membershipList.push(newMembership);
        }
      });

      this.sjMembershipList = membershipList;
      this.sjMembershipValues = membershipValues;

      this.sjMembershipValues = this.sjMembershipValues.map((item) => {
        if (!item.includes(" ")) {
          return item.replace(/(\d{4})/g, "$1 ").trim();
        }
        return item;
      });
    },
    titleize(value) {
      return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
    },
    async getTenant() {
      const res = await this.$store.dispatch("auth/getUser");
      this.allTenants = res.tenants;
    },
    async getToken() {
      this.token = await store.dispatch("auth/acquireToken", router.history.current.meta.scopes);
    },
    updatePassenger() {
      if (this.$refs.passengerForm.validate() && (this.phoneValid || !this.form.phone)) {
        var phoneString = "";

        if (this.form.phone) {
          var dialCode = window.CountryList.findOneByCountryCode(this.selectedCountryCode).data.dial_code;
          phoneString = dialCode + " " + this.form.phone.replace(/\s/g, "");
        }
        this.loading = true;
        var sendObject = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          phone: this.form.phone ? phoneString : "",
          email: this.form.email ? this.form.email : "",
          birthdate: this.form.birthdate ? this.form.birthdate : null,
          identification: this.form.identification ? this.form.identification.toString() : "",
          countryOfResidence: this.form.countryOfResidence ? this.form.countryOfResidence.toUpperCase() : "",
          reference: this.form.reference ? this.form.reference : "",
          memberships: [],
        };

        sendObject.memberships = [
          ...this.sjMembershipList.map((item) => ({ ...item, number: item.number.replace(/\s/g, "") })),
        ];

        this.AccountModel.updatePassenger(this.passenger.id, sendObject, this.id)
          .then(({ data }) => {
            this.$store.dispatch("success", "Passenger Updated Successfuly");
            this.$emit("close");
          })
          .catch((error) => {
            this.$store.dispatch("error", error.message);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    createPassenger() {
      if (this.$refs.passengerForm.validate() && this.phoneValid) {
        var phoneString = "";

        if (this.form.phone) {
          var dialCode = window.CountryList.findOneByCountryCode(this.selectedCountryCode).data.dial_code;
          phoneString = dialCode + " " + this.form.phone.replace(/\s/g, "");
        }
        this.loading = true;
        var sendObject = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          phone: this.form.phone ? phoneString : "",
          email: this.form.email ? this.form.email : "",
          birthdate: this.form.birthdate ? this.form.birthdate : null,
          identification: this.form.identification ? this.form.identification.toString() : "",
          reference: this.form.reference ? this.form.reference : "",
          memberships: [],
        };

        sendObject.memberships = [
          ...this.sjMembershipList.map((item) => ({ ...item, number: item.number.replace(/\s/g, "") })),
        ];

        this.AccountModel.createPassenger(sendObject, this.id)
          .then(({ data }) => {
            this.$store.dispatch("success", "Passenger Created Successfuly");
            this.$emit("close");
          })
          .catch((error) => {
            this.$store.dispatch("error", error.response.data.message || error.message || error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.passenger-detail .item:not(.item:first-child) {
  margin-top: 1rem;
  font-size: 20px;
}

.passenger-detail .item {
  font-size: 20px;
}

::v-deep .country-selector__input {
  height: 56px !important;
  box-shadow: none !important;
}

::v-deep .input-tel__input {
  height: 56px !important;
}

::v-deep .input-tel__input:hover,
.select-country-container:hover .country-selector__input {
  border-color: black !important;
  box-shadow: none !important;
}

.error-message {
  color: #ff5252;
  font-size: 14px;
  font-weight: bold;
}

::v-deep .v-input__prepend-inner {
  width: 90px;
  margin-top: 20px !important;
}

.membership-prefix {
  text-wrap: nowrap;
}
</style>
