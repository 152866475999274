var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1 mb-10",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content flex justify-space-between",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_vm._m(0), _c('v-btn', {
    staticClass: "button greeny text-button",
    attrs: {
      "dark": ""
    },
    on: {
      "click": _vm.getPapersReportExcel
    }
  }, [_c('v-icon', [_vm._v(" mdi-microsoft-excel ")])], 1)], 1)]), _c('v-card', [_c('v-row', {
    staticClass: "px-6 py-3",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "items": _vm.months,
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Month",
      "hint": "Please select month",
      "persistent-hint": ""
    },
    model: {
      value: _vm.month,
      callback: function ($$v) {
        _vm.month = $$v;
      },
      expression: "month"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "items": _vm.years,
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Year",
      "hint": "Please select year",
      "persistent-hint": ""
    },
    model: {
      value: _vm.year,
      callback: function ($$v) {
        _vm.year = $$v;
      },
      expression: "year"
    }
  })], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.papers,
      "loading": _vm.loading,
      "items-per-page": _vm.filter.itemsPerPage,
      "options": _vm.filter,
      "single-expand": _vm.singleExpand,
      "expanded": _vm.expanded,
      "item-key": "boxNumber",
      "show-expand": true,
      "footer-props": {
        'items-per-page-options': [10, 20, 50, 100]
      }
    },
    on: {
      "update:options": function ($event) {
        _vm.filter = $event;
      },
      "update:expanded": function ($event) {
        _vm.expanded = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "expanded-item",
      fn: function (_ref) {
        var headers = _ref.headers,
          item = _ref.item;
        return [_c('td', {
          staticClass: "py-3",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('div', {
          staticClass: "mb-2",
          staticStyle: {
            "width": "100%"
          }
        }, [_c('v-simple-table', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('tbody', _vm._l(item.intervals, function (interval, index) {
                return _c('tr', {
                  key: index
                }, [_c('td', {
                  attrs: {
                    "width": "40px"
                  }
                }), _c('td', {
                  attrs: {
                    "width": "250px"
                  }
                }), _c('td', {
                  attrs: {
                    "width": "250px"
                  }
                }, [_vm._v(_vm._s(interval.beginning))]), _c('td', {
                  attrs: {
                    "width": "250px"
                  }
                }, [_vm._v(_vm._s(interval.end))]), _c('td')]);
              }), 0)];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v("Paper Report")])]);

}]

export { render, staticRenderFns }