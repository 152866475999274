<template>
  <v-dialog
    @click:outside="closeDialog"
    :value="isDialogOpen"
    transition="dialog-top-transition"
    max-width="50%"
    :scrollable="false"
    style="overflow: hidden"
  >
    <v-card>
      <v-toolbar fixed color="titlebg" class="title" dense dark>
        <v-toolbar-title>
               {{ item.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon  @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="px-4 py-4">
        <v-row>
          <v-col cols="12">
            <v-card class="py-2 px-4 mb-4">
              <h3 class="mb-2">Users ({{item.users.length}})</h3>
              <v-chip
                v-for="(user, index) in item.users"
                :key="index"
                class="font-weight-semibold mr-2 mb-2"
                link
                medium
                color="primary"
              >
                {{ user.fullName ?? user.name }}
              </v-chip>
            </v-card>
            </v-col
          >
          <v-col cols="12">
            <v-card class="py-2 px-4 mb-4">
              <h3 class="mb-2">Tenants ({{groupTenants.length}})</h3>
              <v-chip
                v-for="(tenant, index) in groupTenants"
                :key="index"
                class="font-weight-semibold mr-2 mb-2"
                link
                medium
                color="primary"
              >
                {{ tenant.name }}
              </v-chip>
            </v-card>
          </v-col
          >
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ["isDialogOpen","item"],
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      allTenants: "allTenants",
    }),
    groupTenants() {
      return this.allTenants.filter(tenant => this.item.tenants.includes(tenant.id));
    }
  },
  methods: {
    closeDialog() {
      this.$emit("onClose");
    },
  }
};
</script>

<style lang="scss" scoped>
.priority-item {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
.v-dialog > .v-card > .v-card__actions {
  background: white;
  position: sticky;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  bottom: 0;
  z-index: 999;
}
</style>
