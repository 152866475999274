var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content flex flex-row justify-space-between",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_vm._m(0), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "10px"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "height": "40px",
      "font-size": "13px"
    },
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.goToCompanyList
    }
  }, [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v("mdi-domain")]), _vm._v(" Go To Company List ")], 1), _c('v-btn', {
    staticStyle: {
      "height": "40px",
      "font-size": "13px"
    },
    attrs: {
      "disabled": _vm.isDeleted,
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.isAdd = true;
        _vm.customerDialog = true;
        _vm.selectedCustomer = {};
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v("mdi-account")]), _vm._v(" Add Customer")], 1)], 1)])]), _vm.customerDialog ? _c('v-dialog', {
    staticClass: "pa-5",
    attrs: {
      "width": "90%"
    },
    on: {
      "click:outside": function ($event) {
        _vm.customerDialog = false;
      }
    },
    model: {
      value: _vm.customerDialog,
      callback: function ($$v) {
        _vm.customerDialog = $$v;
      },
      expression: "customerDialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "ml-2"
  }, [_vm._v("Customer "), _c('span', [_vm._v("Add")])]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.customerDialog = false;
        _vm.selectedCustomer = {};
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _vm.customerDialog ? _c('CustomerAdd', {
    attrs: {
      "accountId": _vm.accountId,
      "ignoredCustomers": _vm.connectedCustomers
    },
    on: {
      "close": function ($event) {
        _vm.customerDialog = false;
        _vm.takeToken();
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "hide-default-footer": true,
      "disable-pagination": true,
      "headers": _vm.headers,
      "items": _vm.companies,
      "loading": _vm.loading,
      "server-items-length": _vm.companiesTotalCount,
      "items-per-page": _vm.options.itemsPerPage,
      "show-expand": "",
      "options": _vm.options,
      "footer-props": {
        'items-per-page-options': [50, 100]
      }
    },
    on: {
      "update:options": function ($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: `item.name`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v("mdi-domain ")]), _c('b', [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: `item.organizationNumber`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('copyToClipboardField', {
          attrs: {
            "item": item.organizationNumber
          }
        })];
      }
    }, {
      key: `item.fortnoxNumber`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('copyToClipboardField', {
          attrs: {
            "item": item.fortnoxNumber
          }
        })];
      }
    }, {
      key: `item.email`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('copyToClipboardField', {
          attrs: {
            "item": item.email
          }
        })];
      }
    }, {
      key: `item.tenants`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return _vm._l(item.tenants, function (tenant) {
          return _c('v-chip', {
            key: tenant.id,
            staticClass: "font-weight-bold cliporder mr-1 mb-1",
            attrs: {
              "color": "secondary",
              "link": "",
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(tenant.name) + " ")]);
        });
      }
    }, {
      key: `item.actions`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              _vm.companyData = Object.assign({}, item);
              _vm.initCustomersByCompanyId(item.id);
              _vm.detailDialog = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "accent"
          }
        }, [_vm._v("mdi-eye")])], 1)], 1)];
      }
    }, {
      key: `expanded-item`,
      fn: function (_ref7) {
        var headers = _ref7.headers,
          item = _ref7.item;
        return [_c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('div', {
          staticClass: "py-4"
        }, [_c('CustomerList', {
          attrs: {
            "customer": item.customers,
            "loading": _vm.loading,
            "accountId": _vm.accountId
          },
          on: {
            "takeCustomer": _vm.takeToken
          }
        })], 1)])];
      }
    }], null, true)
  }), _c('v-dialog', {
    attrs: {
      "loading": _vm.isLoading,
      "max-width": "70%"
    },
    on: {
      "click:outside": _vm.closeDialog
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v(_vm._s(_vm.editing ? "Update" : "Create") + " Company")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-form', {
    ref: "companyForm"
  }, [_c('v-card-text', [_c('v-container', {
    staticClass: "mt-1 pt-0"
  }, [_c('h3', {
    staticClass: "my-3 mt-0"
  }, [_vm._v("Company Information")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.validate.input,
      "outlined": "",
      "label": "Company Name",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.companyData.name,
      callback: function ($$v) {
        _vm.$set(_vm.companyData, "name", $$v);
      },
      expression: "companyData.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Organization Number",
      "hide-details": "",
      "rules": _vm.validate.input,
      "clearable": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.companyData.organizationNumber,
      callback: function ($$v) {
        _vm.$set(_vm.companyData, "organizationNumber", $$v);
      },
      expression: "companyData.organizationNumber"
    }
  })], 1)], 1), _c('h3', {
    staticClass: "my-3"
  }, [_vm._v("Contact Person")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Full Name",
      "rules": _vm.validate.input,
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.companyData.contactPersonName,
      callback: function ($$v) {
        _vm.$set(_vm.companyData, "contactPersonName", $$v);
      },
      expression: "companyData.contactPersonName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Contact Person's Phone Number",
      "hide-details": "",
      "rules": _vm.validate.phone,
      "dense": "",
      "clearable": ""
    },
    model: {
      value: _vm.companyData.phone,
      callback: function ($$v) {
        _vm.$set(_vm.companyData, "phone", $$v);
      },
      expression: "companyData.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Contact Person's E-mail",
      "rules": _vm.validate.email,
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.companyData.email,
      callback: function ($$v) {
        _vm.$set(_vm.companyData, "email", $$v);
      },
      expression: "companyData.email"
    }
  })], 1)], 1), _c('h3', {
    staticClass: "my-3"
  }, [_vm._v("Reference")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Email for Invoice or E-Invoice",
      "rules": _vm.validate.email,
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.companyData.invoiceEmail,
      callback: function ($$v) {
        _vm.$set(_vm.companyData, "invoiceEmail", $$v);
      },
      expression: "companyData.invoiceEmail"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Fortnox Number",
      "hide-details": "",
      "dense": "",
      "clearable": ""
    },
    model: {
      value: _vm.companyData.fortnoxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.companyData, "fortnoxNumber", $$v);
      },
      expression: "companyData.fortnoxNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Other / Branch",
      "hide-details": "",
      "dense": "",
      "clearable": ""
    },
    model: {
      value: _vm.companyData.reference,
      callback: function ($$v) {
        _vm.$set(_vm.companyData, "reference", $$v);
      },
      expression: "companyData.reference"
    }
  })], 1)], 1), _c('h3', {
    staticClass: "my-3"
  }, [_vm._v("Finance")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Limit",
      "hide-details": "",
      "dense": "",
      "clearable": "",
      "type": "number"
    },
    on: {
      "change": function ($event) {
        _vm.companyData.limit.amount = parseFloat(_vm.companyData.limit.amount);
      }
    },
    model: {
      value: _vm.companyData.limit.amount,
      callback: function ($$v) {
        _vm.$set(_vm.companyData.limit, "amount", $$v);
      },
      expression: "companyData.limit.amount"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "items": ['SEK'],
      "dense": "",
      "hide-details": "",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select currency",
      "persistent-hint": false
    },
    model: {
      value: _vm.companyData.limit.currency,
      callback: function ($$v) {
        _vm.$set(_vm.companyData.limit, "currency", $$v);
      },
      expression: "companyData.limit.currency"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "max-h",
    attrs: {
      "outlined": "",
      "items": [10, 15, 20, 30],
      "dense": "",
      "hide-details": "",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Allowed days to pay the invoice",
      "persistent-hint": false
    },
    model: {
      value: _vm.companyData.allowedDaysToPay,
      callback: function ($$v) {
        _vm.$set(_vm.companyData, "allowedDaysToPay", $$v);
      },
      expression: "companyData.allowedDaysToPay"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-switch', {
    staticClass: "pa-0 ma-0 switch",
    attrs: {
      "color": "greeny",
      "hide-details": "",
      "label": "Automatically Add Invoice Fee in New Orders"
    },
    model: {
      value: _vm.companyData.automaticInvoiceFeeEnabled,
      callback: function ($$v) {
        _vm.$set(_vm.companyData, "automaticInvoiceFeeEnabled", $$v);
      },
      expression: "companyData.automaticInvoiceFeeEnabled"
    }
  })], 1)], 1), _c('v-textarea', {
    staticClass: "mt-3",
    staticStyle: {
      "background-color": "#fff6d9"
    },
    attrs: {
      "outlined": "",
      "rows": "2",
      "label": "Internal Message",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.companyData.comment,
      callback: function ($$v) {
        _vm.$set(_vm.companyData, "comment", $$v);
      },
      expression: "companyData.comment"
    }
  }), _c('h3', {
    staticClass: "my-3"
  }, [_vm._v("Authorized Employees")]), _c('p', [_vm._v("You can add an employee just by the IATA number, or filling up the rest of the fields.")]), _c('v-form', {
    ref: "employeeForm",
    staticClass: "mb-2"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "First Name",
      "dense": "",
      "clearable": "",
      "rules": [function (v) {
        return !!_vm.customer.firstName || !!_vm.validate.input && !_vm.customer.lastName && !_vm.customer.email && !_vm.customer.phone || 'First name is required.';
      }]
    },
    model: {
      value: _vm.customer.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "firstName", $$v);
      },
      expression: "customer.firstName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Last Name",
      "dense": "",
      "clearable": "",
      "rules": [function (v) {
        return !!_vm.customer.lastName || !!_vm.validate.input && !_vm.customer.firstName && !_vm.customer.email && !_vm.customer.phone || 'Last name is required.';
      }]
    },
    model: {
      value: _vm.customer.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "lastName", $$v);
      },
      expression: "customer.lastName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "E-Mail Address",
      "dense": "",
      "clearable": "",
      "rules": [function (v) {
        return !!_vm.customer.email || !!_vm.validate.input && !_vm.customer.firstName && !_vm.customer.lastName && !_vm.customer.phone || 'E-Mail address is required.';
      }]
    },
    model: {
      value: _vm.customer.email,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "email", $$v);
      },
      expression: "customer.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Phone Number",
      "dense": "",
      "clearable": "",
      "rules": [function (v) {
        return !!_vm.customer.phone || !!_vm.validate.input && !_vm.customer.firstName && !_vm.customer.lastName && !_vm.customer.email || 'Phone number is required.';
      }]
    },
    model: {
      value: _vm.customer.phone,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "phone", $$v);
      },
      expression: "customer.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "IATA Number",
      "rules": [function (v) {
        return !!v || !!_vm.customer.firstName && !!_vm.customer.lastName && !!_vm.customer.email && !!_vm.customer.phone || !!_vm.customer.firstName || !!_vm.customer.lastName || !!_vm.customer.email || !!_vm.customer.phone || 'IATA Number or other fields are required.';
      }],
      "dense": "",
      "clearable": ""
    },
    model: {
      value: _vm.customer.companyCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.customer, "companyCustomerNumber", $$v);
      },
      expression: "customer.companyCustomerNumber"
    }
  })], 1), _c('v-col', {
    staticClass: "mb-6 pr-lg-0",
    attrs: {
      "cols": "12",
      "lg": "1",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "accent",
      "dark": "",
      "height": "100%",
      "block": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addCustomer.apply(null, arguments);
      }
    }
  }, [_vm._v(" ADD ")])], 1)], 1)], 1), _c('v-divider', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }), _c('div', {
    staticClass: "text-center"
  }, [_vm.isLoading ? _c('v-progress-circular', {
    staticClass: "mt-2",
    attrs: {
      "size": 50,
      "color": "primary",
      "indeterminate": ""
    }
  }) : _vm._e()], 1), _vm._l(_vm.companyData.customers, function (item, index) {
    return _c('v-row', {
      key: index,
      staticClass: "my-0 py-0"
    }, [_c('v-col', {
      staticStyle: {
        "position": "relative"
      },
      attrs: {
        "cols": "12",
        "lg": "2",
        "md": "12",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('v-icon', {
      staticStyle: {
        "position": "absolute",
        "z-index": "999999",
        "right": "25px",
        "top": "23px"
      },
      attrs: {
        "color": "mango-red ",
        "small": ""
      },
      on: {
        "click": function ($event) {
          return _vm.copyToClipboard(item.firstName);
        }
      }
    }, [_vm._v(" mdi-content-copy ")]), _c('v-text-field', {
      attrs: {
        "outlined": "",
        "readonly": "",
        "label": "First Name",
        "hide-details": "",
        "dense": ""
      },
      model: {
        value: item.firstName,
        callback: function ($$v) {
          _vm.$set(item, "firstName", $$v);
        },
        expression: "item.firstName"
      }
    })], 1), _c('v-col', {
      staticStyle: {
        "position": "relative"
      },
      attrs: {
        "cols": "12",
        "lg": "2",
        "md": "12",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('v-icon', {
      staticStyle: {
        "position": "absolute",
        "z-index": "999999",
        "right": "25px",
        "top": "23px"
      },
      attrs: {
        "color": "mango-red ",
        "small": ""
      },
      on: {
        "click": function ($event) {
          return _vm.copyToClipboard(item.lastName);
        }
      }
    }, [_vm._v(" mdi-content-copy ")]), _c('v-text-field', {
      attrs: {
        "outlined": "",
        "readonly": "",
        "label": "Last Name",
        "hide-details": "",
        "dense": ""
      },
      model: {
        value: item.lastName,
        callback: function ($$v) {
          _vm.$set(item, "lastName", $$v);
        },
        expression: "item.lastName"
      }
    })], 1), _c('v-col', {
      staticStyle: {
        "position": "relative"
      },
      attrs: {
        "cols": "12",
        "lg": "3",
        "md": "12",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('v-icon', {
      staticStyle: {
        "position": "absolute",
        "z-index": "999999",
        "right": "25px",
        "top": "23px"
      },
      attrs: {
        "color": "mango-red ",
        "small": ""
      },
      on: {
        "click": function ($event) {
          return _vm.copyToClipboard(item.email);
        }
      }
    }, [_vm._v(" mdi-content-copy ")]), _c('v-text-field', {
      attrs: {
        "outlined": "",
        "readonly": "",
        "label": "E-mail",
        "hide-details": "",
        "dense": ""
      },
      model: {
        value: item.email,
        callback: function ($$v) {
          _vm.$set(item, "email", $$v);
        },
        expression: "item.email"
      }
    })], 1), _c('v-col', {
      staticStyle: {
        "position": "relative"
      },
      attrs: {
        "cols": "12",
        "lg": "2",
        "md": "12",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('v-icon', {
      staticStyle: {
        "position": "absolute",
        "z-index": "999999",
        "right": "25px",
        "top": "23px"
      },
      attrs: {
        "color": "mango-red ",
        "small": ""
      },
      on: {
        "click": function ($event) {
          return _vm.copyToClipboard(item.phone);
        }
      }
    }, [_vm._v(" mdi-content-copy ")]), _c('v-text-field', {
      attrs: {
        "outlined": "",
        "readonly": "",
        "label": "Phone",
        "hide-details": "",
        "dense": ""
      },
      model: {
        value: item.phone,
        callback: function ($$v) {
          _vm.$set(item, "phone", $$v);
        },
        expression: "item.phone"
      }
    })], 1), _c('v-col', {
      staticStyle: {
        "position": "relative"
      },
      attrs: {
        "cols": "12",
        "lg": "2",
        "md": "12",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('v-icon', {
      staticStyle: {
        "position": "absolute",
        "z-index": "999999",
        "right": "25px",
        "top": "23px"
      },
      attrs: {
        "color": "mango-red ",
        "small": ""
      },
      on: {
        "click": function ($event) {
          return _vm.copyToClipboard(item.companyCustomerNumber);
        }
      }
    }, [_vm._v(" mdi-content-copy ")]), _c('v-text-field', {
      attrs: {
        "outlined": "",
        "label": "IATA Number",
        "hide-details": "",
        "dense": "",
        "readonly": ""
      },
      model: {
        value: item.companyCustomerNumber,
        callback: function ($$v) {
          _vm.$set(item, "companyCustomerNumber", $$v);
        },
        expression: "item.companyCustomerNumber"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "lg": "1",
        "md": "12",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('v-btn', {
      attrs: {
        "color": "primary",
        "block": "",
        "height": "100%",
        "small": "",
        "dark": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeCustomer(index);
        }
      }
    }, [_vm._v(" REMOVE ")])], 1)], 1);
  }), _vm.warnNoEmployee && _vm.companyData.customers && _vm.companyData.customers.length === 0 ? _c('alert', {
    attrs: {
      "type": "error",
      "color": "primary",
      "textBold": "You must add at least one authorized employee for the company you will add."
    }
  }) : _vm._e()], 2)], 1), _c('v-card-actions', {
    staticClass: "pa-7 pt-0"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "dark": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.saveLoader,
      "color": "accent",
      "dark": ""
    },
    on: {
      "click": _vm.createOrUpdateCompany
    }
  }, [_vm._v(" " + _vm._s(_vm.editing ? "Update" : "Create") + " Company ")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    staticClass: "pa-5",
    attrs: {
      "loading": _vm.isLoading,
      "width": "1000px"
    },
    on: {
      "click:outside": function ($event) {
        _vm.detailDialog = false;
      }
    },
    model: {
      value: _vm.detailDialog,
      callback: function ($$v) {
        _vm.detailDialog = $$v;
      },
      expression: "detailDialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "ml-2"
  }, [_vm._v("Company: " + _vm._s(_vm.companyData && _vm.companyData.name))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.detailDialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _vm.companyData ? _c('companyDetail', {
    attrs: {
      "company": _vm.companyData,
      "loading": _vm.isLoading
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v("Companies ")])]);

}]

export { render, staticRenderFns }