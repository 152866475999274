<template>
  <div>
    <v-tooltip bottom color="black">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center" v-bind="attrs" v-on="on">
          <div
              v-if="!order.delivery.country"
              class="d-flex align-center justify-center rounded font-weight-bold black grey lighten-2"
              style="width: 70px; height: 24px"
          >
            None
          </div>

          <div
              v-else-if="order.delivery.country === 'SE'"
              class="d-flex align-items-center px-2 rounded"
              style="width: 70px; height: 24px; background: #1ca2d7"
          >
            <img width="100%" src="@/assets/postnord.svg" alt="Postnord"/>
          </div>

          <div
              v-else
              class="d-flex align-items-center px-2 rounded"
              style="width: 70px; height: 24px; background: #f7c501"
          >
            <img width="100%" src="@/assets/dhl.svg" alt="DHL"/>
          </div>

          <img
              v-if="order.distribution.slug === 'express_post'"
              height="16px"
              class="d-flex align-center ml-1"
              src="@/assets/express.svg"
              alt="Express Post"
          />
        </div>
      </template>
      <span v-if="!order.delivery.country">
        Please enter a proper address and country in <br/>
        order customer shipping/delivery details.</span
      >
      <span v-else
      >{{ order.distribution.title }}
        <span v-if="order.delivery.country">
          {{ " - " + getCountryName(order.delivery.country) }}
        </span>
        <span v-if="order.delivery.country !== 'SE'"
        ><br/>Use yellow envelope.</span
        >
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import countryList from "../../static/countries.json";

export default {
  name: "OrderPrintDeliverField",
  data() {
    return {
      countries: countryList,
    };
  },
  props: ["order"],
  methods: {
    getCountryName(code) {
      const country = this.countries.find((x) => x.value === code);

      return country ? country.text : "Unknown Country";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
