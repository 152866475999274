<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="450">
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on" elevation="1" style="width:120px;"  class="mr-3" small :color="distribution.isLocked ?  'primary' : 'greeny'">
          <v-icon class="mr-2" v-if="distribution.isLocked">mdi-lock</v-icon>
          <v-icon class="mr-2" v-else>mdi-lock-open</v-icon>
          <span>{{ distribution.isLocked ?   "Locked"  : "Unlocked"}}</span>
        </v-btn>
      </template>

      <v-card class="px-5">
        <v-card-title class="text-h5 px-0">
          <div v-if="!distribution.isLocked">
            <v-icon class="mr-3">mdi-lock</v-icon>
            <span>Lock Distribution</span>
          </div>
          <div v-else>
            <v-icon class="mr-3">mdi-lock</v-icon>
            <span>Unlock Distribution</span>
          </div>
        </v-card-title>
        <v-textarea
          v-model="reason"
          outlined
          name="input-7-4"
          label="Reason"
          :value="reason"
        ></v-textarea>
        <v-card-actions class=" pa-0 pb-5">
          <v-spacer></v-spacer>
          <v-btn dark
            color="primary"  @click="dialog = false">
            Disagree
          </v-btn>
          <v-btn :loading="loading" @click="continueClick()"
            dark
            color="accent" >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["item", "distribution", "lockDistribution"],
  data: () => ({
    dialog: false,
    reason: "",
    loading: false
  }),

  methods: {
    async continueClick() {
      this.loading = true;
      const res = await this.lockDistribution(this.item, this.distribution, this.reason);
      this.reason = "";
      this.dialog = false;
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
