<template>
  <div>
    <Header title="Insert New Box" />

    <!--INPUT FIELDS-->
    <v-card class="px-4 py-4">
      <v-row>
        <v-col md="4" xs="12" sm="12">
          <v-text-field
            v-model.trim="paperBody.firstPaperNumber"
            solo
            clearable
            class="font-weight-bold"
            autofocus
            placeholder="First Paper Number"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col md="4" xs="12" sm="12">
          <v-text-field
            v-model.trim="paperBody.lastPaperNumber"
            solo
            clearable
            class="font-weight-bold"
            autofocus
            placeholder="Last Paper Number"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col md="4" xs="12" sm="12">
          <v-text-field
            v-model.trim="paperBody.boxNumber"
            solo
            clearable
            class="font-weight-bold"
            autofocus
            placeholder="Box Number"
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex justify-content-end w-100" style="width:100%;justify-content:flex-end">
        <v-btn :saveLoader="saveLoading" @click="savePaper()"  color="accent">Save</v-btn>
      </div>
    </v-card>

    <!--PAPER STORAGE TABLE-->
    <PaperStorageTable :boxes="boxes" :getPapers="getPapers" />
  </div>
</template>

<script>
import PaperStorageTable from "./components/PaperStorageTable.vue";
import Header from "@/components/common/Header.vue";
import Trainplanet from "@/util/trainplanet.api";
import PaperReportTable from "./components/PaperReportTable.vue";

export default {
  components: {
    PaperStorageTable,
    Header,
    PaperReportTable
  },
  data: () => ({
    saveLoading: false,
    boxes: [],
    papers: [],
    paperBody: {
      firstPaperNumber: null,
      lastPaperNumber: null,
      boxNumber: ""
    }
  }),
  methods: {
    async savePaper() {
      await this.$store.dispatch("loading", true);
      try {
        await Trainplanet.createBox({
          firstPaperNumber: Number(this.paperBody.firstPaperNumber),
          lastPaperNumber: Number(this.paperBody.lastPaperNumber),
          boxNumber: this.paperBody.boxNumber
        });

        for (const key in this.paperBody) {
          delete this.paperBody[key];
        }

        await this.$store.dispatch("success", "You successfully inserted a new box!");

        await this.getPapers();
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message || error);
      } finally {
        await this.$store.dispatch("loading", false);
      }
    },
    async getPapers() {
      await this.$store.dispatch("loading", true);

      try {
        this.boxes = await Trainplanet.getPapers();
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message || error);
      } finally {
        await this.$store.dispatch("loading", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
