<template>
  <div id="order-print">
    <toolbarTitle title="Printable Tickets" color="accent" />
    <v-data-table
      fixed-header
      mobile-breakpoint="0"
      class="elevation-1 row-height-50"
      :headers="headers"
      :items="orders"
      :server-items-length="ordersTotalCount"
      :loading="loading"
      :items-per-page="filter.itemsPerPage"
      :options.sync="filter"
      :footer-props="{
        'items-per-page-options': [20, 50, 100],
      }">
      <template v-slot:item="{ item }">
        <tr :class="item.distribution.isLocked && 'grey lighten-4'">
          <td :class="item.distribution.isLocked && 'disableCss'">
            <div class="d-flex flex-row" style="width: 180px">
              <OrderIDField :order="item"></OrderIDField>
              <OrderIconsField
                :order="item"
                :showPrintableInfo="false"
                :showDistributedInfo="true"
                :showGroupOrderInfo="true"
                :showClaimInfo="true"
                :showArchiveOrder="true"></OrderIconsField>
            </div>
          </td>
          <!-- Currency -->
          <!--          <td :class="item.distribution.isLocked && 'disableCss'"-->
          <!--              style="min-width: 160px"-->
          <!--          >-->
          <!--            {{item.calculatedPrice.total.currency}}-->
          <!--          </td>-->
          <td :class="item.distribution.isLocked && 'disableCss'">
            <OrderPrintDeliverField :order="item" />
          </td>
          <td :class="item.distribution.isLocked && 'disableCss'">
            <div>
              <b>{{ item.purchaseDate | localizeUTCAndGetDate }}</b>
              {{ item.purchaseDate | localizeUTCAndGetTime }}
            </div>
          </td>
          <td :class="item.distribution.isLocked && 'disableCss'" class="pl-0">
            <orderCustomerField :order="item" :address="true" />
          </td>

          <!-- Internal Message -->
          <td :class="item.distribution.isLocked && 'disableCss'" style="min-width: 160px">
            <earliestDepartureDateField :order="item" />
          </td>
          <!-- Internal Message -->
          <td style="min-width: 160px">
            <div class="d-flex align-center">
              <v-tooltip color="black" bottom style="cursor: pointer" v-if="item.internalMessage">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="mango-red" class="mr-1" small>
                    mdi-message-reply-text
                  </v-icon>
                  <span
                    v-bind="attrs"
                    v-on="on"
                    :class="item.distribution.isLocked && 'disableCss'"
                    class="threeDots"
                    style="max-width: 170px">
                    {{ item.internalMessage | cleanHtml }}</span
                  >
                </template>

                <div v-if="item.internalMessage" style="max-width: 400px">
                  {{ item.internalMessage | cleanHtml }}
                </div>
              </v-tooltip>
            </div>
          </td>
          <!-- Actions -->
          <td style="min-width: 190px; text-align: right !important">
            <v-row>
              <v-col md="auto" class="d-flex justify-start align-center w-100" style="width: 100%; display: flex">
                <v-tooltip color="black" bottom style="cursor: pointer">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-switch
                        dense
                        v-model="item.distribution.isLocked"
                        color="mango-red"
                        class="lock"
                        small
                        @click="lockDistribution(item.id, item.distribution)" />
                    </div>
                  </template>
                  <div v-if="item.distribution.isLocked">Order is locked for printing.</div>
                  <div v-else>Switch to lock order for not be printable.</div>
                </v-tooltip>

                <v-tooltip color="black" bottom style="cursor: pointer">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-btn
                        @click="
                          cancelPrinting({
                            orderId: item.id,
                            itemId: item.distribution.id,
                          })
                        "
                        color="mango-red"
                        :disabled="item.distribution.isLocked"
                        icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <div>Delete order from printing section.</div>
                </v-tooltip>

                <v-tooltip
                  v-if="!item.distribution.trackingId"
                  :disabled="!!item.delivery?.fullAddress"
                  color="black"
                  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on">
                      <v-btn
                        @click.prevent="
                          printDialogOpen = true;
                          printOrder(item, item.distribution);
                        "
                        v-bind="attrs"
                        :loading="printing.distribution.id === item.distribution.id && printDialogOpen"
                        :disabled="!item.delivery?.fullAddress"
                        color="accent"
                        icon>
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  Delivery address is required to print the order.
                </v-tooltip>

                <v-btn v-else disabled>
                  <v-icon left>mdi-printer-check</v-icon>
                  Order printed
                </v-btn>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-progress-linear v-if="loading" indeterminate color="#f4846e"></v-progress-linear>
    <v-dialog :value="printDialogOpen" max-width="500px" scrollable transition="dialog-bottom-transition" persistent>
      <v-card>
        <v-card-title justify="between" style="width: 100%; justify-content: space-between">
          <span class="text-h5"
            >Print order: <b>{{ printing.order.id }}</b>
            <!--            [{{-->
            <!--              printing && printing.order && printing.order.calculatedPrice ? printing.order.calculatedPrice.total.currency : ''-->
            <!--            }}]-->
          </span>
        </v-card-title>

        <v-card-text style="min-height: 55vh; max-height: 80vh">
          <v-list
            v-if="
              bookingsToPrint.some((item) => item.mustBePrinted) || bookingsToPrint.every((item) => !item.mustBePrinted)
            "
            subheader
            two-line>
            <v-subheader>
              <span class="mr-1">1. Print the following external bookings:</span>
            </v-subheader>

            <v-list-item-group v-model="printing.bookings" multiple>
              <div v-for="(booking, i) in bookingsToPrint" :key="i" :value="booking">
                <v-list-item @click="copy(booking)" v-if="booking.mustBePrinted">
                  <template>
                    <v-list-item-action>
                      <v-icon>mdi-content-copy</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ booking.externalOrderId }}</v-list-item-title>
                      <v-list-item-subtitle>{{ booking.externalSystem }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title>Currency</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ booking.externalCurrency }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-content v-if="booking.externalItemIds.length > 0">
                      <v-list-item-title>Row Count</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ booking.externalItemIds.length }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-list>

          <v-divider></v-divider>

          <v-subheader>2. Scan/register all printed tickets:</v-subheader>

          <!-- <div>    :disabled="printing.bookings.length === 0"</div> -->

          <div ref="tickets">
            <v-container>
              <v-text-field
                v-for="(ticket, i) in printing.distribution.tickets"
                :key="i"
                v-model="printing.distribution.tickets[i]"
                @input.native="addEmptyTicket($event)"
                ref="ticket"
                type="number"
                label="Ticket ID"
                :rules="[validateTicketNumber.required, validateTicketNumber.min, validateTicketNumber.isConsecutive]"
                outlined
                dense>
                <template v-slot:append-outer>
                  <v-btn @click.prevent="removeTicket(i)" color="primary" icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-container>
          </div>
        </v-card-text>

        <span v-if="nonEmptyTicketsSize !== 0" style="margin-left: 15px; margin-bottom: -10px">
          <span>Scanned Ticket Count: </span>
          <b> {{ nonEmptyTicketsSize }}</b>
        </span>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click.prevent="resetPrintingState()" color="primary">Abort</v-btn>

          <div class="ml-2">
            <v-tooltip :disabled="!saveButtonDisabled" color="black" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on">
                  <v-btn
                    :loading="printLoading"
                    :disabled="saveButtonDisabled"
                    color="accent"
                    v-bind="attrs"
                    @click.prevent="confirmPrint()">
                    Save & continue
                  </v-btn>
                </div>
              </template>
              <span>{{ saveButtonTooltip }}</span>
            </v-tooltip>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- PRINTED TICKETS TABLE -->
    <div class="my-5">
      <PrintedTickets :refreshPrintedOrders="isPrintingCompleted" :refreshPrintableOrdersCallback="this.listOrders" />
    </div>

    <v-snackbar :timeout="3000" v-model="copied" color="success" app bottom right
      >Booking number copied to clipboard!
    </v-snackbar>
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";
import printJS from "print-js";
import LockDialog from "./partials/LockDialog.vue";
import printOrderMixin from "../mixins/printOrder";
import PrintedTickets from "../components/PrintedTickets.vue";
import OrderIDField from "@/components/common/OrderIDField";
import OrderIconsField from "@/components/common/OrderIconsField";
import OrderPrintDeliverField from "@/components/common/OrderPrintDeliverField";
import earliestDepartureDateField from "@/components/common/earliestDepartureDateField";
import toolbarTitle from "@/components/common/toolbarTitle";
import orderCustomerField from "@/components/common/orderCustomerField";
import moment from "moment";
import common from "@/mixins/common";

export default {
  name: "Print",
  mixins: [printOrderMixin, common],
  components: {
    earliestDepartureDateField,
    OrderPrintDeliverField,
    orderCustomerField,
    toolbarTitle,
    OrderIDField,
    LockDialog,
    PrintedTickets,
    OrderIconsField,
  },
  data: () => ({
    printLoading: false,
    printDialogOpen: false,
    isPrintingCompleted: false,
    isLockDialogOpen: false,
    loading: false,
    copied: false,
    isSummaryDialogOpen: false,
    printing: {
      // The currently active printing data
      order: [],
      distribution: {
        tickets: [],
      },
      bookings: [],
    },
  }),

  computed: {
    saveButtonDisabled() {
      return (
        this.printLoading ||
        (this.printing.distribution.tickets &&
          this.printing.distribution.tickets.some(
            (value) =>
              (this.printing.distribution.tickets.length === 1 && !value) || (value !== "" && value.length !== 7)
          ))
      );
    },
    saveButtonTooltip() {
      if (this.printLoading) return "Please wait until the current process is completed.";
      if (
        this.printing.distribution.tickets &&
        this.printing.distribution.tickets.some(
          (value) => (this.printing.distribution.tickets.length === 1 && !value) || (value !== "" && value.length !== 7)
        )
      )
        return "Please scan at least one ticket.";
    },
    validateTicketNumber: function () {
      return {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 7 || "Min 7 characters",
        isConsecutive: (value) => {
          return true; // TODO fix
          // const tickets =
          //   [...this.printing.distribution.tickets]?.length > 1
          //     ? [...this.printing.distribution.tickets].slice(0, -1)
          //     : [...this.printing.distribution.tickets];
          //
          // const lastElement = Number(tickets[tickets.length - 1]);
          //
          // return (
          //   tickets.indexOf(value) === 0 ||
          //   Number(value) - 1 === Number(lastElement) ||
          //   "Consecutive!"
          // );
        },
      };
    },

    bookingsToPrint() {
      if (Object.keys(this.printing.order).length === 0) return [];

      // const itemsToPrint =
      //   this.printing.order.items &&
      //   this.printing.order.items.reduce((prev, item) => {
      //     let response = prev;
      //     if (this.printing.distribution.items.includes(item.id)) {
      //       response = response.concat(Object.keys(item.externalIds));
      //     }
      //     return response;
      //   }, []);

      return this.printing.order.bookings;
    },
    nonEmptyTicketsSize() {
      return this.printing.distribution.tickets.filter((i) => i).length;
    },
  },
  methods: {
    getOnlyDate(datetime) {
      return moment.utc(datetime).local().format("YYYY-MM-DD");
    },
    getOnlyTime(datetime) {
      return moment.utc(datetime).local().format("HH:mm");
    },
    copy(item) {
      this.copyToClipboard(item.externalOrderId);
    },

    generateTicketNumber() {
      const number = "11111" + (Math.random() * 1000).toString().slice(-2);
      const printing = { ...this.printing };
      printing.distribution.tickets[printing.distribution.tickets.length - 1] = number;
      this.printing = printing;
      this.addEmptyTicket();
    },
    printOrder(order, distribution) {
      this.isPrintingCompleted = false;
      this.printing.order = order;
      this.printing.distribution = {
        ...order.distribution,
        tickets: order.distribution.tickets.map((ticket) => ticket.paperNumber.toString()),
      };
      this.addEmptyTicket();
    },
    focusInput() {
      setTimeout(() => {
        this.$refs?.ticket[this.$refs?.ticket?.length - 1].focus();
      }, 90);
    },
    addEmptyTicket(e) {
      if (this.printing.distribution.tickets.length === 0) {
        this.printing.distribution.tickets.push("");
        this.focusInput();
      } else {
        const tickets = this.printing.distribution.tickets;
        let lastTicket = tickets[tickets.length - 1];

        setTimeout(() => {
          if ((e && e.target.value.length === 7) || lastTicket.length === 7) {
            if (this.$refs.ticket[this.$refs.ticket.length - 1].validate()) {
              this.printing.distribution.tickets.push("");
              this.$nextTick(() => {
                this.focusInput();
              });
            } else {
              if (
                this.printing.distribution.tickets.includes(
                  this.printing.distribution.tickets[this.printing.distribution.tickets.length - 1]
                )
              ) {
                this.printing.distribution.tickets.splice(this.printing.distribution.tickets.length - 1, 1);
                this.printing.distribution.tickets.push("");
                this.focusInput();
              }
            }
          }
        }, 100);
      }
    },
    removeTicket(key) {
      if (this.printing.distribution.tickets.length > 1) {
        this.printing.distribution.tickets.splice(key, 1);
      } else {
        const temp = { ...this.printing };
        temp.distribution.tickets[0] = "";
        this.printing = temp;
        this.$refs?.ticket[0].focus();
      }
    },
    async confirmPrint() {
      try {
        this.printLoading = true;
        const tickets = this.printing.distribution.tickets.filter((ticket) => !!ticket).map((ticket) => Number(ticket));

        const response = await Trainplanet.printOrder(this.printing.order.id, this.printing.distribution.id, tickets);

        this.printing.distribution.trackingId = response.trackingId;
        this.printing.distribution.trackingLink = response.trackingLink;

        printJS({
          printable: response.shippingNote, // Base64
          type: "pdf",
          base64: true,
          onPrintDialogClose: () => {
            this.resetPrintingState();
            this.isPrintingCompleted = true;
            this.listOrders();
          },
        });
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message);
      } finally {
        this.printLoading = false;
      }
    },
    resetPrintingState() {
      this.printDialogOpen = false;
      this.isSummaryDialogOpen = false;
      this.printing = {
        order: {},
        distribution: {
          tickets: [],
        },
        bookings: [],
      };
    },

    async cancelPrinting({ itemId, orderId }) {
      this.$confirm("Are you sure you want to cancel this order's shipment and remove it from the printing list?", {
        color: "error",
        title: "Remove from printing list",
      }).then(async (res) => {
        if (!res) return;
        await this.$store.dispatch("loading", true);
        await Trainplanet.cancelItems(orderId, { items: [{ itemId }] });
        await this.listOrders();
        await this.$store.dispatch("success", "Item has been cancelled.");
        await this.$store.dispatch("loading", false);
      });
    },

    async cancelDistribution(item) {
      this.$confirm("Are you sure you want to cancel the selected printed ticket?", {
        color: "error",
        title: "Printed Tickets",
      }).then(async (res) => {
        if (!res) {
          this.loadingButton = "";
        } else {
          const itemId = item.distribution.id;
          const orderId = item.id;

          try {
            await Trainplanet.cancelDistribution(orderId, itemId);
            await this.listOrders();
            await this.$store.dispatch("success", "Distribution cancelled successfully");
          } catch (err) {
            await this.$store.dispatch("error", err.response.data.message || err);
          }

          this.loadingButton = "";
        }
      });
    },
    async lockDistribution(orderID, distribution) {
      try {
        const res = await Trainplanet.lockDistribution(orderID, distribution.id, "");
        this.$store.dispatch(
          "success",
          `You have successfully ${distribution.isLocked ? "locked" : "unlocked"} the distribution.`
        );
        // this.listOrders();
        // this.printing.distribution.locked = true;
      } catch (error) {
        this.$store.dispatch("error", error.message);
      }
    },
  },
  watch: {
    "printing.bookings": function (after, before) {
      const booking = after.find((x) => !before.includes(x));
      if (booking) {
        // Copy the booking number to the user's clipboard
        navigator.clipboard.writeText(booking.externalOrderId);
        this.copied = true;
      }

      if (this.bookingsToPrint.length > 0 && this.bookingsToPrint.length === after.length) {
        // We are ready to scan tickets now
        //this.$nextTick(() => {
        //  this.printing.distribution.tickets.push("");
        //  this.$refs?.ticket[this.$refs?.ticket?.length - 1].focus();
        //});
      }
    },
    filter(val) {
      if (val) {
        this.listOrders();
      }
    },
  },
};
</script>

<style></style>
