<template>
  <v-card>
    <v-tabs v-model="tab" align-tabs="center">
      <v-tab :value="1">Account Detail</v-tab>
      <v-tab :value="2">Account Order</v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item>
        <v-container fluid>
          <AccountDetail />
        </v-container>
      </v-window-item>
      <v-window-item>
        <v-container fluid>
          <AccountOrder :header="'Account Orders'" />
        </v-container>
      </v-window-item>
    </v-window>
  </v-card>
</template>
<script>
import AccountDetail from "./account-detail.vue";
import AccountOrder from "./account-orders.vue";

export default {
  data: () => ({
    tab: null,
  }),
  components: {
    AccountDetail,
    AccountOrder
  }
}
</script>
